import { useState, useEffect } from 'react'
import { Button } from "./ui/button"
import { Card, CardContent } from "./ui/card"
import { ChevronDown, BarChart2, Users, GitBranch, MousePointer } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

export default function FrenemLandingPage() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  // const [expandedMember, setExpandedMember] = useState<number | null>(null)
  const [showHeader, setShowHeader] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById('hero-section')
      if (heroSection) {
        const heroBottom = heroSection.offsetTop + heroSection.offsetHeight
        setShowHeader(window.scrollY > heroBottom - 100)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleSignIn = () => {
    navigate('/login')
  }

  // const toggleMemberExpansion = (index: number) => {
  //   setExpandedMember(expandedMember === index ? null : index)
  // }

  const features = [
    { title: "Clear decision making", icon: BarChart2, description: "Streamline your decision-making process with powerful tools and insights." },
    { title: "Proactive employees", icon: Users, description: "Foster a culture of initiative and engagement among your team members." },
    { title: "Auto-generated org charts", icon: GitBranch, description: "Visualize your organization's structure with automatically updated charts." },
    { title: "1-click set up", icon: MousePointer, description: "Get started quickly with our intuitive, one-click setup process." },
  ]

  // const teamMembers = [
  //   {
  //     name: "Sanjay Paul Antony",
  //     role: "Partner",
  //     bio: "NITC / XLRI / Goldman Sachs / MD, Abanel",
  //   },
  //   {
  //     name: "Tresa Abraham Paul",
  //     role: "Partner",
  //     bio: "XLRI / Director, Abanel",
  //   },
  //   {
  //     name: "Antony Paul Elenjical",
  //     role: "Partner",
  //     bio: "St Stephen's / XLRI / Flaneur",
  //   },
  //   {
  //     name: "Arjun Ravikumar",
  //     role: "Founding Engineer",
  //     bio: "RVCE / Cisco / Agile Building",
  //   },
  //   {
  //     name: "Consultant engineers",
  //     role: "Advisory Team",
  //     bio: "100x engineers on call to help us move the needle from IIIT-Hyderabad, Microsoft Research",
  //   },
  // ]

  return (
    <div className="min-h-screen bg-background text-gray-900">
      {/* Header */}
      <header className={`fixed top-0 left-0 right-0 z-50 bg-background bg-opacity-90 shadow-sm transition-all duration-300 ${
        showHeader ? 'translate-y-0' : '-translate-y-full'
      }`}>
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="font-arial text-2xl font-black tracking-tighter text-black lowercase">
            frenem
          </div>
          <Button 
            variant="outline" 
            className="bg-background hover:bg-gray-100 border-black text-black"
            onClick={handleSignIn}
          >
            Sign In
          </Button>
        </div>
      </header>

      {/* Hero Section */}
      <section id="hero-section" className="h-screen flex items-center justify-center bg-background pt-16">
        <div className="text-center space-y-6 max-w-4xl px-4">
          <h1 className="font-arial text-5xl md:text-6xl font-black tracking-tighter text-black lowercase">
            frenem
          </h1>
          <p className="text-xl md:text-2xl text-gray-700">Turn employees into promoters</p>
          <div className="space-x-4">
            <Button 
              onClick={openModal} 
              size="lg" 
              className="mt-8 bg-black hover:bg-gray-800 text-white"
            >
              Schedule a Demo
            </Button>
            <Button 
              onClick={handleSignIn}
              size="lg" 
              variant="outline"
              className="mt-8 border-black text-black hover:bg-gray-100"
            >
              Sign In
            </Button>
          </div>
        </div>
        <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 animate-bounce">
          <ChevronDown className="w-8 h-8 text-gray-500" />
        </div>
      </section>

      {/* Features */}
      <section className="py-20 px-4 bg-background">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <Card key={index} className="hover:shadow-lg transition-shadow duration-300 border border-black">
                <CardContent className="p-6 flex items-start space-x-4">
                  <feature.icon className="w-8 h-8 text-black flex-shrink-0" />
                  <div>
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Team
      <section className="py-20 px-4 bg-yellow-50">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Our Team</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {teamMembers.map((member, index) => (
              <Card 
                key={index} 
                className="text-center hover:shadow-lg transition-shadow duration-300 bg-white border border-gray-200 cursor-pointer"
                onClick={() => toggleMemberExpansion(index)}
              >
                <CardContent className="p-6">
                  <div className="w-20 h-20 bg-blue-100 rounded-full mx-auto mb-4"></div>
                  <h3 className="text-lg font-semibold text-gray-800">{member.name}</h3>
                  <p className="text-sm text-gray-600 mt-2">{member.role}</p>
                  {expandedMember === index && (
                    <div className="mt-4 text-sm text-gray-700">
                      <p>{member.bio}</p>
                    </div>
                  )}
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    className="mt-4"
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleMemberExpansion(index)
                    }}
                  >
                    {expandedMember === index ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </Button>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section> */}

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-background p-8 rounded-lg max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4 text-black">Schedule a Demo</h2>
            <p className="mb-4 text-gray-600">Fill out the form and we'll get back to you shortly.</p>
            <form className="space-y-4">
              <input type="text" placeholder="Your Name" className="w-full p-2 border rounded border-black" />
              <input type="email" placeholder="Your Email" className="w-full p-2 border rounded border-black" />
              <input type="text" placeholder="Company Name" className="w-full p-2 border rounded border-black" />
              <Button type="submit" className="w-full bg-black hover:bg-gray-800 text-white">
                Submit
              </Button>
            </form>
            <Button onClick={closeModal} variant="outline" className="mt-4 w-full border-black text-black hover:bg-gray-100">
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}