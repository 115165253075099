import { useEffect, useState } from "react"
import { Badge } from "../ui/badge"
import { Star } from "lucide-react"
import api from "../../api"
import { useToast } from "../ui/use-toast"
import { useAuth } from "../../contexts/AuthContext"

interface ReviewCycle {
  id: number
  name: string
  status: 'draft' | 'in_progress' | 'completed'
}

interface EmployeeReview {
  id: string
  name: string
  stage: 'goal_summary' | 'self_review' | 'manager_review' | 'completed'
  manager_name: string
  average_rating: number | null
  is_locked: boolean
  department?: string
}

interface CycleAnalytics {
  total_reviews: number
  completed_reviews: number
  completion_rate: number
  employees: EmployeeReview[]
}

interface ReviewCycleAnalyticsProps {
  cycle: ReviewCycle | null
  onClose?: () => void
  searchQuery?: string
}

export function ReviewCycleAnalytics({ cycle, searchQuery = "" }: ReviewCycleAnalyticsProps) {
  const { user } = useAuth()
  const { toast } = useToast()
  const [analytics, setAnalytics] = useState<CycleAnalytics | null>(null)

  useEffect(() => {
    if (cycle) {
      fetchAnalytics()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycle])

  const fetchAnalytics = async () => {
    if (!cycle || !user?.company) return
    
    try {
      const response = await api.get(`/api/reviews/cycles/${cycle.id}/analytics/`, {
        params: {
          company: user.company
        }
      })
      if (response.status === 200) {
        setAnalytics(response.data)
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch review analytics",
      })
      console.error('Error fetching analytics:', error)
    }
  }

  const getStageLabel = (stage: EmployeeReview['stage']) => {
    return stage.split('_').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ')
  }

  const filteredEmployees = analytics?.employees.filter(employee => {
    return employee.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
           employee.manager_name.toLowerCase().includes(searchQuery.toLowerCase())
  }) || []

  if (!cycle || !analytics) return null

  return (
    <div className="space-y-6">
      <div>
        <div className="rounded-lg bg-muted/50">
          <div className="p-4 space-y-4">
            {filteredEmployees.map(employee => (
              <div 
                key={employee.id} 
                className="p-4 rounded-lg bg-background border hover:bg-accent/50 transition-colors"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium">{employee.name}</h3>
                    <p className="text-sm text-muted-foreground">Manager: {employee.manager_name}</p>
                  </div>
                  <div className="flex items-center gap-4">
                    {employee.stage === 'completed' && employee.average_rating && (
                      <div className="flex items-center gap-1">
                        <Star className="w-4 h-4 fill-yellow-400 text-yellow-400" />
                        <span>{employee.average_rating.toFixed(1)}</span>
                      </div>
                    )}
                    <Badge variant={employee.stage === 'completed' ? 'outline' : 'default'}>
                      {getStageLabel(employee.stage)}
                    </Badge>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
} 