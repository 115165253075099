import { useEffect, useState } from "react"
import { useAuth } from "../../contexts/AuthContext"
import api from "../../api"
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/card"
import { Button } from "../ui/button"
import { useToast } from "../ui/use-toast"
import { Textarea } from "../ui/textarea"
import { Label } from "../ui/label"
import { Star, Check, ClipboardList, UserCircle2, Users } from "lucide-react"
import { cn } from "../../lib/utils"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion"
import { Progress } from "../ui/progress"

interface Goal {
  id: number
  title: string
  description: string
  progress: number
  timeline: string
  timeline_duration: string
  timeline_year: number
  goal_type: string
  owner_details: {
    id: number
    employee_id: string
    name: string
    email: string
    date_of_joining: string
    is_active: boolean
  }
  parent_goal_details?: {
    id: number
    title: string
    progress: number
  } | null
}

interface ReviewGoal {
  id: number
  goal: number
  goal_details: Goal
  self_rating?: number
  manager_rating?: number
  locked_progress: number
  employee_review: number
  weightage: number
  self_comments?: string
  manager_comments?: string
}

interface DirectReport {
  employee_id: string
  name: string
  title: string
  department: string
  review_stage?: 'goal_summary' | 'self_review' | 'manager_review' | 'completed'
}

// Add WeightPoints component
const WeightPoints = ({ 
  value, 
  onChange 
}: { 
  value: number, 
  onChange: (value: number) => void 
}) => {
  return (
    <div className="flex items-center gap-1">
      {[0, 1, 2, 3, 4, 5].map((point) => (
        <button
          key={point}
          onClick={() => onChange(point)}
          className={cn(
            "w-8 h-8 rounded-full flex items-center justify-center transition-all",
            value >= point 
              ? "bg-blue-500 text-white" 
              : "bg-blue-50 text-blue-500 hover:bg-blue-100"
          )}
        >
          {point}
        </button>
      ))}
    </div>
  );
};

const WeightedRatingDisplay = ({
  employeeGoals,
  goalRatings,
  employeeName,
  weightPoints,
  totalPoints
}: {
  employeeGoals: ReviewGoal[],
  goalRatings: Record<number, number>,
  employeeName: string,
  weightPoints: Record<number, number>,
  totalPoints: number
}) => {
  // Calculate manager's weighted average
  const managerWeightedAverage = employeeGoals.reduce((acc, goal) => {
    const rating = goalRatings[goal.id] || goal.manager_rating;
    if (!rating) return acc;
    const weight = weightPoints[goal.id] || 0;
    return acc + (rating * (weight / totalPoints));
  }, 0);

  // Calculate employee's weighted average
  const employeeWeightedAverage = employeeGoals.reduce((acc, goal) => {
    if (!goal.self_rating) return acc;
    const weight = weightPoints[goal.id] || 0;
    return acc + (goal.self_rating * (weight / totalPoints));
  }, 0);

  // Count rated goals
  const ratedGoalsCount = employeeGoals.filter(g => goalRatings[g.id] || g.manager_rating).length;
  const totalGoals = employeeGoals.length;

  return (
    <div className="sticky top-0 z-10 bg-background border-b shadow-sm mb-6">
      <div className="px-6 py-4">
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <h3 className="text-lg font-medium">Manager Review for {employeeName}</h3>
            <p className="text-sm text-muted-foreground">
              {ratedGoalsCount === totalGoals 
                ? "All goals have been rated" 
                : `${ratedGoalsCount} of ${totalGoals} goals rated`}
            </p>
          </div>
          <div className="flex items-center gap-6">
            {/* Employee's Rating */}
            <div className="flex items-center gap-3 px-4 py-2 bg-yellow-50 rounded-lg">
              <div className="flex -space-x-1">
                {[1, 2, 3, 4, 5].map((star) => (
                  <Star 
                    key={star}
                    className={cn(
                      "w-5 h-5",
                      employeeWeightedAverage >= star 
                        ? "fill-yellow-400 text-yellow-400" 
                        : employeeWeightedAverage >= star - 0.5
                        ? "fill-yellow-400/50 text-yellow-400"
                        : "text-yellow-200"
                    )}
                  />
                ))}
              </div>
              <div className="flex flex-col">
                <span className="text-xs text-yellow-600 font-medium">Employee's Rating</span>
                <span className="text-lg font-semibold">
                  {employeeWeightedAverage ? employeeWeightedAverage.toFixed(2) : '-'}
                </span>
              </div>
            </div>

            {/* Manager's Rating */}
            <div className="flex items-center gap-3 px-4 py-2 bg-blue-50 rounded-lg">
              <div className="flex -space-x-1">
                {[1, 2, 3, 4, 5].map((star) => (
                  <Star 
                    key={star}
                    className={cn(
                      "w-5 h-5",
                      managerWeightedAverage >= star 
                        ? "fill-blue-400 text-blue-400" 
                        : managerWeightedAverage >= star - 0.5
                        ? "fill-blue-400/50 text-blue-400"
                        : "text-blue-200"
                    )}
                  />
                ))}
              </div>
              <div className="flex flex-col">
                <span className="text-xs text-blue-600 font-medium">Your Rating</span>
                <span className="text-lg font-semibold">
                  {managerWeightedAverage ? managerWeightedAverage.toFixed(2) : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Updated StageProgress component in ManagerReviewSpace.tsx for mobile responsiveness:
// - Resize the bubbles for mobile (w-8 h-8 for non-current, w-10 h-10 for current) with overrides for sm screens.
// - Adjust icon and text sizes accordingly.
const StageProgress = ({ currentStage }: { currentStage: string }) => {
  const stages = [
    { key: 'goal_summary', label: 'Goals Review', icon: ClipboardList, description: 'Review and summarize goals' },
    { key: 'self_review', label: 'Self Rating', icon: UserCircle2, description: 'Employee rates performance' },
    { key: 'manager_review', label: 'Manager Rating', icon: Users, description: 'Manager evaluates performance' },
    { key: 'completed', label: 'Completed', icon: Check, description: 'Review cycle completed' }
  ];
  const currentIndex = stages.findIndex(stage => stage.key === currentStage);

  return (
    <div className="w-full mb-12 mt-4 px-6">
      <div className="relative flex justify-between items-center max-w-4xl mx-auto px-12">
        {/* Progress Line */}
        <div className="absolute top-1/2 left-12 right-12 h-[2px] bg-blue-100">
          <div 
            className="h-full bg-blue-500 transition-all duration-500 ease-in-out"
            style={{ 
              width: `${((currentIndex) / (stages.length - 1)) * 100}%`
            }}
          />
        </div>

        {/* Stages */}
        {stages.map((stage, index) => {
          const isCompleted = index < currentIndex;
          const isCurrent = index === currentIndex;
          const isUpcoming = index > currentIndex;

          return (
            <div key={stage.key} className={cn(
              "relative flex flex-col items-center min-w-[120px] transition-all duration-300",
              isCurrent && "scale-110"
            )}>
              {/* Circle with number/icon */}
              <div 
                className={cn(
                  "relative z-10 flex items-center justify-center rounded-full border-2 transition-all duration-200",
                  isCompleted && "border-blue-500 bg-blue-500 text-white w-8 h-8 sm:w-12 sm:h-12",
                  isCurrent && "border-blue-500 bg-white text-blue-500 ring-2 ring-blue-100 shadow-lg w-10 h-10 sm:w-14 sm:h-14",
                  isUpcoming && "border-gray-200 bg-white text-gray-400 w-8 h-8 sm:w-12 sm:h-12"
                )}
              >
                {isCompleted ? (
                  <Check className={cn("w-3 h-3 sm:w-5 sm:h-5", isCurrent && "w-4 h-4 sm:w-6 sm:h-6")}/>
                ) : (
                  <span className={cn("text-base font-semibold sm:text-lg", isCurrent && "sm:text-xl")}>{index + 1}</span>
                )}
              </div>
              
              {/* Label - hidden on mobile */}
              <div className={cn(
                "absolute -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap transition-all duration-200 hidden sm:block",
                isCurrent && "transform scale-105"
              )}>
                <p className={cn(
                  "text-sm font-medium transition-all duration-200",
                  isCompleted && "text-blue-500",
                  isCurrent && "text-blue-500 font-bold text-base",
                  isUpcoming && "text-gray-400"
                )}>
                  {stage.label}
                </p>
              </div>
              
              {/* Description - hidden on mobile */}
              <div className={cn(
                "absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap transition-all duration-200 hidden sm:block",
                isCurrent && "transform scale-105"
              )}>
                <p className={cn(
                  "text-xs transition-all duration-200",
                  isCompleted && "text-gray-600",
                  isCurrent && "text-blue-500 font-medium text-sm",
                  isUpcoming && "text-gray-400"
                )}>
                  {stage.description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ManagerReviewSpace = () => {
  const { user } = useAuth()
  const { toast } = useToast()
  const [directReports, setDirectReports] = useState<DirectReport[]>([])
  const [selectedEmployee, setSelectedEmployee] = useState<DirectReport | null>(null)
  const [employeeGoals, setEmployeeGoals] = useState<ReviewGoal[]>([])
  const [goalRatings, setGoalRatings] = useState<Record<number, number>>({})
  const [weightPoints, setWeightPoints] = useState<Record<number, number>>({})
  const [managerComments, setManagerComments] = useState<string>("")
  const [activeReviewCycle, setActiveReviewCycle] = useState<boolean>(false)

  useEffect(() => {
    const checkActiveReviewCycle = async () => {
      try {
        const response = await api.get('/api/reviews/cycles/', {
          params: { company: user?.company }
        });
        // Check if there's any active review cycle
        const hasActiveCycle = response.data.some((cycle: any) => 
          new Date(cycle.end_date) >= new Date() && new Date(cycle.start_date) <= new Date()
        );
        setActiveReviewCycle(hasActiveCycle);
        if (hasActiveCycle) {
          fetchDirectReports();
        }
      } catch (error) {
        console.error('Error checking active review cycle:', error);
        setActiveReviewCycle(false);
      }
    };

    if (user?.employeeId) {
      checkActiveReviewCycle();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchDirectReports = async () => {
    try {
      const response = await api.get(`/api/manager/${user?.employeeId}/direct-reports/`)
      if (response.status === 200) {
        // Fetch review stages for each direct report
        const reportsWithStages = await Promise.all(
          response.data.map(async (report: DirectReport) => {
            try {
              const reviewResponse = await api.get('/api/reviews/reviews/manager_pending/', {
                params: {
                  manager_id: user?.employeeId,
                  employee_id: report.employee_id
                }
              });
              if (reviewResponse.data.length > 0) {
                return {
                  ...report,
                  review_stage: reviewResponse.data[0].stage
                };
              }
              return report;
            } catch (error) {
              return report;
            }
          })
        );
        setDirectReports(reportsWithStages);
      }
    } catch (error) {
      console.error('Error fetching direct reports:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch direct reports",
      })
    }
  }

  const fetchEmployeeGoals = async (employeeId: string) => {
    try {
      const response = await api.get('/api/reviews/reviews/manager_pending/', {
        params: {
          manager_id: user?.employeeId,
          employee_id: employeeId
        }
      })
      
      console.log('API Response:', response.data);
      
      if (response.status === 200) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          const review = response.data[0];
          console.log('Review data:', review);
          if (review.goal_reviews && Array.isArray(review.goal_reviews)) {
            // Store the employee review ID along with the goals
            const goalsWithReviewId = review.goal_reviews.map((goal: Omit<ReviewGoal, 'employee_review'>) => ({
              ...goal,
              employee_review: review.id // Add the parent review ID to each goal
            }));
            setEmployeeGoals(goalsWithReviewId);

            // Initialize weightPoints from existing weightages
            const initialWeightPoints: Record<number, number> = {};
            const initialRatings: Record<number, number> = {};
            review.goal_reviews.forEach((goal: ReviewGoal) => {
              initialWeightPoints[goal.id] = goal.weightage || 0;
              // Initialize manager ratings if they exist
              if (goal.manager_rating) {
                initialRatings[goal.id] = goal.manager_rating;
              }
            });
            setWeightPoints(initialWeightPoints);
            setGoalRatings(initialRatings);

            // Initialize manager comments if they exist
            if (review.goal_reviews[0]?.manager_comments) {
              setManagerComments(review.goal_reviews[0].manager_comments);
            }

            console.log('Goals with review ID:', goalsWithReviewId);
          } else {
            console.error('Invalid goal_reviews structure:', review);
            setEmployeeGoals([]);
            setWeightPoints({});
            setGoalRatings({});
            setManagerComments("");
          }
        } else {
          console.log('No reviews found for employee:', employeeId);
          setEmployeeGoals([]);
          setWeightPoints({});
          setGoalRatings({});
          setManagerComments("");
        }
      } else {
        setEmployeeGoals([]);
        setWeightPoints({});
        setGoalRatings({});
        setManagerComments("");
      }
    } catch (error) {
      console.error('Error fetching employee goals:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch employee goals",
      });
      setEmployeeGoals([]);
      setWeightPoints({});
      setGoalRatings({});
      setManagerComments("");
    }
  }

  const handleEmployeeSelect = (employee: DirectReport) => {
    setSelectedEmployee(employee)
    fetchEmployeeGoals(employee.employee_id)
    setGoalRatings({})
    setWeightPoints({})
    setManagerComments("")
  }

  const handleRatingChange = (goalId: number, rating: number) => {
    setGoalRatings(prev => ({ ...prev, [goalId]: rating }))
  }

  const handleWeightChange = (goalId: number, points: number) => {
    setWeightPoints(prev => ({ ...prev, [goalId]: points }))
  }

  const submitManagerReview = async () => {
    if (!employeeGoals || employeeGoals.length === 0) return;
    
    try {
      const totalPoints = Object.values(weightPoints).reduce((a, b) => a + b, 0);
      
      if (totalPoints === 0) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please assign weight points to at least one goal",
        });
        return;
      }

      // Update all goal ratings, weightages and comments
      await Promise.all(employeeGoals.map(async goal => {
        if (!goal.employee_review) {
          console.error('Missing employee_review ID for goal:', goal);
          throw new Error('Missing employee review ID');
        }
        
        return api.patch(`/api/reviews/reviews/${goal.employee_review}/goals/${goal.id}/`, {
          manager_rating: goalRatings[goal.id],
          manager_comments: managerComments,
          weightage: weightPoints[goal.id] || 0
        });
      }));

      toast({
        title: "Success",
        description: "Manager review submitted successfully",
      })
      
      if (selectedEmployee?.employee_id) {
        fetchEmployeeGoals(selectedEmployee.employee_id)
      }
    } catch (error) {
      console.error('Error submitting manager review:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to submit manager review. Please try again.",
      })
    }
  }

  const totalPoints = Object.values(weightPoints).reduce((a, b) => a + b, 0);

  // Group direct reports by stage
  const reportsByStage = directReports.reduce((acc, report) => {
    const stage = report.review_stage || 'goal_summary';
    if (!acc[stage]) acc[stage] = [];
    acc[stage].push(report);
    return acc;
  }, {} as Record<string, DirectReport[]>);

  const stageInfo = {
    goal_summary: {
      label: 'Goals Review',
      icon: ClipboardList,
      description: 'Reviewing and summarizing goals'
    },
    self_review: {
      label: 'Self Review',
      icon: UserCircle2,
      description: 'Completing self evaluation'
    },
    manager_review: {
      label: 'Manager Review',
      icon: Users,
      description: 'Pending your review'
    },
    completed: {
      label: 'Completed',
      icon: Check,
      description: 'Reviews completed'
    }
  };

  return (
    <div className="h-full flex">
      {!activeReviewCycle ? (
        <div className="h-full w-full flex items-center justify-center">
          <div className="text-center max-w-md mx-auto px-4">
            <ClipboardList className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
            <h3 className="text-lg font-medium mb-2">No Active Review Cycle</h3>
            <p className="text-sm text-muted-foreground">
              There is currently no active review cycle. The next review cycle will be announced when it begins.
            </p>
          </div>
        </div>
      ) : directReports.length > 0 ? (
        <>
          {/* Left sidebar with accordion */}
          <div className="w-80 border-r bg-muted/10">
            <div className="p-4">
              <Accordion type="multiple" className="space-y-2">
                {Object.entries(stageInfo).map(([stage, info]) => {
                  const employees = reportsByStage[stage] || [];
                  const Icon = info.icon;
                  
                  return (
                    <AccordionItem 
                      key={stage} 
                      value={stage}
                      className={cn(
                        "border rounded-lg overflow-hidden",
                        employees.length > 0 && "border-black/10 shadow-sm",
                        employees.length === 0 && "border-dashed opacity-50"
                      )}
                    >
                      <AccordionTrigger className="px-4 py-3 hover:no-underline">
                        <div className="flex items-center gap-4 w-full">
                          <div className={cn(
                            "p-2 rounded-md",
                            stage === 'completed' && "bg-green-100 text-green-700",
                            stage === 'manager_review' && "bg-blue-100 text-blue-700",
                            stage === 'self_review' && "bg-yellow-100 text-yellow-700",
                            stage === 'goal_summary' && "bg-gray-100 text-gray-700",
                          )}>
                            <Icon className="h-4 w-4" />
                          </div>
                          <div className="flex-1 flex items-center justify-between">
                            <span className="font-medium">{info.label}</span>
                            {employees.length > 0 && (
                              <span className={cn(
                                "px-2 py-0.5 rounded-full text-xs font-medium",
                                stage === 'completed' && "bg-green-100 text-green-700",
                                stage === 'manager_review' && "bg-blue-100 text-blue-700",
                                stage === 'self_review' && "bg-yellow-100 text-yellow-700",
                                stage === 'goal_summary' && "bg-gray-100 text-gray-700",
                              )}>
                                {employees.length}
                              </span>
                            )}
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="px-4 pb-3 space-y-1">
                          {employees.length > 0 ? (
                            employees.map((employee) => (
                              <Button
                                key={employee.employee_id}
                                variant={selectedEmployee?.employee_id === employee.employee_id ? "secondary" : "ghost"}
                                className="w-full justify-start text-left h-auto py-2"
                                onClick={() => handleEmployeeSelect(employee)}
                              >
                                <div className="flex flex-col items-start">
                                  <span className="font-medium">{employee.name}</span>
                                  <span className="text-xs text-muted-foreground">{employee.title}</span>
                                </div>
                              </Button>
                            ))
                          ) : (
                            <p className="text-sm text-muted-foreground py-2 px-3">
                              No team members in this stage
                            </p>
                          )}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          </div>

          {/* Main content area */}
          <div className="flex-1 overflow-y-auto">
            {selectedEmployee ? (
              <div className="h-full">
                {/* Stage Progress */}
                {selectedEmployee.review_stage && (
                  <div className="pt-6">
                    <StageProgress currentStage={selectedEmployee.review_stage} />
                  </div>
                )}
                
                {/* Stage-specific content */}
                {selectedEmployee.review_stage === 'goal_summary' && (
                  <div className="px-6">
                    <Card>
                      <CardHeader>
                        <CardTitle>Goals Review</CardTitle>
                        <CardDescription>
                          {selectedEmployee.name} is currently reviewing and summarizing their goals.
                          You'll be notified when they're ready for your review.
                        </CardDescription>
                      </CardHeader>
                    </Card>
                  </div>
                )}

                {selectedEmployee.review_stage === 'self_review' && (
                  <div className="p-6">
                    <Card>
                      <CardHeader>
                        <CardTitle>Self Review in Progress</CardTitle>
                        <CardDescription>
                          {selectedEmployee.name} is currently completing their self-review.
                          You'll be notified when it's ready for your review.
                        </CardDescription>
                      </CardHeader>
                    </Card>
                  </div>
                )}

                {selectedEmployee.review_stage === 'manager_review' && (
                  <>
                    <WeightedRatingDisplay
                      employeeGoals={employeeGoals}
                      goalRatings={goalRatings}
                      employeeName={selectedEmployee.name}
                      weightPoints={weightPoints}
                      totalPoints={Object.values(weightPoints).reduce((a, b) => a + b, 0)}
                    />

                    <div className="p-6">
                      {/* Add Success State Card if any ratings exist */}
                      {employeeGoals.some(goal => goal.manager_rating) && (
                        <Card className="mb-6 border-blue-100 bg-blue-50/50">
                          <CardHeader>
                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-2">
                                <Check className="h-5 w-5 text-blue-500" />
                                <CardTitle className="text-lg text-blue-700">Review Submitted</CardTitle>
                              </div>
                              <div className="flex items-center gap-3 px-4 py-2 bg-white rounded-lg shadow-sm">
                                <div className="flex -space-x-1">
                                  {[1, 2, 3, 4, 5].map((star) => {
                                    const avgRating = employeeGoals.reduce((acc, goal) => {
                                      if (!goal.manager_rating) return acc;
                                      const weight = weightPoints[goal.id] || 0;
                                      const totalPoints = Math.max(Object.values(weightPoints).reduce((a, b) => a + b, 0), 1);
                                      return acc + (goal.manager_rating * (weight / totalPoints));
                                    }, 0);
                                    
                                    return (
                                      <Star 
                                        key={star}
                                        className={cn(
                                          "w-5 h-5",
                                          avgRating >= star 
                                            ? "fill-blue-500 text-blue-500" 
                                            : avgRating >= star - 0.5
                                            ? "fill-blue-500/50 text-blue-500"
                                            : "text-blue-200"
                                        )}
                                      />
                                    );
                                  })}
                                </div>
                                <span className="text-lg font-semibold text-blue-700">
                                  {employeeGoals.reduce((acc, goal) => {
                                    if (!goal.manager_rating) return acc;
                                    const weight = weightPoints[goal.id] || 0;
                                    const totalPoints = Math.max(Object.values(weightPoints).reduce((a, b) => a + b, 0), 1);
                                    return acc + (goal.manager_rating * (weight / totalPoints));
                                  }, 0).toFixed(2)}
                                </span>
                              </div>
                            </div>
                            <CardDescription className="text-blue-600">
                              You can continue to edit and update your ratings and feedback until the review cycle ends.
                            </CardDescription>
                          </CardHeader>
                        </Card>
                      )}

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {employeeGoals.map((goal) => (
                          <Card key={goal.id} className="group">
                            <CardContent className="pt-6">
                              <div className="space-y-6">
                                <div>
                                  <h3 className="text-lg font-medium whitespace-pre-wrap mb-2">{goal.goal_details.title}</h3>
                                  <p className="text-sm text-muted-foreground">{goal.goal_details.description}</p>
                                </div>

                                {/* Progress */}
                                <div className="space-y-2">
                                  <div className="flex justify-between text-sm">
                                    <span className="text-muted-foreground">Locked Progress</span>
                                    <span className={cn(
                                      "font-medium",
                                      goal.locked_progress < 30 ? "text-red-600" :
                                      goal.locked_progress < 70 ? "text-yellow-500" :
                                      "text-green-600"
                                    )}>{goal.locked_progress}%</span>
                                  </div>
                                  <Progress 
                                    value={goal.locked_progress} 
                                    className={cn(
                                      "h-2",
                                      goal.locked_progress < 30 
                                        ? "bg-red-100 [&>div]:bg-red-600" 
                                        : goal.locked_progress < 70 
                                        ? "bg-yellow-100 [&>div]:bg-yellow-500"
                                        : "bg-green-100 [&>div]:bg-green-600"
                                    )}
                                  />
                                </div>

                                {/* Self Rating */}
                                <div className="space-y-2">
                                  <Label className="text-sm text-muted-foreground">Employee's Rating</Label>
                                  <div className="flex items-center space-x-2">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                      <Star 
                                        key={star}
                                        className={cn(
                                          "w-5 h-5",
                                          (goal.self_rating || 0) >= star 
                                            ? "fill-yellow-400 text-yellow-400" 
                                            : "text-yellow-200"
                                        )}
                                      />
                                    ))}
                                    <span className="text-sm font-medium ml-2">
                                      {goal.self_rating ? `${goal.self_rating}/5` : 'Not rated'}
                                    </span>
                                  </div>
                                </div>

                                {/* Manager Rating */}
                                <div className="space-y-2">
                                  <Label className="text-sm text-muted-foreground">Your Rating</Label>
                                  <div className="flex items-center space-x-2">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                      <button
                                        key={star}
                                        onClick={() => handleRatingChange(goal.id, star)}
                                        className="text-blue-400 transition-all hover:scale-110"
                                      >
                                        <Star 
                                          className={cn(
                                            "w-5 h-5",
                                            (goalRatings[goal.id] || 0) >= star ? "fill-current" : "fill-none"
                                          )} 
                                        />
                                      </button>
                                    ))}
                                    <span className="text-sm font-medium ml-2">
                                      {goalRatings[goal.id] ? `${goalRatings[goal.id]}/5` : 'Not rated'}
                                    </span>
                                  </div>
                                </div>

                                {/* Weight Points */}
                                <div className="space-y-2">
                                  <div className="flex items-center justify-between">
                                    <Label className="text-sm text-muted-foreground">Goal Weight</Label>
                                    <span className="text-sm font-medium text-blue-500">
                                      {((weightPoints[goal.id] || 0) / Math.max(totalPoints, 1) * 100).toFixed(1)}%
                                    </span>
                                  </div>
                                  <WeightPoints 
                                    value={weightPoints[goal.id] || 0} 
                                    onChange={(points) => handleWeightChange(goal.id, points)}
                                  />
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        ))}
                      </div>

                      {/* Employee's Overall Comments */}
                      {employeeGoals[0]?.self_comments && (
                        <div className="mt-8">
                          <Card>
                            <CardHeader>
                              <CardTitle className="text-lg">Employee's Comments</CardTitle>
                              <CardDescription>
                                {selectedEmployee.name}'s self-assessment and comments
                              </CardDescription>
                            </CardHeader>
                            <CardContent>
                              <p className="text-sm text-muted-foreground bg-secondary/20 p-4 rounded-lg">
                                {employeeGoals[0].self_comments}
                              </p>
                            </CardContent>
                          </Card>
                        </div>
                      )}

                      {/* Manager Comments */}
                      <div className="mt-8">
                        <Card>
                          <CardHeader>
                            <CardTitle className="text-lg">Overall Feedback</CardTitle>
                            <CardDescription>
                              Provide comprehensive feedback about the employee's performance
                            </CardDescription>
                          </CardHeader>
                          <CardContent>
                            <Textarea
                              value={managerComments}
                              onChange={(e) => setManagerComments(e.target.value)}
                              placeholder="Add your overall feedback and comments..."
                              className="min-h-[150px]"
                            />
                          </CardContent>
                        </Card>
                      </div>

                      {/* Submit Button */}
                      <div className="mt-8 flex justify-end">
                        <Button 
                          onClick={submitManagerReview}
                          disabled={!employeeGoals.every(goal => 
                            goalRatings[goal.id] && weightPoints[goal.id] !== undefined
                          )}
                        >
                          {employeeGoals.some(goal => goal.manager_rating) ? "Update Review" : "Submit Review"}
                        </Button>
                      </div>
                    </div>
                  </>
                )}

                {selectedEmployee.review_stage === 'completed' && (
                  <div className="p-6">
                    <Card className="mb-6">
                      <CardHeader>
                        <div className="flex items-center justify-between">
                          <div>
                            <CardTitle>Review Completed</CardTitle>
                            <CardDescription>
                              Performance review for {selectedEmployee.name}
                            </CardDescription>
                          </div>
                          <div className="flex items-center gap-3 px-4 py-2 bg-green-50 rounded-lg">
                            <div className="flex -space-x-1">
                              {[1, 2, 3, 4, 5].map((star) => {
                                const finalRating = employeeGoals.reduce((acc, goal) => {
                                  if (!goal.manager_rating) return acc;
                                  const weight = weightPoints[goal.id] || 0;
                                  const totalPoints = Object.values(weightPoints).reduce((a, b) => a + b, 0);
                                  return acc + (goal.manager_rating * (weight / totalPoints));
                                }, 0);
                                
                                return (
                                  <Star 
                                    key={star}
                                    className={cn(
                                      "w-5 h-5",
                                      finalRating >= star 
                                        ? "fill-green-500 text-green-500" 
                                        : finalRating >= star - 0.5
                                        ? "fill-green-500/50 text-green-500"
                                        : "text-green-200"
                                    )}
                                  />
                                );
                              })}
                            </div>
                            <div className="flex flex-col">
                              <span className="text-xs text-green-600 font-medium">Final Rating</span>
                              <span className="text-lg font-semibold text-green-700">
                                {employeeGoals.reduce((acc, goal) => {
                                  if (!goal.manager_rating) return acc;
                                  const weight = weightPoints[goal.id] || 0;
                                  const totalPoints = Object.values(weightPoints).reduce((a, b) => a + b, 0);
                                  return acc + (goal.manager_rating * (weight / totalPoints));
                                }, 0).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </CardHeader>
                    </Card>

                    {/* Display Goals Summary */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {employeeGoals.map((goal) => (
                        <Card key={goal.id} className="group">
                          <CardContent className="pt-6">
                            <div className="space-y-4">
                              <div>
                                <h3 className="text-lg font-medium whitespace-pre-wrap mb-2">{goal.goal_details.title}</h3>
                              </div>

                              <div className="space-y-4">
                                {/* Self Rating */}
                                <div className="space-y-2">
                                  <Label className="text-sm text-muted-foreground">Employee's Rating</Label>
                                  <div className="flex items-center space-x-2">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                      <Star 
                                        key={star}
                                        className={cn(
                                          "w-5 h-5",
                                          (goal.self_rating || 0) >= star 
                                            ? "fill-yellow-400 text-yellow-400" 
                                            : "text-yellow-200"
                                        )}
                                      />
                                    ))}
                                    <span className="text-sm font-medium ml-2">
                                      {goal.self_rating ? `${goal.self_rating}/5` : 'Not rated'}
                                    </span>
                                  </div>
                                </div>

                                {/* Manager Rating */}
                                <div className="space-y-2">
                                  <Label className="text-sm text-muted-foreground">Your Rating</Label>
                                  <div className="flex items-center space-x-2">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                      <Star 
                                        key={star}
                                        className={cn(
                                          "w-5 h-5",
                                          (goal.manager_rating || 0) >= star 
                                            ? "fill-blue-400 text-blue-400" 
                                            : "text-blue-200"
                                        )}
                                      />
                                    ))}
                                    <span className="text-sm font-medium ml-2">
                                      {goal.manager_rating ? `${goal.manager_rating}/5` : 'Not rated'}
                                    </span>
                                  </div>
                                </div>

                                {/* Weight */}
                                <div className="space-y-2">
                                  <Label className="text-sm text-muted-foreground">Goal Weight</Label>
                                  <div className="text-sm font-medium text-blue-500">
                                    {((weightPoints[goal.id] || 0) / Math.max(totalPoints, 1) * 100).toFixed(1)}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                    </div>

                    {/* Comments Section */}
                    {(employeeGoals[0]?.self_comments || employeeGoals[0]?.manager_comments) && (
                      <div className="mt-8 space-y-6">
                        {employeeGoals[0]?.self_comments && (
                          <Card>
                            <CardHeader>
                              <CardTitle className="text-lg">Employee's Comments</CardTitle>
                              <CardDescription>
                                {selectedEmployee.name}'s self-assessment and overall performance comments
                              </CardDescription>
                            </CardHeader>
                            <CardContent>
                              <p className="text-sm text-muted-foreground bg-secondary/20 p-4 rounded-lg">
                                {employeeGoals[0].self_comments}
                              </p>
                            </CardContent>
                          </Card>
                        )}

                        {employeeGoals[0]?.manager_comments && (
                          <Card>
                            <CardHeader>
                              <CardTitle className="text-lg">Manager's Feedback</CardTitle>
                              <CardDescription>
                                Overall feedback and evaluation
                              </CardDescription>
                            </CardHeader>
                            <CardContent>
                              <p className="text-sm text-muted-foreground bg-blue-50 p-4 rounded-lg">
                                {employeeGoals[0].manager_comments}
                              </p>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="h-full flex items-center justify-center">
                <div className="text-center">
                  <h3 className="text-lg font-medium">Select a team member</h3>
                  <p className="text-sm text-muted-foreground">
                    Choose a team member from the list to view or manage their review
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default ManagerReviewSpace 