import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { Target, Users } from 'lucide-react';
import PromoterDashboard from './PromoterDashboard';
import EmployeeReviewPortal from './EmployeeReviewPortal';
import ManagerReviewSpace from './ManagerReviewSpace';
import api from '../../api';

const Reviews = () => {
  const { user } = useAuth();
  const [hasTeam, setHasTeam] = useState(false);
  const isPromoter = user?.role === 'admin' || user?.role === 'promoter' || user?.role === 'hr_admin';

  useEffect(() => {
    const checkTeam = async () => {
      if (user?.employeeId) {
        try {
          const response = await api.get(`/api/manager/${user.employeeId}/direct-reports/`);
          setHasTeam(response.data.length > 0);
        } catch (error) {
          console.error('Error checking team:', error);
          setHasTeam(false);
        }
      }
    };

    checkTeam();
  }, [user]);

  if (isPromoter) {
    return (
      <div className="w-full mx-auto px-4 py-6 sm:max-w-7xl">
        <PromoterDashboard />
      </div>
    );
  }

  // If user has no team, only show the employee review portal
  if (!hasTeam) {
    return (
      <div className="w-full mx-auto px-4 py-6 sm:max-w-7xl">
        <EmployeeReviewPortal />
      </div>
    );
  }

  // Show tabs if user has team members
  return (
    <div className="min-h-screen bg-background">
      <Tabs defaultValue="my-review">
        <div className="bg-background">
          <div className="w-full mx-auto px-4 py-6 sm:max-w-7xl flex justify-center">
            <TabsList className="flex flex-row items-center justify-center rounded-lg bg-blue-100 p-2 shadow-sm space-x-2">
              <TabsTrigger 
                value="my-review" 
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md px-4 sm:px-8 py-2 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:text-blue-700 data-[state=active]:shadow-md"
              >
                <Target className="mr-2 h-4 w-4" />
                My Review
              </TabsTrigger>
              <TabsTrigger 
                value="team-reviews"
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md px-4 sm:px-8 py-2 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:text-blue-700 data-[state=active]:shadow-md"
              >
                <Users className="mr-2 h-4 w-4" />
                Team Reviews
              </TabsTrigger>
            </TabsList>
          </div>
        </div>

        <div className="w-full mx-auto px-4 py-6 sm:max-w-7xl">
          <TabsContent value="my-review">
            <EmployeeReviewPortal />
          </TabsContent>

          <TabsContent value="team-reviews">
            <ManagerReviewSpace />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
};

export default Reviews; 