/* eslint-disable @typescript-eslint/no-unused-vars */
"use client"

import { useState, useEffect, useMemo, useRef, useCallback } from "react"
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Textarea } from "../components/ui/textarea"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../components/ui/dialog"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs"
import { Progress } from "../components/ui/progress"
import { Label } from "../components/ui/label"
import { ChevronLeft, ChevronRight, X, Edit3, Trash2, Plus, ChevronDown, Check, Users, Archive } from "lucide-react"
import { useAuth } from '../contexts/AuthContext';
import api from '../api';
import { Slider } from "./ui/slider"
// import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogCancel, AlertDialogAction, AlertDialogTrigger } from "./ui/alert-dialog"
import { Star } from "lucide-react"
import { Search } from "lucide-react"
import { cn } from "../lib/utils"

type GoalUpdate = {
  id: number;
  type: "self" | "internal" | "external";
  content: string;
  progress: number;
  raters: string[];
  review_status: 'no_review' | 'pending' | 'awaiting_feedback' | 'completed';
  created_at: string;
  review_requests: ReviewRequest[];
};

type Goal = {
  id: number;
  title: string;
  description: string;
  goal_type: 'personal' | 'company';
  progress: number;
  owner: string | null;
  owner_details: {
    id: number;
    employee_id: string;
    name: string;
    email: string;
    date_of_joining: string;
    is_active: boolean;
    competencies: string[];
  } | null;
  internal_reviewer_details: Array<{
    id: number;
    employee_id: string;
    name: string;
    email: string;
    date_of_joining: string;
    is_active: boolean;
    competencies: string[];
  }>;
  external_reviewers: string[];
  timeline: string;
  timeline_duration: string;
  timeline_year: number;
  company: string;
  created_at: string;
  updated_at: string;
  updates: GoalUpdate[];
  parent_goal?: number | null;
  parent_goal_details?: {
    id: number;
    title: string;
    progress: number;
  } | null;
  has_pending_changes: boolean;
  change_requests: ChangeRequest[];
};

type ChangeRequest = {
  id: number;
  goal: number;
  change_type: 'edit' | 'delete';
  requested_by: {
    id: number;
    employee_id: string;
    name: string;
  };
  reviewed_by: {
    id: number;
    employee_id: string;
    name: string;
  } | null;
  status: 'pending' | 'approved' | 'rejected';
  previous_state: Goal | null;
  requested_state: {
    description?: string;
    timeline?: string;
    internal_reviewer_details?: Array<{
      id: number;
      employee_id: string;
      name: string;
      email: string;
      date_of_joining: string;
      is_active: boolean;
      competencies: string[];
    }>;
    external_reviewers?: string[];
  } | null;
  reviewer_comments: string;
  created_at: string;
  updated_at: string;
};

// type Update = {
//   id: string;
//   goalId: number;
//   type: "self" | "internal" | "external";
//   content: string;
//   progress: number;
//   date: string;
//   raters?: string[];
// };

type User = {
  userId?: string;
  employeeId?: string;
  company?: string;
  role?: string;
  name?: string;
};

type Employee = {
  id: number;
  employee_id: string;
  name: string;
  email: string;
  date_of_joining: string;
  is_active: boolean;
};

type ReviewRequest = {
  reviewer_email: string;
  status: 'pending' | 'completed';
  rating?: number;
  comment?: string;
  created_at: string;
  completed_at?: string;
};

interface GoalsProps {
  isEmployeeView?: boolean;
  employeeId?: string;
}

function ReviewerSearchInput({ 
  onSelect, 
  selectedReviewers,
  employees,
  allowExternal = true
}: { 
  onSelect: (reviewer: { email: string; name?: string; type: 'internal' | 'external' }) => void;
  selectedReviewers: { email: string; name?: string; type: 'internal' | 'external' }[];
  employees: Employee[];
  allowExternal?: boolean;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const filteredResults = useMemo(() => {
    if (!searchTerm) return [];
    
    const term = searchTerm.toLowerCase();
    
    // Filter employees
    const matchingEmployees = employees
      .filter(emp => 
        emp.name.toLowerCase().includes(term) || 
        emp.email.toLowerCase().includes(term)
      )
      .map(emp => ({
        email: emp.email,
        name: emp.name,
        type: 'internal' as const
      }));

    // Add option for external email if it looks like an email and is allowed
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(searchTerm);
    const externalOption = allowExternal && isEmail ? [{
      email: searchTerm,
      type: 'external' as const
    }] : [];

    return [...matchingEmployees, ...externalOption];
  }, [searchTerm, employees, allowExternal]);

  return (
    <div className="relative">
      <Input
        placeholder="Search by name or email..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setShowDropdown(true);
        }}
        onFocus={() => setShowDropdown(true)}
      />
      
      {showDropdown && filteredResults.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-background border rounded-md shadow-lg max-h-48 overflow-y-auto">
          {filteredResults.map((result) => {
            const isSelected = selectedReviewers.some(r => r.email === result.email);
            return (
              <button
                key={result.email}
                className={cn(
                  "w-full px-4 py-2 text-left hover:bg-accent flex items-center gap-2",
                  isSelected && "opacity-50 cursor-not-allowed"
                )}
                onClick={() => {
                  if (!isSelected) {
                    onSelect(result);
                    setSearchTerm('');
                    setShowDropdown(false);
                  }
                }}
                disabled={isSelected}
              >
                {result.type === 'internal' ? (
                  <>
                    <span className="text-sm font-medium">{result.name}</span>
                    <span className="text-sm text-muted-foreground">({result.email})</span>
                  </>
                ) : (
                  <span className="text-sm">{result.email}</span>
                )}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}

function ProgressUpdateForm({
  goalId,
  addUpdate,
  onClose
}: {
  goalId: number;
  addUpdate: (goalId: number, update: Omit<GoalUpdate, 'id'>) => Promise<void>;
  onClose: () => void;
}) {
  const [content, setContent] = useState("");
  const [progress, setProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      const updateData: Omit<GoalUpdate, 'id'> = {
        type: 'self',
        content,
        progress,
        raters: [],
        review_status: 'no_review',
        created_at: new Date().toISOString(),
        review_requests: []
      };

      await addUpdate(goalId, updateData);
      onClose();
    } catch (error) {
      console.error('Error submitting progress update:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="progress-update">Update Details</Label>
        <Textarea
          id="progress-update"
          placeholder="Describe your progress..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="progress">Progress ({progress}%)</Label>
        <div className="relative">
          <Progress 
            id="progress"
            value={progress} 
            className="h-3 bg-gray-200 border border-gray-300"
          />
          <Slider
            min={0}
            max={100}
            step={1}
            value={[progress]}
            onValueChange={(value) => setProgress(value[0])}
            className="absolute top-0 w-full"
          />
        </div>
      </div>

      <div className="flex justify-end space-x-2">
        <Button type="button" variant="outline" onClick={onClose}>Cancel</Button>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
              Submitting...
            </>
          ) : (
            'Submit Progress'
          )}
        </Button>
      </div>
    </form>
  );
}

function FeedbackRequestForm({
  goalId,
  addUpdate,
  goal,
  employees,
  onClose
}: {
  goalId: number;
  addUpdate: (goalId: number, update: Omit<GoalUpdate, 'id'>) => Promise<void>;
  goal: Goal;
  employees: Employee[];
  onClose: () => void;
}) {
  const [content, setContent] = useState("");
  const [selectedReviewers, setSelectedReviewers] = useState<Array<{ email: string; name?: string; type: 'internal' | 'external' }>>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      // Determine if we have any external reviewers
      const hasExternal = selectedReviewers.some(r => r.type === 'external');
      
      const updateData: Omit<GoalUpdate, 'id'> = {
        // If we have any external reviewers, mark as external type, otherwise internal
        type: hasExternal ? 'external' : 'internal',
        content,
        progress: 0,
        raters: selectedReviewers.map(r => r.email),
        review_status: 'pending',
        created_at: new Date().toISOString(),
        review_requests: []
      };

      await addUpdate(goalId, updateData);
      onClose();
    } catch (error) {
      console.error('Error submitting feedback request:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="feedback-request">Request Details</Label>
        <Textarea
          id="feedback-request"
          placeholder="Describe what feedback you're looking for..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />
      </div>

      <div>
        <Label>Add Reviewers</Label>
        <ReviewerSearchInput
          onSelect={(reviewer) => setSelectedReviewers(prev => [...prev, reviewer])}
          selectedReviewers={selectedReviewers}
          employees={employees}
          allowExternal={true} // Always allow external reviewers
        />
        
        <div className="mt-2 space-y-2">
          {selectedReviewers.map((reviewer) => (
            <div key={reviewer.email} className="flex items-center justify-between bg-muted p-2 rounded-md">
              <span className="text-sm">
                {reviewer.name ? `${reviewer.name} (${reviewer.email})` : reviewer.email}
              </span>
              <button
                type="button"
                onClick={() => setSelectedReviewers(prev => prev.filter(r => r.email !== reviewer.email))}
                className="text-red-500 hover:text-red-700"
              >
                <X size={16} />
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-end space-x-2">
        <Button type="button" variant="outline" onClick={onClose}>Cancel</Button>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
              Requesting...
            </>
          ) : (
            'Request Feedback'
          )}
        </Button>
      </div>
    </form>
  );
}

const EmailTokenInput = ({ 
  emails, 
  onChange 
}: { 
  emails: string[], 
  onChange: (emails: string[]) => void 
}) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const addEmail = (email: string) => {
    const trimmedEmail = email.trim();
    if (trimmedEmail && isValidEmail(trimmedEmail) && !emails.includes(trimmedEmail)) {
      onChange([...emails, trimmedEmail]);
    }
    setInputValue('');
  };

  const removeEmail = (indexToRemove: number) => {
    onChange(emails.filter((_, index) => index !== indexToRemove));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      addEmail(inputValue);
    } else if (e.key === 'Backspace' && !inputValue && emails.length > 0) {
      removeEmail(emails.length - 1);
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const pastedEmails = pastedText.split(/[\s,]+/);
    
    const validEmails = pastedEmails
      .map(email => email.trim())
      .filter(email => email && isValidEmail(email) && !emails.includes(email));
    
    if (validEmails.length > 0) {
      onChange([...emails, ...validEmails]);
    }
  };

  return (
    <div 
      className="flex flex-wrap gap-2 p-2 border rounded-md bg-background min-h-[42px]"
      onClick={() => inputRef.current?.focus()}
    >
      {emails.map((email, index) => (
        <div
          key={index}
          className="flex items-center gap-1 bg-secondary px-2 py-1 rounded-md group"
        >
          <span className="text-sm">{email}</span>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              removeEmail(index);
            }}
            className="text-muted-foreground hover:text-foreground"
          >
            <X size={14} />
          </button>
        </div>
      ))}
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={() => {
          if (inputValue) addEmail(inputValue);
        }}
        onPaste={handlePaste}
        className="flex-1 min-w-[200px] bg-transparent border-none outline-none text-sm"
        placeholder={emails.length === 0 ? "Type email and press space or enter..." : "Add another email..."}
      />
    </div>
  );
};

const EmployeeSelector = ({ 
  selectedEmployees, 
  onUpdate, 
  onClose,
  allEmployees,
  singleSelect = false
}: { 
  selectedEmployees: Employee[], 
  onUpdate: (employees: Employee[]) => void, 
  onClose: () => void,
  allEmployees: Employee[],
  singleSelect?: boolean
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [localSelectedEmployees, setLocalSelectedEmployees] = useState<Employee[]>(selectedEmployees);

  const filteredEmployees = allEmployees.filter(
    emp => emp.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
           emp.employee_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleEmployee = (employee: Employee) => {
    if (singleSelect) {
      setLocalSelectedEmployees([employee]);
      // For single select, automatically update and close
      onUpdate([employee]);
      onClose();
    } else {
      setLocalSelectedEmployees(prev => {
        const isSelected = prev.some(e => e.employee_id === employee.employee_id);
        return isSelected
          ? prev.filter(e => e.employee_id !== employee.employee_id)
          : [...prev, employee];
      });
    }
  };

  const handleDone = () => {
    onUpdate(localSelectedEmployees);
    onClose();
  };

  return (
    <div className="w-full bg-background border rounded-md shadow-lg">
      <div className="p-2">
        <Input
          type="text"
          placeholder="Search employees..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full"
          autoComplete="off"
        />
      </div>
      <div className="max-h-60 overflow-y-auto">
        {filteredEmployees.map(employee => (
          <div
            key={employee.employee_id}
            className="p-2 hover:bg-accent cursor-pointer flex justify-between items-center"
            onClick={() => toggleEmployee(employee)}
          >
            <span>{employee.name}</span>
            {localSelectedEmployees.some(e => e.employee_id === employee.employee_id) && (
              <Check className="h-4 w-4 text-green-500" />
            )}
          </div>
        ))}
      </div>
      {!singleSelect && (
        <div className="p-2 border-t flex justify-end">
          <Button
            onClick={handleDone}
            size="sm"
          >
            Done
          </Button>
        </div>
      )}
    </div>
  );
};

const AverageRating = ({ goal }: { goal: Goal }) => {
  // Extract all numerical ratings from goal updates
  const ratings = goal.updates.flatMap(update =>
    update.review_requests
      .map(req => req.rating)
      .filter(rating => typeof rating === 'number')
  );
  const avg = ratings.length ? ratings.reduce((a, b) => a + b, 0) / ratings.length : 0;
  if (avg === 0) return null;
  return (
    <div className="flex items-center gap-1 mt-2">
      {[1, 2, 3, 4, 5].map(star => (
        <Star
          key={star}
          className={avg >= star ? "text-yellow-500" : "text-gray-300"}
          size={16}
          fill="currentColor"
          strokeWidth={0}
        />
      ))}
      <span className="text-xs text-muted-foreground">({avg.toFixed(1)})</span>
    </div>
  );
};

export default function Goals({ isEmployeeView = false, employeeId }: GoalsProps) {
  const { user } = useAuth();
  const [goals, setGoals] = useState<Goal[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<User>({
    userId: "1",
    employeeId: "Default User",
    role: "employee"
  });
  const [personalGoals, setPersonalGoals] = useState<Goal[]>([]);
  const [isPersonalDialogOpen, setIsPersonalDialogOpen] = useState(false);
  const [isCompanyDialogOpen, setIsCompanyDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingGoalId, setEditingGoalId] = useState<number | null>(null);
  const [updatingGoalId, setUpdatingGoalId] = useState<number | null>(null);
  const [updateType, setUpdateType] = useState<'progress' | 'feedback' | null>(null);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [showPendingOnly, setShowPendingOnly] = useState(false);
  const [showReviewNeeded, setShowReviewNeeded] = useState(false);
  const canApproveChanges = useMemo(() => {
    if (!user?.role) return false;
    return ['admin', 'promoter', 'manager'].includes(user.role);
  }, [user?.role]);

  const fetchGoals = useCallback(async () => {
    if (!user?.company) return;
    
    try {
      setLoading(true);
      
      if (isEmployeeView && employeeId) {
        // For employee view, fetch their personal goals
        const response = await api.get(`/api/companies/${user.company}/goals/`, {
          params: {
            type: 'personal',
            employee_id: employeeId,
            include_updates: true,
            include_change_requests: true
          }
        });
        setPersonalGoals(response.data);
        setGoals([]); // Clear company goals in employee view
      } else if (!isEmployeeView) {
        // For admin/promoter view, fetch both types of goals
        const [companyResponse, personalResponse] = await Promise.all([
          api.get(`/api/companies/${user.company}/goals/`, {
            params: { 
              type: 'company',
              include_updates: true,
              include_change_requests: true
            }
          }),
          api.get(`/api/companies/${user.company}/goals/`, {
            params: {
              type: 'personal',
              include_updates: true,
              include_change_requests: true
            }
          })
        ]);
        
        setGoals(companyResponse.data);
        setPersonalGoals(personalResponse.data);
      }
      
      setError(null);
    } catch (err) {
      console.error('Error fetching goals:', err);
      setError('Failed to fetch goals');
    } finally {
      setLoading(false);
    }
  }, [user?.company, employeeId, isEmployeeView]);

  useEffect(() => {
    if (user) {
      setCurrentUser({
        userId: user.userId || "1",
        employeeId: user.employeeId || "Default User",
        role: user.role === "promoter" ? "founder" : "employee",
        company: user.company
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchEmployees = async () => {
      if (!user?.company) return;
      
      try {
        const response = await api.get(`/api/employees/${user.company}`);
        setEmployees(response.data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, [user?.company]);

  useEffect(() => {
    fetchGoals();
  }, [fetchGoals]);

  const addGoal = async (newGoal: Omit<Goal, 'id' | 'created_at' | 'updated_at'>) => {
    if (!user?.company) return;

    try {
      const payload = {
        ...newGoal,
        goal_type: newGoal.goal_type,
        owner: newGoal.goal_type === 'personal' ? 
          (user.role === 'promoter' ? newGoal.owner : user.employeeId) : 
          undefined
      };

      const response = await api.post(
        `/api/companies/${user.company}/goals/`,
        payload
      );

      if (newGoal.goal_type === 'personal') {
        setPersonalGoals(prevGoals => [...prevGoals, response.data]);
      } else {
        setGoals(prevGoals => [...prevGoals, response.data]);
      }

      return response.data;
    } catch (error) {
      console.error('Error creating goal:', error);
      // Re-throw the error so it can be handled by the form
      throw error;
    }
  };

  const addUpdate = async (goalId: number, updateData: Omit<GoalUpdate, 'id'>) => {
    if (!user?.company) return;
    
    try {
      const response = await api.post(
        `/api/companies/${user.company}/goals/${goalId}/updates/`, 
        updateData
      );

      // Update the goals state with the new update and progress
      const updateGoals = (goals: Goal[]) => 
        goals.map(goal => {
          if (goal.id === goalId) {
            return {
              ...goal,
              progress: updateData.progress || goal.progress, // Update progress
              updates: [response.data, ...goal.updates] // Add new update at the beginning
            };
          }
          
          // If this is a company goal and the updated goal is its child, update parent progress
          if (goal.id === goal.parent_goal_details?.id) {
            const childGoals = goals.filter(g => g.parent_goal_details?.id === goal.id);
            const avgProgress = Math.round(
              childGoals.reduce((sum, g) => sum + g.progress, 0) / childGoals.length
            );
            return {
              ...goal,
              progress: avgProgress
            };
          }
          
          return goal;
        });

      // Update both goal lists
      setGoals(prev => updateGoals(prev));
      setPersonalGoals(prev => updateGoals(prev));

    } catch (error) {
      console.error('Error adding update:', error);
      throw error;
    }
  };

  const defaultTab = user?.role === "promoter" ? "all-goals" : "personal-goals"

  const updateGoal = async (goalId: number, updatedData: Partial<Goal>) => {
    if (!user?.company) return;

    try {
      // For admin/promoter, or if employee is modifying someone else's goal, use direct update
      const updatedGoal = goals.concat(personalGoals).find(g => g.id === goalId);
      const isOwnGoal = updatedGoal?.owner_details?.employee_id === user.employeeId;
      
      if (user.role === 'admin' || user.role === 'promoter' || !isOwnGoal) {
        const response = await api.put(
          `/api/companies/${user.company}/goals/${goalId}/`,
          updatedData
        );

        // Update the appropriate goals list
        if (response.data.goal_type === 'personal') {
          setPersonalGoals(prev => prev.map(goal => 
            goal.id === goalId ? response.data : goal
          ));
        } else {
          setGoals(prev => prev.map(goal => 
            goal.id === goalId ? response.data : goal
          ));
        }
      } else {
        // For employees modifying their own goals, create a change request
        const currentEmployee = employees.find(e => e.employee_id === user.employeeId);
        if (!currentEmployee) {
          throw new Error('Employee not found');
        }

        await api.post(
          `/api/companies/${user.company}/goal-changes/`,
          {
            goal: goalId,
            change_type: 'edit',
            requested_state: updatedData,
            requested_by: currentEmployee.id
          }
        );

        // Update the goal to show pending changes
        if (updatedGoal) {
          const newGoal = { ...updatedGoal, has_pending_changes: true };
          if (newGoal.goal_type === 'personal') {
            setPersonalGoals(prev => prev.map(goal => 
              goal.id === goalId ? newGoal : goal
            ));
          } else {
            setGoals(prev => prev.map(goal => 
              goal.id === goalId ? newGoal : goal
            ));
          }
        }
      }
    } catch (error) {
      console.error('Error updating goal:', error);
      throw error;
    }
  };

  const deleteGoal = async (goalId: number, goalType: 'personal' | 'company') => {
    if (!user?.company) return;

    try {
      // For admin/promoter, or if employee is modifying someone else's goal, use direct delete
      const goalToDelete = goals.concat(personalGoals).find(g => g.id === goalId);
      const isOwnGoal = goalToDelete?.owner_details?.employee_id === user.employeeId;

      if (user.role === 'admin' || user.role === 'promoter' || !isOwnGoal) {
        await api.delete(`/api/companies/${user.company}/goals/${goalId}/`);
        
        // Remove from the appropriate goals list
        if (goalType === 'personal') {
          setPersonalGoals(prev => prev.filter(goal => goal.id !== goalId));
        } else {
          setGoals(prev => prev.filter(goal => goal.id !== goalId));
        }
      } else {
        // For employees deleting their own goals, create a change request
        const currentEmployee = employees.find(e => e.employee_id === user.employeeId);
        if (!currentEmployee) {
          throw new Error('Employee not found');
        }

        await api.post(
          `/api/companies/${user.company}/goal-changes/`,
          {
            goal: goalId,
            change_type: 'delete',
            requested_by: currentEmployee.id
          }
        );

        // Update the goal to show pending changes
        if (goalToDelete) {
          const newGoal = { ...goalToDelete, has_pending_changes: true };
          if (goalType === 'personal') {
            setPersonalGoals(prev => prev.map(goal => 
              goal.id === goalId ? newGoal : goal
            ));
          } else {
            setGoals(prev => prev.map(goal => 
              goal.id === goalId ? newGoal : goal
            ));
          }
        }
      }
    } catch (error) {
      console.error('Error deleting goal:', error);
      throw error;
    }
  };

  // Calculate number of goals needing review
  const goalsNeedingReview = useMemo(() => {
    const allGoals = [...goals, ...personalGoals];
    return allGoals.filter(goal => goal.change_requests?.some(cr => cr.status === 'pending')).length;
  }, [goals, personalGoals]);

  // Filter personal goals based on all criteria
  const filteredPersonalGoals = useMemo(() => {
    return personalGoals.filter(goal => {
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = 
        goal.description.toLowerCase().includes(searchLower) ||
        (goal.owner_details?.name.toLowerCase().includes(searchLower)) ||
        goal.timeline.toLowerCase().includes(searchLower);
      
      // Add pending changes filter
      if (showPendingOnly) {
        return matchesSearch && goal.change_requests?.some(cr => cr.status === 'pending');
      }

      // Add review needed filter
      if (showReviewNeeded) {
        return matchesSearch && goal.change_requests?.some(cr => cr.status === 'pending');
      }
      
      return matchesSearch;
    });
  }, [personalGoals, searchTerm, showPendingOnly, showReviewNeeded]);

  // Group filtered goals by employee
  const groupedGoals = useMemo(() => {
    return filteredPersonalGoals.reduce((acc, goal) => {
      const employeeName = goal.owner_details?.name || 'Unassigned';
      if (!acc[employeeName]) {
        acc[employeeName] = [];
      }
      acc[employeeName].push(goal);
      return acc;
    }, {} as Record<string, Goal[]>);
  }, [filteredPersonalGoals]);

  // Determine if user can add new goals
  const canAddCompanyGoals = user?.role === 'admin' || user?.role === 'promoter';
  const canAddPersonalGoals = user?.role === 'admin' || 
                           user?.role === 'promoter' || 
                           user?.role === 'employee';

  // Update the button component to be more prominent when there are items to review
  const ReviewButton = () => {
    if (!canApproveChanges) return null;
    
    return (
      <Button
        variant={showReviewNeeded ? "default" : goalsNeedingReview > 0 ? "secondary" : "outline"}
        onClick={() => {
          setShowReviewNeeded(!showReviewNeeded);
          setShowPendingOnly(false);
        }}
        className={cn(
          "whitespace-nowrap relative font-medium",
          goalsNeedingReview > 0 && !showReviewNeeded && "bg-yellow-100 hover:bg-yellow-200 border-yellow-400 text-yellow-950"
        )}
      >
        {showReviewNeeded ? "Show All" : `Review Goals (${goalsNeedingReview})`}
      </Button>
    );
  };

  const archiveGoal = async (goalId: number) => {
    try {
      await api.post(`/api/companies/${currentUser?.company}/goals/${goalId}/archive/`, {
        employee_id: user?.employeeId
      });
      // Refresh goals after archiving
      await fetchGoals();
    } catch (error) {
      console.error('Error archiving goal:', error);
      alert('Failed to archive goal. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="h-[calc(100vh-8rem)] flex items-center justify-center">
        <div className="space-y-4 text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black mx-auto"></div>
          <p className="text-muted-foreground">Loading goals...</p>
        </div>
      </div>
    );
  }

  const EmptyState = ({ 
    title, 
    description, 
    actionLabel, 
    onAction 
  }: { 
    title: string; 
    description: string; 
    actionLabel: string; 
    onAction: () => void; 
  }) => (
    <div className="h-full flex flex-col items-center justify-center p-8 text-center">
      <div className="bg-muted/50 rounded-lg p-8 max-w-md space-y-4">
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="text-muted-foreground">{description}</p>
        <Button onClick={onAction} className="mt-4">
          <Plus className="mr-2 h-4 w-4" />
          {actionLabel}
        </Button>
      </div>
    </div>
  );

  return (
    <div className="h-[calc(100vh-8rem)] flex flex-col">
      {isEmployeeView ? (
        <>
          <div className="p-4 border-b">
            <div className="flex items-center gap-4">
              <div className="relative flex-1">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder="Search by description, owner name, or timeline (e.g. FY2024, Q1)..."
                  className="pl-10 w-full"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div className="flex items-center gap-4">
                <ReviewButton />

                {canAddPersonalGoals && (
                  <Dialog open={isPersonalDialogOpen} onOpenChange={setIsPersonalDialogOpen}>
                    <DialogTrigger asChild>
                      <Button>
                        <Plus className="h-4 w-4 mr-2" />
                        Add Goal
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="max-h-[80vh] overflow-hidden flex flex-col">
                      <DialogHeader>
                        <DialogTitle>Create a new personal goal</DialogTitle>
                      </DialogHeader>
                      <div className="flex-1 overflow-y-auto pr-2">
                        <AddGoalForm 
                          addGoal={addGoal} 
                          currentUser={currentUser} 
                          goalType="personal"
                          isPromoter={user?.role === 'promoter'}
                          onClose={() => setIsPersonalDialogOpen(false)}
                        />
                      </div>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto p-4">
            {filteredPersonalGoals.length > 0 ? (
              <div className="space-y-6">
                {Object.keys(groupedGoals).map(employeeName => (
                  <div key={employeeName}>
                    <h2 className="text-lg font-semibold mb-4">{employeeName}</h2>
                    <div className="space-y-6">
                      {groupedGoals[employeeName].map(goal => (
                        <GoalCard
                          key={goal.id}
                          goal={goal}
                          onUpdate={() => setUpdatingGoalId(goal.id)}
                          onEdit={() => setEditingGoalId(goal.id)}
                          onDelete={() => deleteGoal(goal.id, goal.goal_type)}
                          onArchive={() => archiveGoal(goal.id)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <EmptyState
                title="No Personal Goals Yet"
                description="Start tracking your progress by adding your first personal goal."
                actionLabel="Add Your First Goal"
                onAction={() => setIsPersonalDialogOpen(true)}
              />
            )}
          </div>
        </>
      ) : (
        <Tabs defaultValue={defaultTab} className="flex flex-col h-full">
          <div className="border-b p-4">
            <TabsList className="grid w-full grid-cols-2 h-14 text-lg border rounded-lg overflow-hidden">
              <TabsTrigger 
                value="all-goals" 
                className="data-[state=active]:bg-black data-[state=active]:text-white border-r h-full"
              >
                Company Goals
              </TabsTrigger>
              <TabsTrigger 
                value="personal-goals" 
                className="data-[state=active]:bg-black data-[state=active]:text-white h-full relative"
              >
                Personal Goals
                {goalsNeedingReview > 0 && canApproveChanges && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                    {goalsNeedingReview}
                  </span>
                )}
              </TabsTrigger>
            </TabsList>
          </div>

          <div className="flex-1 min-h-0">
            <TabsContent value="all-goals" className="h-full m-0">
              <div className="p-4 border-b">
                <div className="flex items-center gap-4">
                  <div className="relative flex-1">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                    <Input
                      type="search"
                      placeholder="Search by description, owner name, or timeline (e.g. FY2024, Q1)..."
                      className="pl-10 w-full"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  {canAddCompanyGoals && (
                    <Dialog open={isCompanyDialogOpen} onOpenChange={setIsCompanyDialogOpen}>
                      <DialogTrigger asChild>
                        <Button>
                          <Plus className="h-4 w-4 mr-2" />
                          Add Goal
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="max-h-[80vh] overflow-hidden flex flex-col">
                        <DialogHeader>
                          <DialogTitle>Create a new company goal</DialogTitle>
                        </DialogHeader>
                        <div className="flex-1 overflow-y-auto pr-2">
                          <AddGoalForm 
                            addGoal={addGoal} 
                            currentUser={currentUser} 
                            goalType="company"
                            isPromoter={user?.role === 'promoter'}
                            onClose={() => setIsCompanyDialogOpen(false)}
                          />
                        </div>
                      </DialogContent>
                    </Dialog>
                  )}
                </div>
              </div>
              <div className="h-[calc(100%-73px)] overflow-y-auto p-4">
                {goals.length > 0 ? (
                  <div className="space-y-6">
                    {goals.filter(goal => goal.goal_type === "company").map(goal => (
                      <GoalCard
                        key={goal.id}
                        goal={goal}
                        onUpdate={() => setUpdatingGoalId(goal.id)}
                        onEdit={() => setEditingGoalId(goal.id)}
                        onDelete={() => deleteGoal(goal.id, goal.goal_type)}
                        onArchive={() => archiveGoal(goal.id)}
                      />
                    ))}
                  </div>
                ) : (
                  <EmptyState
                    title="No Company Goals Yet"
                    description="Start by adding your first company-wide goal."
                    actionLabel="Add Company Goal"
                    onAction={() => setIsCompanyDialogOpen(true)}
                  />
                )}
              </div>
            </TabsContent>

            <TabsContent value="personal-goals" className="h-full m-0">
              <div className="p-4 border-b">
                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-4">
                    <div className="relative flex-1">
                      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                      <Input
                        type="search"
                        placeholder="Search by description, owner name, or timeline (e.g. FY2024, Q1)..."
                        className="pl-10 w-full"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>

                    <div className="flex items-center gap-4">
                      <ReviewButton />

                      {canAddPersonalGoals && (
                        <Dialog open={isPersonalDialogOpen} onOpenChange={setIsPersonalDialogOpen}>
                          <DialogTrigger asChild>
                            <Button>
                              <Plus className="h-4 w-4 mr-2" />
                              Add Goal
                            </Button>
                          </DialogTrigger>
                          <DialogContent className="max-h-[80vh] overflow-hidden flex flex-col">
                            <DialogHeader>
                              <DialogTitle>Create a new personal goal</DialogTitle>
                            </DialogHeader>
                            <div className="flex-1 overflow-y-auto pr-2">
                              <AddGoalForm 
                                addGoal={addGoal} 
                                currentUser={currentUser} 
                                goalType="personal"
                                isPromoter={user?.role === 'promoter'}
                                onClose={() => setIsPersonalDialogOpen(false)}
                              />
                            </div>
                          </DialogContent>
                        </Dialog>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="h-[calc(100%-73px)] overflow-y-auto p-4">
                {Object.keys(groupedGoals).length > 0 ? (
                  <div className="space-y-6">
                    {Object.keys(groupedGoals).map(employeeName => (
                      <div key={employeeName}>
                        <h2 className="text-lg font-semibold mb-4">{employeeName}</h2>
                        <div className="space-y-6">
                          {groupedGoals[employeeName].map(goal => (
                            <GoalCard
                              key={goal.id}
                              goal={goal}
                              onUpdate={() => setUpdatingGoalId(goal.id)}
                              onEdit={() => setEditingGoalId(goal.id)}
                              onDelete={() => deleteGoal(goal.id, goal.goal_type)}
                              onArchive={() => archiveGoal(goal.id)}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <EmptyState
                    title="No Personal Goals Found"
                    description="Start by adding personal goals for team members."
                    actionLabel="Add Personal Goal"
                    onAction={() => setIsPersonalDialogOpen(true)}
                  />
                )}
              </div>
            </TabsContent>
          </div>
        </Tabs>
      )}

      {/* Edit Goal Dialog */}
      <Dialog 
        open={editingGoalId !== null} 
        onOpenChange={(open) => !open && setEditingGoalId(null)}
      >
        <DialogContent className="max-h-[80vh] overflow-hidden flex flex-col">
          <DialogHeader>
            <DialogTitle>Edit Goal</DialogTitle>
          </DialogHeader>
          <div className="flex-1 overflow-y-auto pr-2">
            {editingGoalId && (
              <AddGoalForm 
                addGoal={(updatedData) => {
                  updateGoal(editingGoalId, updatedData);
                  setEditingGoalId(null);
                }}
                currentUser={currentUser}
                goalType={goals.concat(personalGoals).find(g => g.id === editingGoalId)?.goal_type || 'personal'}
                isPromoter={user?.role === 'promoter'}
                onClose={() => setEditingGoalId(null)}
                initialData={goals.concat(personalGoals).find(g => g.id === editingGoalId)}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>

      {/* Modified Update Dialog */}
      <Dialog 
        open={updatingGoalId !== null} 
        onOpenChange={(open) => {
          if (!open) {
            setUpdatingGoalId(null);
            setUpdateType(null);
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {!updateType ? 'Update Goal' : 
               updateType === 'progress' ? 'Mark Progress' : 
               'Request Feedback'}
            </DialogTitle>
          </DialogHeader>
          
          {updatingGoalId && (
            <>
              {!updateType ? (
                <div className="flex flex-col space-y-4">
                  <Button 
                    onClick={() => setUpdateType('progress')}
                    className="w-full h-24 text-lg"
                  >
                    Mark Progress
                  </Button>
                  <Button 
                    onClick={() => setUpdateType('feedback')}
                    className="w-full h-24 text-lg"
                    variant="outline"
                  >
                    Request Feedback
                  </Button>
                </div>
              ) : updateType === 'progress' ? (
                <ProgressUpdateForm
                  goalId={updatingGoalId}
                  addUpdate={addUpdate}
                  onClose={() => {
                    setUpdatingGoalId(null);
                    setUpdateType(null);
                  }}
                />
              ) : (
                <FeedbackRequestForm
                  goalId={updatingGoalId}
                  addUpdate={addUpdate}
                  goal={goals.find(g => g.id === updatingGoalId) || personalGoals.find(g => g.id === updatingGoalId)!}
                  employees={employees}
                  onClose={() => {
                    setUpdatingGoalId(null);
                    setUpdateType(null);
                  }}
                />
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

function AddGoalForm({ 
  addGoal, 
  currentUser, 
  goalType,
  isPromoter = false,
  onClose,
  initialData
}: { 
  addGoal: (goal: any) => void, 
  currentUser: User,
  goalType: 'personal' | 'company',
  isPromoter?: boolean,
  onClose: () => void,
  initialData?: Goal
}) {
  const { user } = useAuth();
  const [description, setDescription] = useState(initialData?.description || "");
  const [selectedEmployee, setSelectedEmployee] = useState<string>(
    initialData?.owner || ""
  );
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [timelineDuration, setTimelineDuration] = useState<string>(
    initialData?.timeline ? initialData.timeline.replace(/\d+/g, '') : "FY"
  );
  const [timelineYear, setTimelineYear] = useState<number>(
    initialData?.timeline ? 
      parseInt(initialData.timeline.match(/\d+/)?.[0] || new Date().getFullYear().toString()) :
      new Date().getFullYear()
  );
  const [selectedParentGoal, setSelectedParentGoal] = useState<string>(
    initialData?.parent_goal?.toString() || 'none'
  );
  const [companyGoals, setCompanyGoals] = useState<Goal[]>([]);
  const [isEmployeeSelectorOpen, setIsEmployeeSelectorOpen] = useState(false);
  const employeeDropdownRef = useRef<HTMLDivElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchEmployees = async () => {
      if (!user?.company) return;
      
      try {
        const response = await api.get(`/api/employees/${user.company}`);
        setEmployees(response.data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, [user?.company]);

  useEffect(() => {
    const fetchCompanyGoals = async () => {
      if (!user?.company || goalType !== 'personal') return;
      
      try {
        const response = await api.get(`/api/companies/${user.company}/goals/`, {
          params: { 
            type: 'company',
            include_change_requests: true
          }
        });
        setCompanyGoals(response.data);
      } catch (error) {
        console.error('Error fetching company goals:', error);
      }
    };

    fetchCompanyGoals();
  }, [user?.company, goalType]);

  // Add click outside handler for employee selector
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (employeeDropdownRef.current && !employeeDropdownRef.current.contains(event.target as Node)) {
        setIsEmployeeSelectorOpen(false);
      }
    };

    if (isEmployeeSelectorOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEmployeeSelectorOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      
      // Debug logs
      console.log('Auth Context user:', user);
      console.log('Selected Employee:', selectedEmployee);
      
      // Determine owner - prioritize employeeId from auth context
      let owner;
      if (goalType === 'personal') {
        if (user?.employeeId) {
          owner = user.employeeId;
          console.log('Using employeeId from auth:', owner);
        } else if (user?.role === 'admin' || user?.role === 'promoter') {
          owner = selectedEmployee;
          console.log('Using selected employee:', owner);
        }
      }
      
      const goalData = {
        title: description,
        description: description,
        goal_type: goalType,
        timeline: `${timelineDuration}${timelineYear}`,
        progress: initialData?.progress || 0,
        company: user?.company || '',
        parent_goal: selectedParentGoal === 'none' ? null : parseInt(selectedParentGoal),
        owner: owner
      };

      console.log('Final goal data:', goalData);

      await addGoal(goalData);
      
      // Clear form and close dialog
      setDescription("");
      setSelectedEmployee("");
      setTimelineDuration("FY");
      setTimelineYear(new Date().getFullYear());
      setSelectedParentGoal('none');
      onClose();
    } catch (error) {
      console.error('Error submitting goal:', error);
      alert('Failed to save goal. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleYearChange = (direction: 'prev' | 'next') => {
    setTimelineYear(prev => direction === 'prev' ? prev - 1 : prev + 1);
  };

  // Add sorting for employees
  const sortedEmployees = useMemo(() => {
    return [...employees].sort((a, b) => a.name.localeCompare(b.name));
  }, [employees]);

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="goal-description">Goal description <span className="text-red-500">*</span></Label>
        <Textarea
          id="goal-description"
          placeholder="Enter goal description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </div>

      {(user?.role === 'admin' || user?.role === 'promoter') && goalType === 'personal' && (
        <div>
          <Label htmlFor="employee-select">Select Employee <span className="text-red-500">*</span></Label>
          <div className="relative" ref={employeeDropdownRef}>
            <button
              type="button"
              onClick={() => setIsEmployeeSelectorOpen(!isEmployeeSelectorOpen)}
              className="w-full p-2 text-sm text-left flex items-center justify-between bg-background border rounded-md"
            >
              <span className="truncate">
                {selectedEmployee ? 
                  employees.find(e => e.employee_id === selectedEmployee)?.name || 'Select employee' : 
                  'Select employee'}
              </span>
              <ChevronDown className="h-4 w-4 text-gray-400 flex-shrink-0" />
            </button>

            {isEmployeeSelectorOpen && (
              <div className="absolute z-50 w-full mt-1">
                <EmployeeSelector
                  selectedEmployees={employees.filter(emp => 
                    emp.employee_id === selectedEmployee
                  )}
                  onUpdate={(selected) => {
                    setSelectedEmployee(selected[0]?.employee_id || '');
                    setIsEmployeeSelectorOpen(false);
                  }}
                  onClose={() => setIsEmployeeSelectorOpen(false)}
                  allEmployees={sortedEmployees}
                  singleSelect={true}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="space-y-2">
        <Label>Timeline</Label>
        <div className="flex justify-between items-center">
          <Button 
            type="button" 
            variant="outline" 
            size="sm" 
            onClick={() => handleYearChange('prev')}
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <div className="font-semibold">
            {getFYDisplay(timelineYear)}
          </div>
          <Button 
            type="button" 
            variant="outline" 
            size="sm" 
            onClick={() => handleYearChange('next')}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
        
        <div className="grid grid-cols-1 gap-2 mb-2">
          <Button
            type="button"
            variant={timelineDuration === "FY" ? "default" : "outline"}
            onClick={() => setTimelineDuration("FY")}
          >
            FY
          </Button>
        </div>

        <div className="grid grid-cols-2 gap-2">
          <Button
            type="button"
            variant={timelineDuration === "H1" ? "default" : "outline"}
            onClick={() => setTimelineDuration("H1")}
          >
            H1
          </Button>
          <Button
            type="button"
            variant={timelineDuration === "H2" ? "default" : "outline"}
            onClick={() => setTimelineDuration("H2")}
          >
            H2
          </Button>
        </div>

        <div className="grid grid-cols-4 gap-2">
          {["Q1", "Q2", "Q3", "Q4"].map((quarter) => (
            <Button
              key={quarter}
              type="button"
              variant={timelineDuration === quarter ? "default" : "outline"}
              onClick={() => setTimelineDuration(quarter)}
            >
              {quarter}
            </Button>
          ))}
        </div>
      </div>
      {goalType === 'personal' && companyGoals.length > 0 && (
        <div>
          <Label htmlFor="parent-goal">Link to Company Goal (Optional)</Label>
          <Select 
            value={selectedParentGoal} 
            onValueChange={setSelectedParentGoal}
          >
            <SelectTrigger id="parent-goal" className="w-full bg-background">
              <SelectValue placeholder="Select a company goal" />
            </SelectTrigger>
            <SelectContent className="bg-background max-h-[200px] overflow-y-auto">
              <SelectItem value="none">No Parent Goal</SelectItem>
              {companyGoals.map((goal) => (
                <SelectItem key={goal.id} value={goal.id.toString()}>
                  {goal.title}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
      <div className="flex justify-end space-x-2 pt-4">
        <Button type="button" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
              Saving...
            </>
          ) : (
            'Save goal'
          )}
        </Button>
      </div>
    </form>
  );
}

interface GoalCardProps {
  goal: Goal;
  onUpdate: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onArchive: () => void;
}

function GoalCard({
  goal,
  onUpdate,
  onEdit,
  onDelete,
  onArchive
}: GoalCardProps): JSX.Element {
  const { user } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showChangeRequest, setShowChangeRequest] = useState(false);

  const canEditGoal = useMemo(() => {
    if (!user?.role) return false;
    if (user.role === 'admin' || user.role === 'promoter') return true;
    if (goal.goal_type === 'personal') {
      return goal.owner_details?.employee_id === user.employeeId;
    }
    return false;
  }, [user, goal]);

  const canApproveChanges = useMemo(() => {
    if (!user?.role) return false;
    return ['admin', 'promoter', 'manager'].includes(user.role);
  }, [user?.role]);

  const pendingChangeRequest = useMemo(() => {
    return goal.change_requests?.find(cr => cr.status === 'pending');
  }, [goal.change_requests]);

  const handleApproveChanges = async () => {
    if (!pendingChangeRequest || !goal.company || (!user?.employeeId && !user?.userId)) return;
    
    try {
      await api.post(
        `/api/companies/${goal.company}/goal-changes/${pendingChangeRequest.id}/approve/`,
        {
          reviewer_id: user.employeeId || user.userId
        }
      );
      window.location.reload();
    } catch (error) {
      console.error('Error approving changes:', error);
      alert('Failed to approve changes. Please try again.');
    }
  };

  const handleRejectChanges = async () => {
    if (!pendingChangeRequest || !goal.company || (!user?.employeeId && !user?.userId)) return;
    
    try {
      await api.post(
        `/api/companies/${goal.company}/goal-changes/${pendingChangeRequest.id}/reject/`,
        {
          reviewer_id: user.employeeId || user.userId
        }
      );
      window.location.reload();
    } catch (error) {
      console.error('Error rejecting changes:', error);
      alert('Failed to reject changes. Please try again.');
    }
  };

  return (
    <div className={cn(
      "bg-card rounded-lg shadow-sm border overflow-hidden",
      goal.has_pending_changes && "ring-2 ring-yellow-500/50"
    )}>
      <div className="flex flex-col md:flex-row w-full min-h-[200px]">
        {/* Left Column - Goal Details */}
        <div className="flex-1 p-6 border-r">
          {goal.has_pending_changes && (
            <div className="mb-4 p-2 bg-yellow-50 border border-yellow-200 rounded-md">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span className="w-2 h-2 bg-yellow-400 rounded-full" />
                  <span className="text-sm font-medium text-yellow-800">
                    Changes Pending Approval
                  </span>
                </div>
                {canApproveChanges && (
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setShowChangeRequest(true)}
                  >
                    Review Changes
                  </Button>
                )}
              </div>
            </div>
          )}

          <div className="flex justify-between items-start mb-4">
            <div className="flex-1 mr-4">
              <h3 className="text-xl font-semibold mb-2">
                <div 
                  className={cn(
                    "whitespace-pre-wrap",
                    !isExpanded && "line-clamp-2 cursor-pointer hover:text-muted-foreground"
                  )}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {goal.description}
                </div>
                {goal.description.split('\n').length > 2 && (
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="text-sm text-muted-foreground hover:text-foreground mt-1"
                  >
                    {isExpanded ? 'Show less' : 'Show more'}
                  </button>
                )}
              </h3>
              {goal.goal_type === 'personal' && <AverageRating goal={goal} />}
            </div>
            {canEditGoal && (
              <div className="flex gap-2">
                <Button variant="ghost" size="sm" onClick={onEdit}>
                  <Edit3 className="h-4 w-4" />
                </Button>
                <Button variant="ghost" size="sm" onClick={onDelete}>
                  <Trash2 className="h-4 w-4" />
                </Button>
                <Button variant="ghost" size="sm" onClick={onArchive}>
                  <Archive className="h-4 w-4" />
                </Button>
              </div>
            )}
          </div>

          <div className="space-y-6">
            <div className="bg-muted/30 rounded-lg p-4">
              <div className="flex justify-between items-center mb-3">
                <span className="text-sm font-semibold">Progress</span>
                <span className="text-sm font-medium">{goal.progress}%</span>
              </div>
              <div className="bg-muted rounded-full h-2.5">
                <Progress value={goal.progress} className="h-2.5" />
              </div>
            </div>

            <div className="grid gap-4">
              {goal.parent_goal_details && (
                <div className="flex items-start gap-2">
                  <div className="bg-muted/30 p-1.5 rounded">
                    <Star className="h-4 w-4 text-muted-foreground" />
                  </div>
                  <div>
                    <div className="text-sm font-medium mb-0.5">Parent Goal</div>
                    <div className="text-sm text-muted-foreground line-clamp-2">{goal.parent_goal_details.title}</div>
                  </div>
                </div>
              )}

              <div className="flex items-start gap-2">
                <div className="bg-muted/30 p-1.5 rounded">
                  <ChevronRight className="h-4 w-4 text-muted-foreground" />
                </div>
                <div>
                  <div className="text-sm font-medium mb-0.5">Timeline</div>
                  <div className="text-sm text-muted-foreground">{goal.timeline}</div>
                </div>
              </div>

              {(goal.internal_reviewer_details.length > 0 || goal.external_reviewers.length > 0) && (
                <div className="flex items-start gap-2">
                  <div className="bg-muted/30 p-1.5 rounded">
                    <Users className="h-4 w-4 text-muted-foreground" />
                  </div>
                  <div className="space-y-2">
                    {goal.internal_reviewer_details.length > 0 && (
                      <div>
                        <div className="text-sm font-medium mb-0.5">Internal Reviewers</div>
                        <div className="text-sm text-muted-foreground">
                          {goal.internal_reviewer_details.map(r => r.name).join(', ')}
                        </div>
                      </div>
                    )}
                    {goal.external_reviewers.length > 0 && (
                      <div>
                        <div className="text-sm font-medium mb-0.5">External Reviewers</div>
                        <div className="text-sm text-muted-foreground">
                          {goal.external_reviewers.join(', ')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Right Column - Updates */}
        <div className="flex-1 p-6 bg-muted/10">
          <div className="flex justify-between items-center mb-4">
            <h4 className="font-semibold">Updates</h4>
            <Button onClick={onUpdate} size="sm">
              <Plus className="h-4 w-4 mr-2" />
              Add Update
            </Button>
          </div>

          <div className="space-y-4 max-h-[400px] overflow-y-auto">
            {goal.updates.length > 0 ? (
              goal.updates.map((update, index) => (
                <div
                  key={update.id}
                  className="bg-card rounded-md p-4 shadow-sm border"
                >
                  <div className="flex justify-between items-start mb-2">
                    <div className="space-y-1 flex-1">
                      <p className="text-sm whitespace-pre-wrap">{update.content}</p>
                      {update.type === 'self' && (
                        <div className="flex items-center gap-2 mt-2">
                          <Progress value={update.progress} className="w-24 h-1.5" />
                          <span className="text-xs text-muted-foreground">{update.progress}%</span>
                        </div>
                      )}
                    </div>
                    <span className="text-xs text-muted-foreground">
                      {new Date(update.created_at).toLocaleDateString()}
                    </span>
                  </div>
                  {update.review_requests.length > 0 && (
                    <div className="mt-2 pt-2 border-t">
                      <div className="text-xs font-medium mb-1">Review Status:</div>
                      <div className="space-y-1">
                        {update.review_requests.map((request, idx) => (
                          <div key={idx} className="flex flex-col gap-1">
                            <div className="flex items-center gap-2 text-xs">
                              <span className={cn(
                                "w-2 h-2 rounded-full",
                                request.status === 'completed' ? "bg-green-500" : "bg-yellow-500"
                              )} />
                              <span>{request.reviewer_email}</span>
                              <span className="text-muted-foreground">
                                {request.status === 'completed' ? 
                                  `Rated ${request.rating}/5` : 
                                  'Pending'}
                              </span>
                            </div>
                            {request.status === 'completed' && request.comment && (
                              <div className="text-xs text-muted-foreground ml-4">
                                "{request.comment}"
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center text-muted-foreground text-sm py-8">
                No updates yet. Click "Add Update" to get started.
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Change Request Review Dialog */}
      {showChangeRequest && pendingChangeRequest && (
        <Dialog open={showChangeRequest} onOpenChange={setShowChangeRequest}>
          <DialogContent className="max-w-3xl">
            <DialogHeader>
              <DialogTitle>Review Changes</DialogTitle>
            </DialogHeader>
            
            <div className="space-y-4">
              <div className="text-sm text-muted-foreground">
                <span>Requested by {pendingChangeRequest.requested_by.name || 'Unknown'}</span>
              </div>

              {pendingChangeRequest.change_type === 'delete' ? (
                <div className="space-y-4">
                  <div className="bg-red-50 border border-red-200 rounded-md p-4">
                    <p className="text-red-800 font-medium">Goal Deletion Requested</p>
                    <p className="text-sm text-red-600 mt-2">This will permanently remove the goal and all its updates.</p>
                  </div>
                  
                  <div className="bg-muted/30 p-4 rounded-lg space-y-4">
                    <div>
                      <div className="text-sm font-medium text-muted-foreground">Description</div>
                      <div className="mt-1 whitespace-pre-wrap">{goal.description}</div>
                    </div>
                    
                    <div>
                      <div className="text-sm font-medium text-muted-foreground">Timeline</div>
                      <div className="mt-1">{goal.timeline}</div>
                    </div>
                    
                    {goal.internal_reviewer_details.length > 0 && (
                      <div>
                        <div className="text-sm font-medium text-muted-foreground">Internal Reviewers</div>
                        <div className="mt-1">
                          {goal.internal_reviewer_details.map(r => r.name).join(', ')}
                        </div>
                      </div>
                    )}
                    
                    {goal.external_reviewers.length > 0 && (
                      <div>
                        <div className="text-sm font-medium text-muted-foreground">External Reviewers</div>
                        <div className="mt-1">
                          {goal.external_reviewers.join(', ')}
                        </div>
                      </div>
                    )}

                    <div>
                      <div className="text-sm font-medium text-muted-foreground">Current Progress</div>
                      <div className="mt-2">
                        <div className="flex justify-between items-center mb-2">
                          <span className="text-sm">{goal.progress}%</span>
                        </div>
                        <Progress value={goal.progress} className="h-2" />
                      </div>
                    </div>

                    {goal.updates.length > 0 && (
                      <div>
                        <div className="text-sm font-medium text-muted-foreground">Number of Updates</div>
                        <div className="mt-1">{goal.updates.length} updates recorded</div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="space-y-6">
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <h4 className="font-medium mb-4 text-muted-foreground">Current Goal</h4>
                      <div className="space-y-4 bg-muted/30 p-4 rounded-lg">
                        {pendingChangeRequest.requested_state?.description !== goal.description && (
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">Description</div>
                            <div className="mt-1 whitespace-pre-wrap">{goal.description}</div>
                          </div>
                        )}
                        {pendingChangeRequest.requested_state?.timeline !== goal.timeline && (
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">Timeline</div>
                            <div className="mt-1">{goal.timeline}</div>
                          </div>
                        )}
                        {pendingChangeRequest.requested_state?.internal_reviewer_details && 
                         JSON.stringify(pendingChangeRequest.requested_state.internal_reviewer_details) !== 
                         JSON.stringify(goal.internal_reviewer_details) && (
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">Internal Reviewers</div>
                            <div className="mt-1">
                              {goal.internal_reviewer_details.map(r => r.name).join(', ')}
                            </div>
                          </div>
                        )}
                        {pendingChangeRequest.requested_state?.external_reviewers && 
                         JSON.stringify(pendingChangeRequest.requested_state.external_reviewers) !== 
                         JSON.stringify(goal.external_reviewers) && (
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">External Reviewers</div>
                            <div className="mt-1">
                              {pendingChangeRequest.requested_state.external_reviewers.join(', ')}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <h4 className="font-medium mb-4 text-muted-foreground">Requested Changes</h4>
                      <div className="space-y-4 bg-yellow-50 border-yellow-200 border p-4 rounded-lg">
                        {pendingChangeRequest.requested_state?.description !== goal.description && (
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">Description</div>
                            <div className="mt-1 whitespace-pre-wrap">{pendingChangeRequest.requested_state?.description}</div>
                          </div>
                        )}
                        {pendingChangeRequest.requested_state?.timeline !== goal.timeline && (
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">Timeline</div>
                            <div className="mt-1">{pendingChangeRequest.requested_state?.timeline}</div>
                          </div>
                        )}
                        {pendingChangeRequest.requested_state?.internal_reviewer_details && 
                         JSON.stringify(pendingChangeRequest.requested_state.internal_reviewer_details) !== 
                         JSON.stringify(goal.internal_reviewer_details) && (
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">Internal Reviewers</div>
                            <div className="mt-1">
                              {pendingChangeRequest.requested_state.internal_reviewer_details.map(r => r.name).join(', ')}
                            </div>
                          </div>
                        )}
                        {pendingChangeRequest.requested_state?.external_reviewers && 
                         JSON.stringify(pendingChangeRequest.requested_state.external_reviewers) !== 
                         JSON.stringify(goal.external_reviewers) && (
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">External Reviewers</div>
                            <div className="mt-1">
                              {pendingChangeRequest.requested_state.external_reviewers.join(', ')}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-end gap-2 mt-4">
                <Button variant="outline" onClick={() => setShowChangeRequest(false)}>
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  onClick={handleRejectChanges}
                >
                  Reject Changes
                </Button>
                <Button
                  onClick={handleApproveChanges}
                >
                  Approve Changes
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

function getFYDisplay(year: number | string, duration: string = "FY") {
  const numYear = typeof year === 'string' ? parseInt(year) : year;
  
  switch (duration) {
    case "FY":
      return `${numYear-1}-${numYear}`;
    case "H1":
    case "Q1":
    case "Q2":
      return `${numYear-1}-${numYear} ${duration}`;
    case "H2":
    case "Q3":
    case "Q4":
      return `${numYear-1}-${numYear} ${duration}`;
    default:
      return `${numYear}`;
  }
}