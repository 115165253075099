import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';

interface Employee {
  id: string;
  name: string;
  email: string;
  employee_code: string;
}

interface SecondaryManager {
  id: string;
  name: string;
  title: string;
  department: string;
  employee_code: string;
}

interface CustomNodeData {
  title: string;
  department: string;
  sub_department_level_1?: string;
  sub_department_level_2?: string;
  cost_center?: string;
  color: string;
  isIc: boolean;
  grade: string;
  location: string;
  currentEmployee: Employee | null;
  secondaryManager: SecondaryManager | null;
  width: number;
  isClickable?: boolean;
}

const CustomNode: React.FC<NodeProps<CustomNodeData>> = ({ data, id }) => {
  // Check if this is the virtual root node
  const isVirtualRoot = id === 'virtual_root';

  if (isVirtualRoot) {
    return (
      <div 
        style={{ 
          background: '#f8f9fa',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderLeft: '3px solid #0f172a',
          borderRadius: '8px',
          padding: '16px 20px',
          width: '300px',
          minHeight: '70px',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1)',
          fontFamily: 'Inter, system-ui, sans-serif',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        }}
        className="hover:shadow-md"
      >
        <div style={{
          fontSize: '22px',
          fontWeight: '800',
          color: '#0f172a',
          textAlign: 'center',
          letterSpacing: '-0.02em',
          lineHeight: '1.2',
        }}>
          {data.title}
        </div>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: '#94a3b8',
            width: '6px',
            height: '6px',
            border: 'none',
            bottom: '-3px',
          }}
        />
      </div>
    );
  }

  // Regular node rendering
  return (
    <div 
      style={{ 
        background: `${data.color}15`,
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderLeft: `3px solid ${data.color}`,
        borderRadius: '8px',
        padding: '14px 16px',
        width: '260px',
        height: '130px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Inter, system-ui, sans-serif',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        cursor: 'pointer',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      }}
      className="node hover:translate-y-[-1px] hover:shadow-md group"
    >
      {/* Main content */}
      <div className="space-y-2.5">
        {/* Employee Name / Vacant */}
        {data.currentEmployee ? (
          <div style={{ 
            fontSize: '22px',
            lineHeight: '1.2',
            fontWeight: '800',
            color: '#000000',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
            marginBottom: '1px',
            letterSpacing: '-0.01em',
          }}
          title={data.currentEmployee.name}
          >
            {data.currentEmployee.name}
          </div>
        ) : (
          <div style={{ 
            fontSize: '22px',
            lineHeight: '1.2',
            color: '#dc2626',
            fontWeight: '800',
            fontStyle: 'italic',
            marginBottom: '1px',
            letterSpacing: '-0.01em',
          }}>
            Vacant Position
          </div>
        )}

        {/* Role Title */}
        <div style={{ 
          fontSize: '19px',
          lineHeight: '1.3',
          fontWeight: '750',
          color: '#374151',
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          wordBreak: 'break-word',
          letterSpacing: '-0.01em',
        }}
        title={data.title}
        >
          {data.title}
        </div>

        {/* Department with icon */}
        <div style={{ 
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
          fontSize: '18px',
          fontWeight: '600',
          color: '#4b5563',
          marginTop: '2px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        title={data.department}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ flexShrink: 0 }}
          >
            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
          </svg>
          <span style={{ 
            fontWeight: '600',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
            {data.department}
          </span>
        </div>
      </div>

      {/* Handles */}
      <Handle
        type="target"
        position={Position.Top}
        style={{
          background: '#94a3b8',
          width: '6px',
          height: '6px',
          border: 'none',
          top: '-3px',
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          background: '#94a3b8',
          width: '6px',
          height: '6px',
          border: 'none',
          bottom: '-3px',
        }}
      />
    </div>
  );
};

export default memo(CustomNode);
