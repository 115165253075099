import { useEffect, useState } from "react"
import { useAuth } from "../../contexts/AuthContext"
import api from "../../api"
import { Button } from "../ui/button"
import { useToast } from "../ui/use-toast"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog"
import { Label } from "../ui/label"
import { Input } from "../ui/input"
import { Badge } from "../ui/badge"
import { ReviewCycleAnalytics } from "./ReviewCycleAnalytics"
import { Pencil, Trash2, Calendar, Users, Search } from "lucide-react"
import { cn } from "../../lib/utils"

interface ReviewCycle {
  id: number
  name: string
  start_date: string
  end_date: string
  status: 'draft' | 'in_progress' | 'completed'
  employee_reviews_count: number
  completed_reviews_count: number
  employee_reviews?: Array<{
    employee_name: string
    employee_title: string
    stage: string
    self_rating?: number
    manager_rating?: number
  }>
}

interface CreateCycleForm {
  name: string
  start_date: string
  end_date: string
}

const PromoterDashboard = () => {
  const { user } = useAuth()
  const { toast } = useToast()
  const [reviewCycles, setReviewCycles] = useState<ReviewCycle[]>([])
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [selectedCycle, setSelectedCycle] = useState<ReviewCycle | null>(null)
  const [formData, setFormData] = useState<CreateCycleForm>({
    name: "",
    start_date: "",
    end_date: ""
  })
  const [editingCycle, setEditingCycle] = useState<ReviewCycle | null>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [cycleToDelete, setCycleToDelete] = useState<ReviewCycle | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>("")

  useEffect(() => {
    fetchReviewCycles()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.company])

  const fetchReviewCycles = async () => {
    try {
      const response = await api.get(`/api/reviews/cycles/`, {
        params: {
          company: user?.company,
          include_employee_reviews: true
        }
      })
      if (response.status === 200) {
        setReviewCycles(response.data)
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch review cycles",
      })
    }
  }

  const handleCreateCycle = async () => {
    try {
      // Validate form
      if (!formData.name.trim()) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please enter a cycle name",
        })
        return
      }

      if (!formData.start_date || !formData.end_date) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please select both start and end dates",
        })
        return
      }

      const startDate = new Date(formData.start_date)
      const endDate = new Date(formData.end_date)
      if (endDate < startDate) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "End date must be after start date",
        })
        return
      }

      // Format dates to YYYY-MM-DD
      const formattedData = {
        ...formData,
        start_date: formData.start_date.split('T')[0],
        end_date: formData.end_date.split('T')[0],
        company: user?.company
      }

      const response = await api.post('/api/reviews/cycles/', formattedData)
      
      if (response.status === 201) {
        setShowCreateModal(false)
        fetchReviewCycles()
        toast({
          title: "Success",
          description: "Review cycle created successfully",
        })
        // Reset form
        setFormData({
          name: "",
          start_date: "",
          end_date: ""
        })
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.detail || 
                          error.response?.data?.error ||
                          "Failed to create review cycle"
      toast({
        variant: "destructive",
        title: "Error",
        description: errorMessage,
      })
    }
  }

  const handleEditCycle = async () => {
    if (!editingCycle) return;

    try {
      // Validate form
      if (!formData.name.trim()) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please enter a cycle name",
        })
        return
      }

      if (!formData.start_date || !formData.end_date) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please select both start and end dates",
        })
        return
      }

      const startDate = new Date(formData.start_date)
      const endDate = new Date(formData.end_date)
      if (endDate < startDate) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "End date must be after start date",
        })
        return
      }

      // Format dates to YYYY-MM-DD
      const formattedData = {
        ...formData,
        start_date: formData.start_date.split('T')[0],
        end_date: formData.end_date.split('T')[0],
      }

      const response = await api.patch(`/api/reviews/cycles/${editingCycle.id}/`, formattedData, {
        params: {
          company: user?.company
        }
      })
      
      if (response.status === 200) {
        setShowCreateModal(false)
        setEditingCycle(null)
        fetchReviewCycles()
        toast({
          title: "Success",
          description: "Review cycle updated successfully",
        })
        // Reset form
        setFormData({
          name: "",
          start_date: "",
          end_date: ""
        })
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.detail || 
                          error.response?.data?.error ||
                          "Failed to update review cycle"
      toast({
        variant: "destructive",
        title: "Error",
        description: errorMessage,
      })
    }
  }

  const handleDeleteCycle = async () => {
    if (!cycleToDelete) return;

    try {
      await api.delete(`/api/reviews/cycles/${cycleToDelete.id}/`, {
        params: {
          company: user?.company
        }
      })
      setShowDeleteDialog(false)
      setCycleToDelete(null)
      fetchReviewCycles()
      toast({
        title: "Success",
        description: "Review cycle deleted successfully",
      })
    } catch (error: any) {
      const errorMessage = error.response?.data?.detail || 
                          error.response?.data?.error ||
                          "Failed to delete review cycle"
      toast({
        variant: "destructive",
        title: "Error",
        description: errorMessage,
      })
    }
  }

  const getStatusBadgeVariant = (status: ReviewCycle['status']) => {
    switch (status) {
      case 'draft':
        return 'secondary'
      case 'in_progress':
        return 'default'
      case 'completed':
        return 'outline'
      default:
        return 'secondary'
    }
  }

  return (
    <div className="h-full flex">
      {/* Left sidebar */}
      <div className="w-80 border-r bg-muted/10">
        <div className="p-4 space-y-4">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">Review Cycles</h2>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => {
                setFormData({
                  name: "",
                  start_date: "",
                  end_date: ""
                })
                setEditingCycle(null)
                setShowCreateModal(true)
              }}
            >
              Create New
            </Button>
          </div>

          <div className="space-y-2">
            {reviewCycles.map(cycle => (
              <div
                key={cycle.id}
                onClick={() => setSelectedCycle(selectedCycle?.id === cycle.id ? null : cycle)}
                className={cn(
                  "p-3 rounded-lg cursor-pointer",
                  "hover:bg-accent/50 transition-colors",
                  selectedCycle?.id === cycle.id ? "bg-accent" : "bg-card",
                  "border"
                )}
              >
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium truncate">{cycle.name}</h3>
                  <Badge variant={getStatusBadgeVariant(cycle.status)}>
                    {cycle.status.replace('_', ' ')}
                  </Badge>
                </div>
                <div className="flex flex-col gap-1 text-sm text-muted-foreground">
                  <div className="flex items-center gap-1.5">
                    <Calendar className="h-4 w-4" />
                    <span className="truncate">{new Date(cycle.start_date).toLocaleDateString()}</span>
                  </div>
                  <div className="flex items-center gap-1.5">
                    <Users className="h-4 w-4" />
                    <span>{cycle.completed_reviews_count} / {cycle.employee_reviews_count} completed</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Main content area */}
      <div className="flex-1 flex flex-col min-h-0">
        {selectedCycle ? (
          <>
            {/* Header */}
            <div className="flex-none p-6 border-b">
              <div className="flex justify-between items-start mb-8">
                <div>
                  <h1 className="text-2xl font-bold mb-2">{selectedCycle.name}</h1>
                  <div className="flex items-center gap-4 text-sm text-muted-foreground">
                    <div className="flex items-center gap-1.5">
                      <Calendar className="h-4 w-4" />
                      <span>{new Date(selectedCycle.start_date).toLocaleDateString()} - {new Date(selectedCycle.end_date).toLocaleDateString()}</span>
                    </div>
                    <Badge variant={getStatusBadgeVariant(selectedCycle.status)}>
                      {selectedCycle.status.replace('_', ' ')}
                    </Badge>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={() => {
                      setFormData({
                        name: selectedCycle.name,
                        start_date: selectedCycle.start_date.split('T')[0],
                        end_date: selectedCycle.end_date.split('T')[0]
                      });
                      setEditingCycle(selectedCycle);
                      setShowCreateModal(true);
                    }}
                  >
                    <Pencil className="h-4 w-4 mr-2" />
                    Edit
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={() => {
                      setCycleToDelete(selectedCycle);
                      setShowDeleteDialog(true);
                    }}
                  >
                    <Trash2 className="h-4 w-4 mr-2" />
                    Delete
                  </Button>
                </div>
              </div>

              {/* Progress Overview */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="flex flex-col p-4 bg-muted/50 rounded-lg">
                  <span className="text-sm font-medium text-muted-foreground">Total Reviews</span>
                  <span className="text-2xl font-bold mt-1">{selectedCycle.employee_reviews_count}</span>
                  <span className="text-xs text-muted-foreground mt-1">Employees in review cycle</span>
                </div>
                <div className="flex flex-col p-4 bg-muted/50 rounded-lg">
                  <span className="text-sm font-medium text-muted-foreground">Completed</span>
                  <span className="text-2xl font-bold text-green-600 mt-1">{selectedCycle.completed_reviews_count}</span>
                  <span className="text-xs text-muted-foreground mt-1">Reviews finalized</span>
                </div>
                <div className="flex flex-col p-4 bg-muted/50 rounded-lg">
                  <span className="text-sm font-medium text-muted-foreground">Completion Rate</span>
                  <span className="text-2xl font-bold text-blue-600 mt-1">
                    {selectedCycle.employee_reviews_count ? 
                      ((selectedCycle.completed_reviews_count / selectedCycle.employee_reviews_count) * 100).toFixed(1) : 0}%
                  </span>
                  <div className="mt-2 space-y-1">
                    <div className="flex justify-between text-xs text-muted-foreground">
                      <span>Progress</span>
                      <span>{selectedCycle.completed_reviews_count} of {selectedCycle.employee_reviews_count}</span>
                    </div>
                    <div className="h-2 bg-background border rounded-full">
                      <div 
                        className="h-full bg-blue-600 rounded-full transition-all"
                        style={{ 
                          width: `${selectedCycle.employee_reviews_count ? 
                            (selectedCycle.completed_reviews_count / selectedCycle.employee_reviews_count * 100) : 0}%` 
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Employee Reviews Section */}
            <div className="flex-1 overflow-auto">
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h4 className="text-lg font-semibold">Employee Reviews</h4>
                  <div className="relative w-[300px]">
                    <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                      placeholder="Search by employee or manager name..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="pl-8"
                    />
                  </div>
                </div>
                <ReviewCycleAnalytics 
                  cycle={selectedCycle} 
                  searchQuery={searchQuery}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="h-full flex items-center justify-center">
            <div className="text-center">
              <h3 className="text-lg font-medium">Select a Review Cycle</h3>
              <p className="text-sm text-muted-foreground mt-1">
                Choose a review cycle from the list to view details
              </p>
            </div>
          </div>
        )}
      </div>

      {/* Create/Edit Modal */}
      <Dialog open={showCreateModal} onOpenChange={setShowCreateModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{editingCycle ? 'Edit Review Cycle' : 'Create New Review Cycle'}</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Label htmlFor="name">Cycle Name</Label>
              <Input
                id="name"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                placeholder="e.g., Q4 2023 Performance Review"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="start_date">Start Date</Label>
                <Input
                  id="start_date"
                  type="date"
                  value={formData.start_date}
                  onChange={(e) => setFormData(prev => ({ ...prev, start_date: e.target.value }))}
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="end_date">End Date</Label>
                <Input
                  id="end_date"
                  type="date"
                  value={formData.end_date}
                  onChange={(e) => setFormData(prev => ({ ...prev, end_date: e.target.value }))}
                  min={formData.start_date || new Date().toISOString().split('T')[0]}
                />
              </div>
            </div>

            <Button onClick={editingCycle ? handleEditCycle : handleCreateCycle} className="w-full">
              {editingCycle ? 'Update Review Cycle' : 'Create Review Cycle'}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Review Cycle</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-sm text-muted-foreground">
              Are you sure you want to delete this review cycle? This action cannot be undone.
            </p>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowDeleteDialog(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDeleteCycle}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PromoterDashboard 