/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, useCallback, useEffect } from 'react';
import ReactFlow, {
  Node,
  Edge,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  XYPosition,
  ReactFlowProvider,
  ReactFlowInstance,
  useReactFlow,
  NodeMouseHandler,
} from 'reactflow';
import 'reactflow/dist/style.css';
import api from '../api';
import { useAuth } from '../contexts/AuthContext'; // Add this import
import EditHistory from './EditHistory';

import CustomNode from './CustomNode';
import CustomEdge from './CustomEdge';
import NodeSidebar from './NodeSidebar';
import KPIs from './KPIs';
import ViewNavigation from './ViewNavigation';
import { Button } from "./ui/button";
import { ArrowUpCircle, Filter, Search, ChevronDown, ChevronUp, ClipboardList, Users, MapPin, CreditCard } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select"
import { Label } from "./ui/label"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./ui/popover"
import { Input } from "./ui/input"

// Define nodeTypes outside of the component
const nodeTypes = {
  custom: CustomNode,
};

// Define edgeTypes outside of the component
const edgeTypes = {
  custom: CustomEdge,
};

// Add this near the top of the file
interface OrgChartProps {
  isEmployeeView?: boolean;
}

// Modify the component definition
const AltOrgChart: React.FC<OrgChartProps> = ({ isEmployeeView = false }) => {
  // 1. First, declare all state hooks
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [employeeDetails, setEmployeeDetails] = useState<{
    employee: {
      employee_name: string;
      title: string;
      employee_id?: string;
    };
    role?: {
      id: string;
      title: string;
      department: string;
      location?: string;
      grade?: string;
      reporting_to?: string;
      kras?: any[];
      kpis?: any[];
      current_employee?: any;
    };
  } | null>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentView, setCurrentView] = useState<'chart' | 'history'>('chart');
  const [showHistory, setShowHistory] = useState(false);
  const [showCenterButton, setShowCenterButton] = useState(false);
  const [initialFitViewDone, setInitialFitViewDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  const { user } = useAuth();
  const [initialViewportState, setInitialViewportState] = useState<{ x: number, y: number, zoom: number } | null>(null);
  const [departments, setDepartments] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [costCenters, setCostCenters] = useState<string[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [selectedCostCenter, setSelectedCostCenter] = useState<string>('');
  const [showFilters, setShowFilters] = useState(false);
  const [employees, setEmployees] = useState<Array<{id: string, name: string}>>([]);
  const [isFilterBarCollapsed, setIsFilterBarCollapsed] = useState(false);

  // 2. Define all callback hooks
  const findRootNode = useCallback((nodes: Node[], edges: Edge[]) => {
    const nodeWithIncomingEdges = new Set(edges.map(e => e.target));
    return nodes.find(node => !nodeWithIncomingEdges.has(node.id));
  }, []);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
    
    // If there are no nodes, don't try to center
    if (nodes.length === 0) {
      return;
    }

    // Find the root node (node with no incoming edges)
    const rootNode = findRootNode(nodes, edges);
    
    // If no root node is found, center on the first node
    if (!rootNode && nodes.length > 0) {
      const firstNode = nodes[0];
      const zoom = 1.5;
      
      setInitialViewportState({ 
        x: firstNode.position.x, 
        y: firstNode.position.y, 
        zoom 
      });
      
      instance.setCenter(
        firstNode.position.x, 
        firstNode.position.y,
        { zoom, duration: 800 }
      );
      return;
    }

    // If user is an employee, find their node and center on it
    if (user?.role === 'employee' && user?.employeeId) {
      const employeeNode = nodes.find(node => 
        node.data.currentEmployee?.employee_code === user.employeeId
      );

      if (employeeNode) {
        const centerX = employeeNode.position.x;
        const centerY = employeeNode.position.y;
        const zoom = 1.5;
        
        setInitialViewportState({ x: centerX, y: centerY, zoom });
        instance.setCenter(
          centerX, 
          centerY,
          { zoom, duration: 800 }
        );
        return;
      }
    }
    
    // Default behavior for non-employees or if employee node not found
    if (rootNode) {
      const centerX = rootNode.position.x;
      const centerY = rootNode.position.y + 400;
      const zoom = 1.5;
      
      setInitialViewportState({ x: centerX, y: centerY, zoom });
      instance.setCenter(
        centerX, 
        centerY,
        { zoom, duration: 800 }
      );
    } else {
      // If no specific node to center on, fit view to show all nodes
      instance.fitView({ 
        padding: 0.5,
        duration: 800,
        maxZoom: 1.5
      });
    }
    
    setInitialFitViewDone(true);
  }, [nodes, edges, findRootNode, user?.role, user?.employeeId]);

  // Add this effect to handle viewport updates when filters change
  useEffect(() => {
    if (reactFlowInstance && nodes.length > 0) {
      // Short delay to ensure nodes are rendered
      setTimeout(() => {
        reactFlowInstance.fitView({ 
          padding: 0.5,
          duration: 800,
          maxZoom: 1.5
        });
      }, 100);
    }
  }, [nodes, reactFlowInstance]);

  const centerOnRoot = useCallback(() => {
    if (!reactFlowInstance || !initialViewportState) return;
    
    // Use the stored initial viewport state
    reactFlowInstance.setCenter(
      initialViewportState.x, 
      initialViewportState.y,
      { zoom: initialViewportState.zoom, duration: 800 }
    );
    setShowCenterButton(false);
  }, [reactFlowInstance, initialViewportState]);

  const handleNodeClick = useCallback(async (_: React.MouseEvent | React.TouchEvent, node: Node) => {
    // If employee view, check if node is clickable based on backend flag
    if (isEmployeeView && !node.data.isClickable) {
      return;
    }

    try {
      if (!node.data.currentEmployee) {
        setEmployeeDetails({
          employee: {
            employee_name: '',
            title: '',
          },
          role: {
            id: node.id,
            title: node.data.title,
            department: node.data.department,
            location: node.data.location,
            grade: node.data.grade,
            reporting_to: node.data.reporting_to,
            kras: [],
            kpis: [],
            current_employee: null
          }
        });
        setSelectedNode(node);
        setSidebarOpen(true);
        return;
      }

      const response = await api.get(`/api/employee-dashboard/${node.data.currentEmployee.employee_code}/`);
      setEmployeeDetails(response.data);
      setSelectedNode(node);
      setSidebarOpen(true);
    } catch (error) {
      console.error('Error in handleNodeClick:', error);
    }
  }, [isEmployeeView, setEmployeeDetails, setSelectedNode, setSidebarOpen]);

  const onNodeTouchEnd = useCallback((event: React.TouchEvent, node: Node) => {
    event.preventDefault();
    handleNodeClick(event, node);
  }, [handleNodeClick]);

  const onRemoveNode = useCallback(
    (id: string) => {
      setNodes((nds) => nds.filter((n) => n.id !== id));
      setEdges((eds) => eds.filter((e) => e.source !== id && e.target !== id));
    },
    [setNodes, setEdges]
  );

  const onDeleteNode = useCallback(
    async (id: string) => {
      try {
        const nodeToDelete = nodes.find(node => node.id === id);
        if (!nodeToDelete) return;

        const parentEdge = edges.find(edge => edge.target === id);
        const childEdges = edges.filter(edge => edge.source === id);
        const updatedEdges = edges.filter(edge => edge.source !== id && edge.target !== id);
        
        if (parentEdge) {
          childEdges.forEach(childEdge => {
            updatedEdges.push({
              ...childEdge,
              source: parentEdge.source
            });
          });
        }

        setNodes(nodes => nodes.filter(node => node.id !== id));
        setEdges(updatedEdges);

        await api.post('/api/delete-node/', { nodeId: id });
      } catch (error) {
        console.error('Error deleting node:', error);
      }
    },
    [nodes, edges, setNodes, setEdges]
  );

  // 2. Create a stable reference for node data creation
  const createNodeData = useCallback((
    name: string,
    title: string,
    department: string,
    color: string,
    isIc: boolean,
    width: number,
    handlers: {
      onAddChild: (parentId: string) => Promise<void>;
      onRemoveNode: (id: string) => void;
      onDeleteNode: (id: string) => Promise<void>;
    }
  ) => ({
    name,
    title,
    department,
    color,
    isIc,
    width,
    ...handlers
  }), []);

  // 3. Define onAddChild
  const onAddChild = useCallback(
    async (parentId: string) => {
      try {
        const response = await api.post('/api/create-node/', {
          node: {
            parentId: parentId,
            employee_name: 'New Employee',
            title: 'New Title',
            department: 'Department',
            userId: user?.userId,
          }
        });

        const createdNode = response.data.node;
        const parentNode = nodes.find(node => node.id === parentId);

        if (!parentNode) {
          console.error('Parent node not found');
          return;
        }

        const newNode: Node = {
          id: createdNode.id.toString(),
          type: 'custom',
          position: { x: parentNode.position.x, y: parentNode.position.y + 100 },
          data: {
            name: createdNode.employee_name || 'New Employee',
            role: createdNode.title || 'New Title',
            department: createdNode.department || 'Department',
            color: createdNode.color || '#ffffff',
            isIc: createdNode.is_ic || false,
            width: createdNode.width,
            onAddChild,
            onRemoveNode,
            onDeleteNode,
            userId: user?.userId,
          },
        };

        setNodes(currentNodes => [...currentNodes, newNode]);
        setEdges(currentEdges => [
          ...currentEdges,
          {
            id: `e${parentId}-${createdNode.id}`,
            source: parentId,
            target: createdNode.id.toString(),
            type: 'custom'
          }
        ]);

      } catch (error) {
        console.error('Error creating node:', error);
      }
    },
    [nodes, setNodes, setEdges, user?.userId, onRemoveNode, onDeleteNode]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const updateChartSize = () => {
      const header = document.querySelector('.app-header') as HTMLElement;
      const headerHeight = header?.offsetHeight || 0;
      const windowHeight = window.innerHeight;
      const availableHeight = windowHeight - headerHeight;
      
      const chartContainer = document.getElementById('org-chart-container');
      if (chartContainer) {
        chartContainer.style.height = `${availableHeight}px`;
      }
    };

    // Call immediately and set up listener
    updateChartSize();
    window.addEventListener('resize', updateChartSize);

    return () => {
      window.removeEventListener('resize', updateChartSize);
    };
  }, []);

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();

      const reactFlowBounds = document.querySelector('.react-flow')?.getBoundingClientRect();
      const draggedNodeId = event.dataTransfer.getData('application/reactflow');
      const targetNodeId = (event.target as HTMLElement).closest('.react-flow__node')?.getAttribute('data-id');

      if (draggedNodeId && targetNodeId && draggedNodeId !== targetNodeId && reactFlowBounds) {
        const draggedNode = nodes.find((node) => node.id === draggedNodeId);
        const targetNode = nodes.find((node) => node.id === targetNodeId);

        if (draggedNode && targetNode) {
          // Remove existing edge from dragged node's parent
          const updatedEdges = edges.filter(
            (edge) => edge.target !== draggedNodeId
          );

          // Add new edge connecting target node to dragged node
          const newEdge: Edge = {
            id: `e${targetNodeId}-${draggedNodeId}`,
            source: targetNodeId,
            target: draggedNodeId,
          };

          setEdges([...updatedEdges, newEdge]);

          // Update node positions
          const updatedNodes = nodes.map((node) => {
            if (node.id === draggedNodeId) {
              const newPosition: XYPosition = {
                x: targetNode.position.x,
                y: targetNode.position.y + 100,
              };
              return {
                ...node,
                position: newPosition,
              };
            }
            return node;
          });

          setNodes(updatedNodes);

          // Update backend
          updateHierarchyOnBackend(draggedNodeId, targetNodeId);
        }
      }
    },
    [nodes, edges, setNodes, setEdges]
  );

  const updateHierarchyOnBackend = async (draggedNodeId: string, newParentId: string) => {
    try {
      await api.post('/api/update-hierarchy/', {
        draggedNodeId,
        newParentId,
      });
    } catch (error) {
      console.error('Error updating hierarchy:', error);
    }
  };

  const closeNodeSidebar = () => {
    setSelectedNode(null);
    setEmployeeDetails(null);
    setSidebarOpen(false);
  };

  const onMoveEnd = useCallback((_event: any, viewport: any) => {
    if (!initialViewportState) return;
    
    const { x, y, zoom } = viewport;
    const { x: initialX, y: initialY, zoom: initialZoom } = initialViewportState;
    
    const hasMovedSignificantly = 
      Math.abs(x - initialX) > 100 || 
      Math.abs(y - initialY) > 100 || 
      Math.abs(zoom - initialZoom) > 0.1;

    setShowCenterButton(hasMovedSignificantly);
  }, [initialViewportState]);

  const handleBackToCenter = centerOnRoot;

  const updateNodeData = async (updatedData: { name: string; role: string }) => {
    if (!selectedNode || !employeeDetails) return;

    // Update nodes with new employee data
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === selectedNode.id) {
          return {
            ...n,
            data: { 
              ...n.data,
              currentEmployee: {
                ...n.data.currentEmployee,
                employee_name: updatedData.name,
                employee_code: employeeDetails.employee.employee_id
              }
            }
          };
        }
        return n;
      })
    );
    
    // Update selected node
    setSelectedNode({
      ...selectedNode,
      data: {
        ...selectedNode.data,
        currentEmployee: {
          ...selectedNode.data.currentEmployee,
          employee_name: updatedData.name,
          employee_code: employeeDetails.employee.employee_id
        }
      }
    });
    
    // Update employee details
    setEmployeeDetails({
      ...employeeDetails,
      employee: {
        ...employeeDetails.employee,
        employee_name: updatedData.name
      }
    });
  };

  const handleNavigate = (view: 'chart' | 'history') => {
    setCurrentView(view);
    setShowHistory(view === 'history');
    if (sidebarOpen) {
      setSidebarOpen(false);
      setSelectedNode(null);
      setEmployeeDetails(null);
    }
  };

  // 3. Define effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchOrgChartData();
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching org chart data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user?.company]);

  // Add this after other useEffects
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await api.get(`/api/companies/${user?.company}/roles/filters/`);
        if (response.status === 200) {
          setDepartments(response.data.departments || []);
          setLocations(response.data.locations || []);
          setCostCenters(response.data.cost_centers || []);
        }
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    if (user?.company) {
      fetchFilters();
    }
  }, [user?.company]);

  // Modify fetchOrgChartData to include filters
  const fetchOrgChartData = async () => {
    try {
      const companyName = user?.company || 'Kaynes';
      const params = new URLSearchParams({
        userRole: user?.role || 'employee',
        employeeId: user?.employeeId || ''
      });

      // Add filters if selected
      if (selectedDepartment) params.append('department', selectedDepartment);
      if (selectedLocation) params.append('location', selectedLocation);
      if (selectedCostCenter) params.append('cost_center', selectedCostCenter);

      const response = await api.get(`/api/org-chart/${companyName}?${params.toString()}`);
      const { nodes: apiNodes, edges: apiEdges } = response.data;

      const transformedNodes = apiNodes.map((node: any) => ({
        id: node.id.toString(),
        type: 'custom',
        position: { x: node.position.x, y: node.position.y },
        data: {
          title: node.data.title,
          department: node.data.department,
          color: node.data.color,
          isIc: node.data.isIc,
          width: node.data.width,
          currentEmployee: node.data.currentEmployee,
          secondaryManager: node.data.secondaryManager,
          isClickable: node.data.isClickable,
        },
      }));

      const transformedEdges = apiEdges.map((edge: any) => ({
        id: edge.id,
        source: edge.source.toString(),
        target: edge.target.toString(),
        type: 'custom',
        data: { 
          isVerticalIC: edge.data?.isVerticalIC,
          isFirstIC: edge.data?.isFirstIC
        },
      }));

      setNodes(transformedNodes);
      setEdges(transformedEdges);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching org chart data:', error);
      setIsLoading(false);
    }
  };

  // Add handler for filter changes
  const handleFilterChange = (type: 'department' | 'location' | 'cost_center', value: string) => {
    switch (type) {
      case 'department':
        setSelectedDepartment(value === 'all' ? '' : value);
        break;
      case 'location':
        setSelectedLocation(value === 'all' ? '' : value);
        break;
      case 'cost_center':
        setSelectedCostCenter(value === 'all' ? '' : value);
        break;
    }
  };

  // Add useEffect to refetch data when filters change
  useEffect(() => {
    if (user?.company) {
      fetchOrgChartData();
    }
  }, [selectedDepartment, selectedLocation, selectedCostCenter]);

  // Add this before the return statement
  const clearFilters = () => {
    setSelectedDepartment('');
    setSelectedLocation('');
    setSelectedCostCenter('');
  };

  // Modify the useEffect for employees list
  useEffect(() => {
    // Extract unique employees from nodes
    const uniqueEmployees = nodes
      .filter(node => node.data.currentEmployee) // Only nodes with employees
      .map(node => ({
        id: node.id,
        name: node.data.currentEmployee.employee_name || node.data.currentEmployee.name || 'Unknown'
      }))
      .filter(employee => employee.name !== 'Unknown') // Remove any without names
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically
    setEmployees(uniqueEmployees);
  }, [nodes]);

  // Add function to handle employee selection
  const handleEmployeeSelect = (employeeId: string) => {
    const selectedNode = nodes.find(node => node.id === employeeId);
    if (selectedNode && reactFlowInstance) {
      reactFlowInstance.setCenter(
        selectedNode.position.x,
        selectedNode.position.y,
        { zoom: 1.5, duration: 800 }
      );
    }
  };

  // 5. Render logic
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-[calc(100vh-8rem)]">
      <div className="w-full px-4 py-3 border-b">
        <div className="flex items-center justify-between gap-4">
          {(!isEmployeeView && currentView === 'history') ? (
            <div className="flex-1" />
          ) : (
            <div className="flex-1 grid grid-cols-4 gap-2">
              <Popover>
                <PopoverTrigger asChild>
                  <Button 
                    variant="outline" 
                    size="sm" 
                    className="w-full justify-start"
                  >
                    <span className="sm:hidden">
                      <Search className="h-4 w-4" />
                    </span>
                    <span className="hidden sm:inline">
                      Search employees...
                    </span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="p-0 w-[200px] bg-background shadow-md" align="start">
                  <Command>
                    <CommandInput 
                      placeholder="Search employees..." 
                      className="h-9"
                    />
                    <CommandList className="max-h-[300px] overflow-auto">
                      <CommandEmpty>No employees found.</CommandEmpty>
                      <CommandGroup>
                        {employees.map((employee) => (
                          <CommandItem
                            key={employee.id}
                            value={employee.name}
                            onSelect={() => {
                              handleEmployeeSelect(employee.id);
                              const trigger = document.querySelector('[data-state="open"]');
                              if (trigger) {
                                (trigger as HTMLElement).click();
                              }
                            }}
                            className="cursor-pointer"
                          >
                            <Search className="mr-2 h-4 w-4" />
                            {employee.name}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>

              <Popover>
                <PopoverTrigger asChild>
                  <Button 
                    variant="outline" 
                    size="sm" 
                    className="w-full justify-start"
                  >
                    <span className="sm:hidden"><Users className="h-4 w-4" /></span>
                    <span className="hidden sm:inline">{selectedDepartment || "Department"}</span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="p-0 w-[200px] bg-background shadow-md" align="start">
                  <Command>
                    <CommandInput placeholder="Search departments..." className="h-9" />
                    <CommandList className="max-h-[300px] overflow-auto">
                      <CommandEmpty>No departments found.</CommandEmpty>
                      <CommandGroup>
                        <CommandItem 
                          value="all"
                          onSelect={() => handleFilterChange('department', 'all')}
                          className="cursor-pointer"
                        >
                          All Departments
                        </CommandItem>
                        {departments.map((dept) => (
                          <CommandItem
                            key={dept}
                            value={dept}
                            onSelect={() => handleFilterChange('department', dept)}
                            className="cursor-pointer"
                          >
                            {dept}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>

              <Popover>
                <PopoverTrigger asChild>
                  <Button 
                    variant="outline" 
                    size="sm"
                    className="w-full justify-start"
                  >
                    <span className="sm:hidden"><MapPin className="h-4 w-4" /></span>
                    <span className="hidden sm:inline">{selectedLocation || "Location"}</span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="p-0 w-[200px] bg-background shadow-md" align="start">
                  <Command>
                    <CommandInput placeholder="Search locations..." className="h-9" />
                    <CommandList className="max-h-[300px] overflow-auto">
                      <CommandEmpty>No locations found.</CommandEmpty>
                      <CommandGroup>
                        <CommandItem 
                          value="all"
                          onSelect={() => handleFilterChange('location', 'all')}
                          className="cursor-pointer"
                        >
                          All Locations
                        </CommandItem>
                        {locations.map((loc) => (
                          <CommandItem
                            key={loc}
                            value={loc}
                            onSelect={() => handleFilterChange('location', loc)}
                            className="cursor-pointer"
                          >
                            {loc}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>

              <Popover>
                <PopoverTrigger asChild>
                  <Button 
                    variant="outline" 
                    size="sm"
                    className="w-full justify-start"
                  >
                    <span className="sm:hidden"><CreditCard className="h-4 w-4" /></span>
                    <span className="hidden sm:inline">{selectedCostCenter || "Cost Center"}</span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="p-0 w-[200px] bg-background shadow-md" align="start">
                  <Command>
                    <CommandInput placeholder="Search cost centers..." className="h-9" />
                    <CommandList className="max-h-[300px] overflow-auto">
                      <CommandEmpty>No cost centers found.</CommandEmpty>
                      <CommandGroup>
                        <CommandItem 
                          value="all"
                          onSelect={() => handleFilterChange('cost_center', 'all')}
                          className="cursor-pointer"
                        >
                          All Cost Centers
                        </CommandItem>
                        {costCenters.map((cc) => (
                          <CommandItem
                            key={cc}
                            value={cc}
                            onSelect={() => handleFilterChange('cost_center', cc)}
                            className="cursor-pointer"
                          >
                            {cc}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
          )}

          <div className="flex items-center gap-2 pl-4 border-l">
            {currentView !== 'history' && (selectedDepartment || selectedLocation || selectedCostCenter) && (
              <Button
                variant="outline"
                size="sm"
                onClick={clearFilters}
              >
                Clear Filters
              </Button>
            )}
            {!isEmployeeView && (
              <Button
                variant="secondary"
                size="sm"
                onClick={() => handleNavigate(currentView === 'chart' ? 'history' : 'chart')}
                className="font-medium"
              >
                {currentView === 'chart' ? (
                  <>
                    <ClipboardList className="h-4 w-4 mr-2" />
                    View History
                  </>
                ) : (
                  <>
                    <Users className="h-4 w-4 mr-2" />
                    View Org Chart
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="flex-1 relative overflow-hidden">
        {!isEmployeeView && currentView === 'history' ? (
          <div className="absolute inset-0 overflow-auto">
            <EditHistory />
          </div>
        ) : (
          <div 
            style={{ 
              width: !isEmployeeView && sidebarOpen ? 'calc(100% - 16px)' : '100%',
              height: '100%',
              transition: 'width 0.3s ease-in-out'
            }}
            className="relative"
          >
            <ReactFlowProvider>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeClick={handleNodeClick}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onInit={onInit}
                onMoveEnd={onMoveEnd}
                onDragOver={onDragOver}
                onDrop={onDrop}
                defaultEdgeOptions={{ type: 'custom' }}
                fitView={false}
                minZoom={0.4}
                maxZoom={2}
                zoomOnScroll={false}
                panOnScroll={true}
                panOnScrollMode={"free" as any}
                preventScrolling={true}
                nodesDraggable={false}
                nodesConnectable={false}
                className="h-full touch-none"
                style={{ 
                  background: 'var(--background)',
                  height: '100%'
                }}
              >
                <Controls 
                  showZoom={true} 
                  showFitView={false} 
                  showInteractive={false}
                  className="bg-background/80 border shadow-sm backdrop-blur-sm"
                />
                <Background 
                  gap={24}
                  size={1}
                  color="rgba(0, 0, 0, 0.05)"
                  style={{
                    background: 'radial-gradient(circle at center, rgba(255,255,255,1) 0%, rgba(248,250,252,1) 100%)',
                  }}
                />
                <svg style={{ position: 'absolute', width: 0, height: 0 }}>
                  <defs>
                    <pattern
                      id="smallGrid"
                      width="24"
                      height="24"
                      patternUnits="userSpaceOnUse"
                    >
                      <path
                        d="M 24 0 L 0 0 0 24"
                        fill="none"
                        stroke="rgba(0,0,0,0.03)"
                        strokeWidth="1"
                      />
                    </pattern>
                  </defs>
                </svg>
              </ReactFlow>
              {showCenterButton && (
                <Button
                  onClick={handleBackToCenter}
                  className="absolute bottom-4 right-4 sm:bottom-8 sm:right-8 bg-background border border-black text-black hover:bg-black hover:text-white p-2 rounded-full"
                  size="sm"
                >
                  <ArrowUpCircle className="h-4 w-4" />
                </Button>
              )}
            </ReactFlowProvider>
          </div>
        )}
        {/* Show sidebar when node is selected, regardless of view type */}
        {sidebarOpen && selectedNode && employeeDetails && (
          <div className="absolute inset-0 sm:inset-auto sm:right-0 sm:w-1/2 bg-background">
            <NodeSidebar
              node={selectedNode}
              employeeDetails={employeeDetails}
              onClose={closeNodeSidebar}
              onUpdate={updateNodeData}
              userId={user?.userId || ''}
              onDataChange={fetchOrgChartData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AltOrgChart;
