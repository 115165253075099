import { useEffect, useState } from "react"
import { useAuth } from "../../contexts/AuthContext"
import api from "../../api"
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/card"
import { Button } from "../ui/button"
import { useToast } from "../ui/use-toast"
import { Textarea } from "../ui/textarea"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../ui/alert-dialog"
import { Star, Calendar, Target, ChevronRight, ClipboardList } from "lucide-react"
import { cn } from "../../lib/utils"
import { Label } from "../ui/label"
import { Slider } from "../ui/slider"
import { Check } from "lucide-react"
import { Progress } from "../ui/progress"

interface Goal {
  id: number
  title: string
  description: string
  progress: number
  timeline: string
  timeline_duration: string
  timeline_year: number
  average_rating: number | null
  total_reviews: number
  owner_details: {
    id: number
    employee_id: string
    name: string
    email: string
    date_of_joining: string
    is_active: boolean
    competencies: any[]
  }
  internal_reviewer_details: Array<any>
  external_reviewers: Array<any>
  parent_goal_details?: {
    id: number
    title: string
    progress: number
  } | null
  updates: Array<{
    id: number
    type: string
    content?: string
    created_at: string
    review_requests: Array<{
      rating: number | null
      status: string
      comment?: string
    }>
  }>
}

interface GoalReview {
  id: number
  goal_details: Goal
  self_rating?: number
  manager_rating?: number
  locked_progress: number
  self_comments?: string
  manager_comments?: string
  weightage: number
}

interface Review {
  id: number
  review_cycle_name: string
  review_cycle_status: string
  stage: 'goal_summary' | 'self_review' | 'manager_review' | 'completed'
  is_locked: boolean
  goal_reviews: GoalReview[]
}

// Update StageProgress component
const StageProgress = ({ currentStage }: { currentStage: string }) => {
  const stages = [
    { key: 'goal_summary', label: 'Goals Review', icon: ClipboardList, description: 'Review and summarize your goals' },
    { key: 'self_review', label: 'Self Rating', icon: ClipboardList, description: 'Rate your performance' },
    { key: 'manager_review', label: 'Manager Rating', icon: ClipboardList, description: 'Manager evaluates your performance' },
    { key: 'completed', label: 'Completed', icon: Check, description: 'Review cycle completed' }
  ]

  const currentIndex = stages.findIndex(stage => stage.key === currentStage)

  return (
    <div className="w-full mb-12 mt-4">
      <div className="relative flex justify-between items-center max-w-4xl mx-auto">
        {/* Progress Line */}
        <div className="absolute top-1/2 left-0 w-full h-[2px] bg-blue-100">
          <div 
            className="h-full bg-blue-500 transition-all duration-500 ease-in-out"
            style={{ 
              width: `${((currentIndex) / (stages.length - 1)) * 100}%`,
            }}
          />
        </div>

        {/* Stages */}
        {stages.map((stage, index) => {
          const isCompleted = index < currentIndex
          const isCurrent = index === currentIndex
          const isUpcoming = index > currentIndex
          
          return (
            <div key={stage.key} className={cn(
              "relative flex flex-col items-center min-w-[120px] transition-all duration-300",
              isCurrent && "scale-110"
            )}>
              {/* Circle with number/icon */}
              <div 
                className={cn(
                  "relative z-10 flex items-center justify-center rounded-full border-2 transition-all duration-200",
                  isCompleted && "border-blue-500 bg-blue-500 text-white w-12 h-12",
                  isCurrent && "border-blue-500 bg-white text-blue-500 ring-4 ring-blue-100 shadow-lg w-14 h-14",
                  isUpcoming && "border-gray-200 bg-white text-gray-400 w-12 h-12"
                )}
              >
                {isCompleted ? (
                  <Check className={cn("w-5 h-5", isCurrent && "w-6 h-6")} />
                ) : (
                  <span className={cn(
                    "text-lg font-semibold",
                    isCurrent && "text-xl"
                  )}>{index + 1}</span>
                )}
              </div>
              
              {/* Label */}
              <div className={cn(
                "absolute -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap transition-all duration-200",
                isCurrent && "transform scale-105"
              )}>
                <p className={cn(
                  "text-sm font-medium transition-all duration-200",
                  isCompleted && "text-blue-500",
                  isCurrent && "text-blue-500 font-bold text-base",
                  isUpcoming && "text-gray-400"
                )}>
                  {stage.label}
                </p>
              </div>
              
              {/* Description */}
              <div className={cn(
                "absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap transition-all duration-200",
                isCurrent && "transform scale-105"
              )}>
                <p className={cn(
                  "text-xs transition-all duration-200",
                  isCompleted && "text-gray-600",
                  isCurrent && "text-blue-500 font-medium text-sm",
                  isUpcoming && "text-gray-400"
                )}>
                  {stage.description}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

// Add a simplified GoalSummaryCard component for the goal_summary stage
const GoalSummaryCard = ({ goal }: { goal: Goal }) => {
  // Get the latest update
  const latestUpdate = goal.updates.sort((a, b) => 
    new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0];

  // Get the latest external review if it exists
  const latestExternalReview = goal.updates
    .filter(update => update.type === 'external' && update.review_requests.length > 0)
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0];

  return (
    <Card className="group hover:shadow-lg transition-all duration-200">
      <CardHeader className="space-y-2">
        <div className="flex items-start justify-between">
          <div className="space-y-1 flex-1">
            <div className="flex items-center gap-2">
              <CardTitle className="text-lg line-clamp-2 whitespace-pre-wrap">{goal.title}</CardTitle>
              {goal.average_rating && (
                <div className="flex items-center gap-1 px-2 py-1 bg-yellow-50 rounded-full">
                  <Star className="w-4 h-4 fill-yellow-400 text-yellow-400" />
                  <span className="text-sm font-medium">{goal.average_rating.toFixed(1)}</span>
                </div>
              )}
            </div>
            <div className="flex items-center gap-3 text-sm text-muted-foreground">
              <div className="flex items-center gap-1">
                <Calendar className="w-4 h-4" />
                <span>{goal.timeline_duration} {goal.timeline_year}</span>
              </div>
              <div className="flex items-center gap-1">
                <Target className="w-4 h-4" />
                <span className={cn(
                  goal.progress < 30 ? "text-red-600" :
                  goal.progress < 70 ? "text-yellow-500" :
                  "text-green-600"
                )}>{goal.progress}% complete</span>
              </div>
            </div>
          </div>
        </div>
      </CardHeader>

      <CardContent className="space-y-4">
        {/* Progress bar with gradient */}
        <div className="space-y-2">
          <Progress 
            value={goal.progress} 
            className={cn(
              "h-2",
              goal.progress < 30 
                ? "bg-red-100 [&>div]:bg-red-600" 
                : goal.progress < 70 
                ? "bg-yellow-100 [&>div]:bg-yellow-500"
                : "bg-green-100 [&>div]:bg-green-600"
            )}
          />
        </div>

        {/* Latest update or review */}
        <div className="space-y-3 pt-2">
          {latestExternalReview && (
            <div className="rounded-lg bg-primary/5 p-3 space-y-1">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">Latest Review</span>
                <span className="text-xs text-muted-foreground">
                  {new Date(latestExternalReview.created_at).toLocaleDateString()}
                </span>
              </div>
              {latestExternalReview.review_requests[0]?.comment && (
                <p className="text-sm text-muted-foreground line-clamp-2">
                  "{latestExternalReview.review_requests[0].comment}"
                </p>
              )}
            </div>
          )}
          
          {latestUpdate && latestUpdate.type === 'self' && (
            <div className="rounded-lg bg-secondary/50 p-3 space-y-1">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">Latest Update</span>
                <span className="text-xs text-muted-foreground">
                  {new Date(latestUpdate.created_at).toLocaleDateString()}
                </span>
              </div>
              <p className="text-sm text-muted-foreground line-clamp-2">
                {latestUpdate.content}
              </p>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

// Add GoalCard component for better goal rendering
//eslint-disable-next-line
const GoalCard = ({ 
  goal, 
  isEditing = false,
  selfRating,
  managerRating,
  weightage,
  onRatingChange,
  onWeightageChange,
  onCommentChange,
  comments,
  showManagerRating = false
}: { 
  goal: Goal,
  isEditing?: boolean,
  selfRating?: number,
  managerRating?: number,
  weightage?: number,
  onRatingChange?: (rating: number) => void,
  onWeightageChange?: (weightage: number) => void,
  onCommentChange?: (comment: string) => void,
  comments?: string,
  showManagerRating?: boolean
}) => {
  return (
    <Card className="mb-6 hover:shadow-md transition-shadow duration-200">
      <CardHeader>
        <div className="flex items-start justify-between">
          <div className="space-y-1">
            <CardTitle className="text-xl">{goal.title}</CardTitle>
            <CardDescription className="text-base">{goal.description}</CardDescription>
          </div>
          <div className="flex items-center space-x-2">
            <Calendar className="w-4 h-4 text-muted-foreground" />
            <span className="text-sm text-muted-foreground">
              {goal.timeline_duration} {goal.timeline_year}
            </span>
          </div>
        </div>
      </CardHeader>
      
      <CardContent className="space-y-6">
        <div className="space-y-2">
          <div className="flex items-center justify-between text-sm">
            <span className="text-muted-foreground">Progress</span>
            <span className={cn(
              "font-medium",
              goal.progress < 30 ? "text-red-600" :
              goal.progress < 70 ? "text-yellow-500" :
              "text-green-600"
            )}>{goal.progress}%</span>
          </div>
          <Progress 
            value={goal.progress} 
            className={cn(
              "h-2",
              goal.progress < 30 
                ? "bg-red-100 [&>div]:bg-red-600" 
                : goal.progress < 70 
                ? "bg-yellow-100 [&>div]:bg-yellow-500"
                : "bg-green-100 [&>div]:bg-green-600"
            )}
          />
        </div>

        {(isEditing || selfRating !== undefined) && (
          <div className="grid gap-6 pt-4">
            <div className="space-y-4">
              <Label className="text-base">Self Rating</Label>
              <div className="flex items-center space-x-2">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    onClick={() => onRatingChange?.(star)}
                    disabled={!isEditing}
                    className={cn(
                      "text-yellow-400 transition-all",
                      isEditing && "hover:scale-110 cursor-pointer"
                    )}
                  >
                    <Star 
                      className={cn(
                        "w-6 h-6",
                        (selfRating || 0) >= star ? "fill-current" : "fill-none"
                      )} 
                    />
                  </button>
                ))}
                <span className="ml-2 text-sm text-muted-foreground">
                  {selfRating ? `${selfRating}/5` : "Not rated"}
                </span>
              </div>
            </div>

            {showManagerRating && (
              <div className="space-y-4">
                <Label className="text-base">Manager Rating</Label>
                <div className="flex items-center space-x-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star 
                      key={star}
                      className={cn(
                        "w-6 h-6",
                        (managerRating || 0) >= star 
                          ? "fill-yellow-400 text-yellow-400" 
                          : "text-muted stroke-current"
                      )} 
                    />
                  ))}
                  <span className="ml-2 text-sm text-muted-foreground">
                    {managerRating ? `${managerRating}/5` : "Pending"}
                  </span>
                </div>
              </div>
            )}

            {weightage !== undefined && (
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <Label className="text-base">Goal Weightage</Label>
                  <span className="text-sm font-medium">{weightage}%</span>
                </div>
                <Slider
                  value={[weightage]}
                  onValueChange={([value]) => onWeightageChange?.(value)}
                  min={0}
                  max={100}
                  step={5}
                  disabled={!isEditing}
                  className="py-2"
                />
              </div>
            )}
          </div>
        )}

        {(isEditing || comments !== undefined) && (
          <div className="space-y-4 pt-4">
            <Label className="text-base">Comments</Label>
            <Textarea
              value={comments}
              onChange={(e) => onCommentChange?.(e.target.value)}
              placeholder="Add your comments about this goal's progress..."
              disabled={!isEditing}
              className="min-h-[100px]"
            />
          </div>
        )}
      </CardContent>
    </Card>
  )
}

// Add WeightPoints component
const WeightPoints = ({ 
  value, 
  onChange 
}: { 
  value: number, 
  onChange: (value: number) => void 
}) => {
  return (
    <div className="flex items-center gap-1">
      {[0, 1, 2, 3, 4, 5].map((point) => (
        <button
          key={point}
          onClick={() => onChange(point)}
          className={cn(
            "w-8 h-8 rounded-full flex items-center justify-center transition-all",
            value >= point 
              ? "bg-blue-500 text-white" 
              : "bg-blue-50 text-blue-500 hover:bg-blue-100"
          )}
        >
          {point}
        </button>
      ))}
    </div>
  );
};

// Update SelfReviewCard component
const SelfReviewCard = ({ 
  goal,
  rating,
  weightPoints,
  totalWeightPoints,
  onRatingChange,
  onWeightChange,
}: { 
  goal: Goal,
  rating?: number,
  weightPoints: number,
  totalWeightPoints: number,
  onRatingChange: (rating: number) => void,
  onWeightChange: (points: number) => void,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card className="group hover:shadow-sm transition-all duration-200">
      <CardContent className="pt-6">
        <div className="space-y-6">
          {/* Expandable Title */}
          <div 
            className="cursor-pointer group/title" 
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <h3 className={cn(
              "text-lg font-medium pr-8 relative whitespace-pre-wrap",
              !isExpanded && "line-clamp-2"
            )}>
              {goal.title}
              <ChevronRight className={cn(
                "absolute right-0 top-1 h-5 w-5 text-gray-400 transition-transform",
                isExpanded && "rotate-90",
                "group-hover/title:text-gray-600"
              )} />
            </h3>
          </div>

          {/* Rating Stars */}
          <div className="space-y-2">
            <Label className="text-sm text-muted-foreground">Performance Rating</Label>
            <div className="flex items-center space-x-2">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  onClick={() => onRatingChange(star)}
                  className="text-yellow-400 transition-all hover:scale-110"
                >
                  <Star 
                    className={cn(
                      "w-6 h-6",
                      (rating || 0) >= star ? "fill-current" : "fill-none"
                    )} 
                  />
                </button>
              ))}
              <span className="ml-2 text-sm text-muted-foreground">
                {rating ? `${rating}/5` : "Not rated"}
              </span>
            </div>
          </div>

          {/* Weight Points */}
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <Label className="text-sm text-muted-foreground">Goal Weight</Label>
              <span className="text-sm font-medium text-blue-500">
                {weightPoints} points ({((weightPoints / Math.max(totalWeightPoints, 1)) * 100).toFixed(1)}%)
              </span>
            </div>
            <WeightPoints 
              value={weightPoints} 
              onChange={onWeightChange}
            />
            <p className="text-xs text-muted-foreground mt-1">
              Assign importance points (0-5) relative to other goals
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

// Add WeightedRatingDisplay component
const WeightedRatingDisplay = ({
  ratings,
  weights,
  totalPoints
}: {
  ratings: Record<number, number>,
  weights: Record<number, number>,
  totalPoints: number
}) => {
  // Calculate weighted average
  const weightedAverage = Object.entries(ratings).reduce((acc, [goalId, rating]) => {
    const weight = weights[parseInt(goalId)] || 0
    return acc + (rating * (weight / totalPoints))
  }, 0);

  // Count how many goals are rated
  const ratedGoalsCount = Object.values(ratings).filter(r => r > 0).length;
  const totalGoals = Object.keys(weights).length;

  return (
    <div className="sticky top-0 z-10 bg-background border-b shadow-sm">
      <div className="container mx-auto px-4 py-4 max-w-7xl">
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <h3 className="text-lg font-medium">Calculated Rating</h3>
            <p className="text-sm text-muted-foreground">
              Based on your ratings and goal weights
            </p>
          </div>
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2">
              <div className="text-sm text-muted-foreground">
                Goals Rated: <span className="font-medium text-foreground">{ratedGoalsCount}/{totalGoals}</span>
              </div>
            </div>
            <div className="flex items-center gap-3 px-4 py-2 bg-blue-50 rounded-lg">
              <div className="flex -space-x-1">
                {[1, 2, 3, 4, 5].map((star) => (
                  <Star 
                    key={star}
                    className={cn(
                      "w-5 h-5",
                      weightedAverage >= star 
                        ? "fill-yellow-400 text-yellow-400" 
                        : weightedAverage >= star - 0.5
                        ? "fill-yellow-400/50 text-yellow-400"
                        : "text-yellow-200"
                    )}
                  />
                ))}
              </div>
              <span className="text-lg font-semibold">
                {weightedAverage ? weightedAverage.toFixed(2) : '-'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add ComparisonRatingDisplay component
const ComparisonRatingDisplay = ({
  goalReviews,
  weightPoints,
  totalPoints
}: {
  goalReviews: GoalReview[],
  weightPoints: Record<number, number>,
  totalPoints: number
}) => {
  // Calculate weighted averages
  const selfWeightedAverage = goalReviews.reduce((acc, review) => {
    if (!review.self_rating) return acc;
    const weight = weightPoints[review.id] || 0;
    return acc + (review.self_rating * (weight / Math.max(totalPoints, 1)));
  }, 0);

  const managerWeightedAverage = goalReviews.reduce((acc, review) => {
    if (!review.manager_rating) return acc;
    const weight = weightPoints[review.id] || 0;
    return acc + (review.manager_rating * (weight / Math.max(totalPoints, 1)));
  }, 0);

  const ratedGoalsCount = goalReviews.filter(g => g.manager_rating).length;
  const totalGoals = goalReviews.length;

  return (
    <div className="sticky top-0 z-10 bg-background border-b shadow-sm">
      <div className="container mx-auto px-4 py-4 max-w-7xl">
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <h3 className="text-lg font-medium">Review Ratings</h3>
            <p className="text-sm text-muted-foreground">
              {ratedGoalsCount === totalGoals 
                ? "All goals have been rated by your manager" 
                : `${ratedGoalsCount} of ${totalGoals} goals rated by manager`}
            </p>
          </div>
          <div className="flex items-center gap-6">
            {/* Self Rating */}
            <div className="flex items-center gap-3 px-4 py-2 bg-yellow-50 rounded-lg">
              <div className="flex -space-x-1">
                {[1, 2, 3, 4, 5].map((star) => (
                  <Star 
                    key={star}
                    className={cn(
                      "w-5 h-5",
                      selfWeightedAverage >= star 
                        ? "fill-yellow-400 text-yellow-400" 
                        : selfWeightedAverage >= star - 0.5
                        ? "fill-yellow-400/50 text-yellow-400"
                        : "text-yellow-200"
                    )}
                  />
                ))}
              </div>
              <div className="flex flex-col">
                <span className="text-xs text-yellow-600 font-medium">Your Rating</span>
                <span className="text-lg font-semibold">
                  {selfWeightedAverage ? selfWeightedAverage.toFixed(2) : '-'}
                </span>
              </div>
            </div>

            {/* Manager Rating */}
            <div className="flex items-center gap-3 px-4 py-2 bg-blue-50 rounded-lg">
              <div className="flex -space-x-1">
                {[1, 2, 3, 4, 5].map((star) => (
                  <Star 
                    key={star}
                    className={cn(
                      "w-5 h-5",
                      managerWeightedAverage >= star 
                        ? "fill-blue-400 text-blue-400" 
                        : managerWeightedAverage >= star - 0.5
                        ? "fill-blue-400/50 text-blue-400"
                        : "text-blue-200"
                    )}
                  />
                ))}
              </div>
              <div className="flex flex-col">
                <span className="text-xs text-blue-600 font-medium">Manager's Rating</span>
                <span className="text-lg font-semibold">
                  {managerWeightedAverage ? managerWeightedAverage.toFixed(2) : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add NoGoalsState component
const NoGoalsState = () => {
  return (
    <div className="text-center max-w-lg mx-auto py-12">
      <ClipboardList className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
      <h3 className="text-lg font-medium mb-2">No Goals Found</h3>
      <p className="text-sm text-muted-foreground mb-6">
        You need to add at least one goal before you can proceed with your performance review.
        Goals help track your progress and achievements during the review period.
      </p>
      <Button asChild>
        <a href="/goals">Add Goals</a>
      </Button>
    </div>
  );
};

// Add Manager

const EmployeeReviewPortal = () => {
  const { user } = useAuth()
  const { toast } = useToast()
  const [activeReview, setActiveReview] = useState<Review | null>(null)
  const [goals, setGoals] = useState<Goal[]>([])
  const [goalComments, setGoalComments] = useState<Record<number, string>>({})
  const [goalRatings, setGoalRatings] = useState<Record<number, number>>({})
  //eslint-disable-next-line
  const [goalWeightages, setGoalWeightages] = useState<Record<number, number>>({})
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [overallComments, setOverallComments] = useState("")
  const [weightPoints, setWeightPoints] = useState<Record<number, number>>({})

  useEffect(() => {
    if (user?.employeeId && user?.company) {
      fetchActiveReview()
      fetchGoals()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const fetchGoals = async () => {
    try {
      const response = await api.get(`/api/companies/${encodeURIComponent(user?.company || '')}/goals/`, {
        params: {
          type: 'personal',
          employee_id: user?.employeeId,
          include_updates: true,
          include_change_requests: true,
          is_active: true
        }
      })
      if (response.status === 200) {
        const userGoals = response.data.filter((goal: Goal) => 
          goal.owner_details.employee_id === user?.employeeId || 
          goal.internal_reviewer_details.some(reviewer => reviewer.employee_id === user?.employeeId)
        );
        setGoals(userGoals);
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch goals",
      })
      console.error('Error fetching goals:', error)
    }
  }

  const fetchActiveReview = async () => {
    try {
      const response = await api.get(`/api/reviews/reviews/employee_active/`, {
        params: {
          employee_id: user?.employeeId
        }
      })
      if (response.status === 200 && response.data.length > 0) {
        setActiveReview(response.data[0])
        
        // Initialize weightPoints from existing weightages
        const initialWeightPoints: Record<number, number> = {};
        const initialRatings: Record<number, number> = {};
        response.data[0].goal_reviews.forEach((goal: GoalReview) => {
          initialWeightPoints[goal.id] = goal.weightage || 0;
          // Initialize manager ratings if they exist
          if (goal.manager_rating) {
            initialRatings[goal.id] = goal.manager_rating;
          }
        });
        setWeightPoints(initialWeightPoints)

        // Initialize comments, ratings, and weightages from existing data
        const comments: Record<number, string> = {}
        const ratings: Record<number, number> = {}
        const weightages: Record<number, number> = {}
        response.data[0].goal_reviews.forEach((goal: GoalReview) => {
          comments[goal.id] = goal.self_comments || ''
          if (goal.self_rating) ratings[goal.id] = goal.self_rating
          weightages[goal.id] = goal.weightage || 0
        })
        setGoalComments(comments)
        setGoalRatings(ratings)
        setGoalWeightages(weightages)
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch active review",
      })
      console.error('Error fetching active review:', error)
    }
  }

  const handleRatingChange = (goalId: number, rating: number) => {
    setGoalRatings(prev => ({ ...prev, [goalId]: rating }))
  }

  const handleWeightChange = (goalId: number, points: number) => {
    setWeightPoints(prev => ({ ...prev, [goalId]: points }))
  }

  const submitGoalComments = async () => {
    try {
      // First save all comments
      await Promise.all(
        activeReview!.goal_reviews.map(goal => 
          api.patch(`/api/reviews/reviews/${activeReview!.id}/goals/${goal.id}/`, {
            self_comments: goalComments[goal.id] || ''
          })
        )
      )
      
      // Then update the review stage and lock it
      await api.post(`/api/reviews/reviews/${activeReview!.id}/lock_goals/`, {})

      toast({
        title: "Success",
        description: "Comments saved and moved to self-rating stage",
      })
      
      setShowConfirmDialog(false)
      await fetchActiveReview()
    } catch (error) {
      console.error('Error submitting comments:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to save goal comments. Please try again.",
      })
    }
  }

  const submitSelfRatings = async () => {
    try {
      const totalPoints = Object.values(weightPoints).reduce((a, b) => a + b, 0);
      
      if (totalPoints === 0) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please assign weight points to at least one goal",
        });
        return;
      }

      await Promise.all(
        activeReview!.goal_reviews.map(goal => 
          api.patch(`/api/reviews/reviews/${activeReview!.id}/goals/${goal.id}/`, {
            self_rating: goalRatings[goal.id],
            weightage: weightPoints[goal.id] || 0,
            self_comments: overallComments
          })
        )
      )
      
      // Update the review stage
      await api.patch(`/api/reviews/reviews/${activeReview!.id}/`, {
        stage: 'manager_review'
      })

      toast({
        title: "Success",
        description: "Self ratings submitted successfully. Your manager will be notified.",
      })
      fetchActiveReview()
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to submit self ratings",
      })
    }
  }

  const acceptManagerReview = async () => {
    if (!activeReview) return;
    
    try {
      // Call the complete_review endpoint
      await api.post(`/api/reviews/reviews/${activeReview.id}/complete_review/`);
      
      toast({
        title: "Success",
        description: "Review completed successfully.",
      });
      
      // Refresh the review data
      await fetchActiveReview();
    } catch (error) {
      console.error('Error completing review:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to complete the review. Please try again.",
      });
    }
  };

  if (!activeReview) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <div className="text-center max-w-md mx-auto px-4">
          <ClipboardList className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
          <h3 className="text-lg font-medium mb-2">No Active Review Cycle</h3>
          <p className="text-sm text-muted-foreground">
            There is currently no active review cycle. The next review cycle will be announced when it begins.
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-8">
      <div className="bg-background">

        {/* Stage Progress */}
        <StageProgress currentStage={activeReview.stage} />
        
        {activeReview.stage === 'goal_summary' && !activeReview.is_locked ? (
          <div>
            {goals.length === 0 ? (
              <NoGoalsState />
            ) : (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {goals.map((goal) => (
                    <GoalSummaryCard key={goal.id} goal={goal} />
                  ))}
                </div>
                
                <div className="mt-8 flex justify-end">
                  <Button 
                    onClick={() => setShowConfirmDialog(true)}
                    className="px-8"
                  >
                    Submit Goals Summary
                  </Button>
                </div>
              </>
            )}
          </div>
        ) : activeReview.stage === 'self_review' ? (
          <div className="space-y-8">
            {/* Add weighted rating display */}
            <WeightedRatingDisplay 
              ratings={goalRatings}
              weights={weightPoints}
              totalPoints={Object.values(weightPoints).reduce((a, b) => a + b, 0)}
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pt-4">
              {activeReview.goal_reviews.map((goalReview) => {
                const totalPoints = Object.values(weightPoints).reduce((a, b) => a + b, 0);
                return (
                  <SelfReviewCard
                    key={goalReview.id}
                    goal={goalReview.goal_details}
                    rating={goalRatings[goalReview.id]}
                    weightPoints={weightPoints[goalReview.id] || 0}
                    totalWeightPoints={totalPoints}
                    onRatingChange={(rating) => handleRatingChange(goalReview.id, rating)}
                    onWeightChange={(points) => handleWeightChange(goalReview.id, points)}
                  />
                );
              })}
            </div>

            {/* Overall Comments Section */}
            <Card>
              <CardHeader>
                <CardTitle className="text-lg">Overall Comments</CardTitle>
                <CardDescription>
                  Share any additional context or justification for your ratings that you'd like your manager to consider.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Textarea
                  value={overallComments}
                  onChange={(e) => setOverallComments(e.target.value)}
                  placeholder="Add your overall comments about your performance and ratings..."
                  className="min-h-[150px]"
                />
              </CardContent>
            </Card>

            {/* Submit Button */}
            <div className="flex justify-end">
              <Button 
                onClick={submitSelfRatings}
                disabled={!activeReview.goal_reviews.every(goal => 
                  goalRatings[goal.id] && weightPoints[goal.id] !== undefined
                )}
              >
                Submit Self Review
              </Button>
            </div>
          </div>
        ) : (
          <div className="space-y-8">
            {/* Add comparison rating display */}
            {activeReview.stage !== 'completed' && (
              <ComparisonRatingDisplay 
                goalReviews={activeReview.goal_reviews}
                weightPoints={weightPoints}
                totalPoints={Object.values(weightPoints).reduce((a, b) => a + b, 0)}
              />
            )}

            {/* Review Completed State - Moved to top when completed */}
            {activeReview.stage === 'completed' && (
              <Card className="border-green-100 bg-green-50/50">
                <CardHeader>
                  <div className="flex items-center gap-2">
                    <Check className="h-5 w-5 text-green-500" />
                    <CardTitle className="text-lg text-green-700">Review Completed</CardTitle>
                  </div>
                  <CardDescription>
                    This review cycle has been completed. The final ratings have been recorded.
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="flex items-center justify-center gap-3 px-4 py-3 bg-background rounded-lg">
                    <div className="flex -space-x-1">
                      {[1, 2, 3, 4, 5].map((star) => {
                        const finalRating = activeReview.goal_reviews.reduce((acc, goal) => {
                          if (!goal.manager_rating) return acc;
                          const weight = weightPoints[goal.id] || 0;
                          const totalPoints = Math.max(Object.values(weightPoints).reduce((a, b) => a + b, 0), 1);
                          return acc + (goal.manager_rating * (weight / totalPoints));
                        }, 0);
                        
                        return (
                          <Star 
                            key={star}
                            className={cn(
                              "w-6 h-6",
                              finalRating >= star 
                                ? "fill-green-500 text-green-500" 
                                : finalRating >= star - 0.5
                                ? "fill-green-500/50 text-green-500"
                                : "text-green-200"
                            )}
                          />
                        );
                      })}
                    </div>
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-green-600">Final Rating</span>
                      <span className="text-2xl font-bold text-green-700">
                        {activeReview.goal_reviews.reduce((acc, goal) => {
                          if (!goal.manager_rating) return acc;
                          const weight = weightPoints[goal.id] || 0;
                          const totalPoints = Math.max(Object.values(weightPoints).reduce((a, b) => a + b, 0), 1);
                          return acc + (goal.manager_rating * (weight / totalPoints));
                        }, 0).toFixed(2)}
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}

            {/* Display Goals Summary */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {activeReview.goal_reviews.map((goalReview) => (
                <Card key={goalReview.id} className="group">
                  <CardContent className="pt-6">
                    <div className="space-y-4">
                      <div>
                        <h3 className="text-lg font-medium whitespace-pre-wrap mb-2">{goalReview.goal_details.title}</h3>
                      </div>

                      <div className="space-y-4">
                        {/* Self Rating */}
                        <div className="space-y-2">
                          <Label className="text-sm text-muted-foreground">Your Rating</Label>
                          <div className="flex items-center space-x-2">
                            {[1, 2, 3, 4, 5].map((star) => (
                              <Star 
                                key={star}
                                className={cn(
                                  "w-5 h-5",
                                  (goalReview.self_rating || 0) >= star 
                                    ? "fill-yellow-400 text-yellow-400" 
                                    : "text-yellow-200"
                                )}
                              />
                            ))}
                            <span className="text-sm font-medium ml-2">
                              {goalReview.self_rating ? `${goalReview.self_rating}/5` : 'Not rated'}
                            </span>
                          </div>
                        </div>

                        {/* Manager Rating */}
                        <div className="space-y-2">
                          <Label className="text-sm text-muted-foreground">Manager's Rating</Label>
                          <div className="flex items-center space-x-2">
                            {[1, 2, 3, 4, 5].map((star) => (
                              <Star 
                                key={star}
                                className={cn(
                                  "w-5 h-5",
                                  (goalReview.manager_rating || 0) >= star 
                                    ? "fill-blue-400 text-blue-400" 
                                    : "text-blue-200"
                                )}
                              />
                            ))}
                            <span className="text-sm font-medium ml-2">
                              {goalReview.manager_rating ? `${goalReview.manager_rating}/5` : 'Not rated'}
                            </span>
                          </div>
                        </div>

                        {/* Weight */}
                        <div className="space-y-2">
                          <Label className="text-sm text-muted-foreground">Goal Weight</Label>
                          <div className="text-sm font-medium text-blue-500">
                            {weightPoints[goalReview.id] || 0} points ({((weightPoints[goalReview.id] || 0) / Math.max(Object.values(weightPoints).reduce((a, b) => a + b, 0), 1) * 100).toFixed(1)}%)
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>

            {/* Comments Section */}
            {(activeReview.goal_reviews[0]?.self_comments || activeReview.goal_reviews[0]?.manager_comments) && (
              <div className="mt-8 space-y-6">
                {activeReview.goal_reviews[0]?.self_comments && (
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-lg">Your Comments</CardTitle>
                      <CardDescription>
                        Your self-assessment and overall performance comments
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <p className="text-sm text-muted-foreground bg-secondary/20 p-4 rounded-lg">
                        {activeReview.goal_reviews[0].self_comments}
                      </p>
                    </CardContent>
                  </Card>
                )}

                {activeReview.goal_reviews[0]?.manager_comments && (
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-lg">Manager's Feedback</CardTitle>
                      <CardDescription>
                        Overall feedback from your manager
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <p className="text-sm text-muted-foreground bg-blue-50 p-4 rounded-lg">
                        {activeReview.goal_reviews[0].manager_comments}
                      </p>
                    </CardContent>
                  </Card>
                )}
              </div>
            )}

            {/* Review Acknowledgment Section */}
            {activeReview.stage === 'manager_review' && activeReview.goal_reviews.every(goal => goal.manager_rating) && (
              <Card className="mt-8">
                <CardHeader>
                  <CardTitle className="text-lg">Review Acknowledgment</CardTitle>
                  <CardDescription>
                    Please review your manager's feedback and ratings before acknowledging the review.
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <ul className="list-disc list-inside space-y-2 text-sm text-muted-foreground mb-6">
                    <li>You have reviewed all ratings and feedback provided by your manager</li>
                    <li>You understand the rationale behind the ratings</li>
                    <li>You have discussed any concerns or questions with your manager</li>
                  </ul>
                  <Button 
                    onClick={acceptManagerReview}
                    className="w-full"
                  >
                    Acknowledge and Complete Review
                  </Button>
                </CardContent>
              </Card>
            )}
          </div>
        )}
      </div>

      {/* Confirm dialog for goal summary submission */}
      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Submit Goals Summary</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to submit your goals summary? Once submitted, you won't be able to make changes to the goals progress.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={submitGoalComments}>Submit</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

export default EmployeeReviewPortal 