'use client'

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { ChevronDown, Search, User, BarChart2, Check, Plus, Trash2, Edit2, GitBranch, AlertTriangle, CheckCircle, X } from 'lucide-react';
// import axios from 'axios';
import { Popover } from '@headlessui/react';
import Plot from 'react-plotly.js';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { Card, CardContent } from "../ui/card"
import api from '../../api';
import { useAuth } from '../../contexts/AuthContext';
import { createPortal } from 'react-dom';
import { useFloating, offset, shift, flip } from '@floating-ui/react';
import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogCancel, AlertDialogAction } from "../ui/alert-dialog"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select"
import { Label } from "../ui/label"

// ─────────────────────────────────────────────────────────────
// Constants and types
// ─────────────────────────────────────────────────────────────

type Employee = {
  employee_id: string;
  name: string;
};

type Decision = {
  id: number;
  company: string;
  inform: Employee[];
  advise: Employee[];
  decide: Employee[];
  execute: Employee[];
  decision_id: string;
  deadline: string;
  decision_level: string;
  status: string;
  decision_priority: string;
  description: string;
  execute_best_practice: boolean;
  decide_best_practice: boolean;
  department: string;
  area_of_focus: string;
};

type SunburstData = {
  data: any[];
  layout: any;
};

type GroupedDecisions = {
  [department: string]: {
    [areaOfFocus: string]: Decision[];
  };
};

type Department = {
  name: string;
  areas_of_focus: string[];
};

const decisionLevels = ['Strategic', 'Tactical', 'Routine'];
const priorityOptions = ['Critical', 'High', 'Medium', 'Low'];

const statusSortOrder = ['Stuck', 'To-Do', 'Doing', 'Done'];
const prioritySortOrder = ['Critical', 'High', 'Medium', 'Low'];

// A helper to generate pastel colors
const generatePastelColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 80%)`;
};

// ─────────────────────────────────────────────────────────────
// Sub‐components
// ─────────────────────────────────────────────────────────────

// Critical alert indicator component (unchanged)
const CriticalAlertIndicator = ({ onClick, criticalCount, totalCount }: {
  onClick: () => void,
  criticalCount: number,
  totalCount: number
}) => {
  const violationPercentage = (criticalCount / totalCount) * 100;
  let bgColor, textColor, icon;
  if (criticalCount === 0) {
    bgColor = 'bg-green-100';
    textColor = 'text-green-800';
    icon = <CheckCircle className="h-4 w-4" />;
  } else if (violationPercentage < 50) {
    bgColor = 'bg-yellow-100';
    textColor = 'text-yellow-800';
    icon = <AlertTriangle className="h-4 w-4" />;
  } else {
    bgColor = 'bg-red-100';
    textColor = 'text-red-800';
    icon = <AlertTriangle className="h-4 w-4" />;
  }
  return (
    <Button
      variant="outline"
      size="sm"
      onClick={onClick}
      className={`flex items-center space-x-2 ${bgColor} ${textColor}`}
    >
      {icon}
      <span>{criticalCount}/{totalCount}</span>
    </Button>
  );
};

// Expandable text component
const ExpandableText = ({ text, maxLength = 100 }: { text: string; maxLength?: number }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const needsExpansion = text.length > maxLength;
  const toggleExpansion = () => setIsExpanded(!isExpanded);
  return (
    <div>
      {isExpanded || !needsExpansion ? text : `${text.slice(0, maxLength)}...`}
      {needsExpansion && (
        <button
          onClick={toggleExpansion}
          className="ml-1 text-blue-500 hover:text-blue-700 text-sm font-medium focus:outline-none"
        >
          {isExpanded ? 'Read less' : 'Read more'}
        </button>
      )}
    </div>
  );
};

// New decision dialog component (unchanged except for formatting)
const NewDecisionDialog = ({
  isOpen,
  onClose,
  departments,
  onCreateDecision,
}: {
  isOpen: boolean;
  onClose: () => void;
  departments: Department[];
  onCreateDecision: (department: string, areaOfFocus: string) => void;
}) => {
  const [selectedDept, setSelectedDept] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [newDeptName, setNewDeptName] = useState('');
  const [newAreaName, setNewAreaName] = useState('');
  const handleCreate = () => {
    const finalDepartment = selectedDept || newDeptName;
    const finalArea = selectedArea || newAreaName;
    if ((selectedDept || newDeptName) && (selectedArea || newAreaName)) {
      onCreateDecision(finalDepartment, finalArea);
      onClose();
      setSelectedDept('');
      setSelectedArea('');
      setNewDeptName('');
      setNewAreaName('');
    }
  };
  if (!isOpen) return null;
  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="sm:max-w-[425px]">
        <AlertDialogHeader>
          <AlertDialogTitle>Create New Decision</AlertDialogTitle>
          <AlertDialogDescription>
            Select an existing department and area or enter new ones.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="grid gap-4 py-4">
          <div className="space-y-2">
            <Label>Department</Label>
            <div className="space-y-2">
              <Select
                value={selectedDept}
                onValueChange={(value) => {
                  setSelectedDept(value);
                  setNewDeptName('');
                }}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select existing department" />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  {departments.map((dept) => (
                    <SelectItem key={dept.name} value={dept.name}>
                      {dept.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <div className="flex items-center gap-2">
                <span className="text-sm text-muted-foreground">or</span>
                <Input
                  value={newDeptName}
                  onChange={(e) => {
                    setNewDeptName(e.target.value);
                    setSelectedDept('');
                  }}
                  placeholder="Enter new department name"
                  className="flex-grow"
                />
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <Label>Area of Focus</Label>
            <div className="space-y-2">
              <Select
                value={selectedArea}
                onValueChange={(value) => {
                  setSelectedArea(value);
                  setNewAreaName('');
                }}
                disabled={!selectedDept}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select existing area" />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  {departments
                    .find(d => d.name === selectedDept)
                    ?.areas_of_focus.map((area) => (
                      <SelectItem key={area} value={area}>
                        {area}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              <div className="flex items-center gap-2">
                <span className="text-sm text-muted-foreground">or</span>
                <Input
                  value={newAreaName}
                  onChange={(e) => {
                    setNewAreaName(e.target.value);
                    setSelectedArea('');
                  }}
                  placeholder="Enter new area name"
                  className="flex-grow"
                />
              </div>
            </div>
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={handleCreate}
            disabled={
              (!selectedDept && !newDeptName) ||
              (!selectedArea && !newAreaName)
            }
          >
            Create Decision
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

// Some shared className strings
const accordionItemClasses = "mb-4 border border-gray-200 rounded-lg overflow-hidden bg-white transition-all duration-200 hover:border-gray-300 hover:shadow-sm";
const accordionTriggerClasses = "px-6 py-4 text-lg font-medium text-black hover:text-black hover:no-underline data-[state=closed]:bg-white data-[state=closed]:hover:bg-gray-50/30 data-[state=open]:bg-gray-50/20";
const tableClasses = "w-full border-separate border-spacing-0";
const tableRowClasses = "transition-colors hover:bg-gray-50/30 hover:text-black border-b border-gray-100/5";
const tableCellClasses = "px-4 py-4 text-black group-hover:text-black";

// ─────────────────────────────────────────────────────────────
// Memoized sub‐components outside the main component
// ─────────────────────────────────────────────────────────────

// EmployeeSelector popover
const EmployeeSelector = React.memo(({
  selectedEmployees, onUpdate, onClose, allEmployees
}: {
  selectedEmployees: Employee[],
  onUpdate: (employees: Employee[]) => void,
  onClose: () => void,
  allEmployees: Employee[]
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [localSelectedEmployees, setLocalSelectedEmployees] = useState<Employee[]>(selectedEmployees);
  const filteredEmployees = useMemo(() =>
    allEmployees.filter(emp => emp.name.toLowerCase().includes(searchTerm.toLowerCase())),
    [allEmployees, searchTerm]
  );
  const toggleEmployee = useCallback((employee: Employee) => {
    setLocalSelectedEmployees(prev => {
      const isSelected = prev.some(e => e.employee_id === employee.employee_id);
      return isSelected
        ? prev.filter(e => e.employee_id !== employee.employee_id)
        : [...prev, employee];
    });
  }, []);
  const handleDone = useCallback(() => {
    onUpdate(localSelectedEmployees);
    onClose();
  }, [localSelectedEmployees, onUpdate, onClose]);
  return (
    <div className="absolute z-50 mt-1 w-80 bg-white border rounded-md shadow-lg">
      <div className="p-2">
        <input
          type="text"
          placeholder="Search employees..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border rounded text-sm"
          autoComplete="off"
        />
      </div>
      <div className="max-h-60 overflow-y-auto">
        {filteredEmployees.map(employee => (
          <div
            key={employee.employee_id}
            className="p-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
            onClick={() => toggleEmployee(employee)}
          >
            <span>{employee.name}</span>
            {localSelectedEmployees.some(e => e.employee_id === employee.employee_id) && (
              <Check className="h-4 w-4 text-green-500" />
            )}
          </div>
        ))}
      </div>
      <div className="p-2 border-t flex justify-end">
        <button
          onClick={handleDone}
          className="px-4 py-2 bg-blue-500 text-white rounded text-sm"
        >
          Done
        </button>
      </div>
    </div>
  );
});

// EmployeeCell component (using useFloating and createPortal)
const EmployeeCell = React.memo(({
  employees, onUpdate, isCritical, criticalType, decisionId, isExpanded, allEmployees, isLoadingEmployees
}: {
  employees: Employee[],
  onUpdate: (employees: Employee[]) => void,
  isCritical: boolean,
  criticalType?: 'decide' | 'execute',
  decisionId: number,
  isExpanded: boolean,
  allEmployees: Employee[],
  isLoadingEmployees: boolean,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(10), shift(), flip()],
  });
  const handleOpen = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(prev => !prev);
  }, []);
  const displayText = isExpanded
    ? employees.map(emp => emp.name).join(', ') || '-'
    : employees.length > 0
      ? `${employees[0].name}${employees.length > 1 ? ` +${employees.length - 1}` : ''}`
      : '-';
  const handleUpdate = useCallback((newEmployees: Employee[]) => {
    onUpdate(newEmployees);
  }, [onUpdate]);
  if (isLoadingEmployees) {
    return (
      <div className="flex items-center justify-center py-2">
        <div className="animate-pulse h-6 w-24 bg-gray-200 rounded"></div>
      </div>
    );
  }
  return (
    <div className="relative flex items-center space-x-1">
      <button
        ref={refs.setReference}
        onClick={handleOpen}
        onMouseOver={(e) => e.stopPropagation()}
        className={`w-full p-2 text-sm text-left flex items-center justify-between bg-transparent
          ${isCritical ? 'border-2 border-red-300 rounded-lg shadow-sm' : ''}`}
      >
        {isCritical && criticalType && (
          <div className="relative mr-1 flex-shrink-0">
            <div
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <AlertTriangle className="h-4 w-4 text-red-500" />
              {showTooltip && (
                <div
                  className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2
                  whitespace-nowrap z-50 mb-1"
                  style={{ boxShadow: '0 2px 4px rgba(0,0,0,0.2)' }}
                >
                  {criticalType === 'decide'
                    ? "Ensure there is exactly one decision maker assigned."
                    : "Ensure there is at least one executor assigned."}
                </div>
              )}
            </div>
          </div>
        )}
        <span className={`flex-grow font-semibold text-gray-900 ${isExpanded ? '' : 'truncate'} ${displayText === '-' ? 'text-gray-400' : ''}`}>
          {displayText}
        </span>
        <ChevronDown className="h-4 w-4 text-gray-400 flex-shrink-0" />
      </button>
      {isOpen && createPortal(
        <div
          ref={refs.setFloating}
          style={{ ...floatingStyles, zIndex: 1000 }}
          className="z-50 w-80 bg-white border rounded-md shadow-lg"
          onClick={(e) => e.stopPropagation()}
        >
          <EmployeeSelector
            selectedEmployees={employees}
            onUpdate={handleUpdate}
            onClose={() => setIsOpen(false)}
            allEmployees={allEmployees}
          />
        </div>,
        document.body
      )}
    </div>
  );
});

// MultiSelectFilter component
const MultiSelectFilter = React.memo(({
  options, selectedOptions, onChange
}: {
  options: string[],
  selectedOptions: string[],
  onChange: (selected: string[]) => void,
}) => {
  const toggleOption = useCallback((option: string) => {
    const newSelection = selectedOptions.includes(option)
      ? selectedOptions.filter(item => item !== option)
      : [...selectedOptions, option];
    onChange(newSelection);
  }, [selectedOptions, onChange]);
  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      {options.map(option => (
        <div key={option} className="flex items-center mb-2">
          <input
            type="checkbox"
            id={option}
            checked={selectedOptions.includes(option)}
            onChange={() => toggleOption(option)}
            className="mr-2"
          />
          <label htmlFor={option}>{option}</label>
        </div>
      ))}
    </div>
  );
});

// EmployeeFilter component
const EmployeeFilter = React.memo(({
  selectedEmployees, onUpdate, allEmployees
}: {
  selectedEmployees: string[],
  onUpdate: (employees: string[]) => void,
  allEmployees: Employee[]
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  const selectedEmployeeObjects = useMemo(() => allEmployees.filter(emp => selectedEmployees.includes(emp.employee_id)), [allEmployees, selectedEmployees]);
  const displayText = selectedEmployeeObjects.length > 0
    ? `${selectedEmployeeObjects[0].name}${selectedEmployeeObjects.length > 1 ? ` +${selectedEmployeeObjects.length - 1}` : ''}`
    : 'Select employees';
  return (
    <div className="relative" ref={ref}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full p-2 border rounded text-sm text-left flex justify-between items-center"
      >
        <span>{displayText}</span>
        <ChevronDown className="h-4 w-4" />
      </button>
      {isOpen && (
        <EmployeeSelector
          selectedEmployees={selectedEmployeeObjects}
          onUpdate={(employees) => onUpdate(employees.map(e => e.employee_id))}
          onClose={() => setIsOpen(false)}
          allEmployees={allEmployees}
        />
      )}
    </div>
  );
});

// ─────────────────────────────────────────────────────────────
// Main Component: DecisionTable
// ─────────────────────────────────────────────────────────────

export default function DecisionTable() {
  const [decisions, setDecisions] = useState<Decision[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortColumn, setSortColumn] = useState<keyof Decision>('id');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [editingDescription, setEditingDescription] = useState<number | null>(null);
  const [editedDescription, setEditedDescription] = useState('');
  const [employeeFilter, setEmployeeFilter] = useState<string[]>([]);
  const [priorityFilter, setPriorityFilter] = useState<string[]>([]);
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [allEmployees, setAllEmployees] = useState<Employee[]>([]);
  const [criticalFilter, setCriticalFilter] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSummaryView, setIsSummaryView] = useState(false);
  const [sunburstData, setSunburstData] = useState<SunburstData | null>(null);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [expandedDepartments, setExpandedDepartments] = useState<string[]>([]);
  const [aofColors, setAofColors] = useState<{ [key: string]: string }>({});
  const [isNewDecisionDialogOpen, setIsNewDecisionDialogOpen] = useState(false);
  const [isLoadingEmployees, setIsLoadingEmployees] = useState(true);
  const [isLoadingSunburst, setIsLoadingSunburst] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [editingPriority, setEditingPriority] = useState<{ id: number; value: string } | null>(null);
  const [editingType, setEditingType] = useState<{ id: number; value: string } | null>(null);

  const { user } = useAuth();

  const updateDecision = useCallback(async (id: number, field: keyof Decision, value: any) => {
    try {
      await api.patch(`/api/decisions/${id}/`, { [field]: value });
      setDecisions(prev => prev.map(decision => decision.id === id ? { ...decision, [field]: value } : decision));
    } catch (error) {
      // handle error if needed
    }
  }, [setDecisions]);

  // Fetch decisions
  const fetchDecisions = useCallback(async () => {
    try {
      const companyName = user?.company || 'Kaynes';
      const response = await api.get(`/api/decisions/${companyName}/`);
      setDecisions(response.data);
      const uniqueAoFs = Array.from(new Set(response.data.map((d: Decision) => d.area_of_focus))) as string[];
      const newAofColors = uniqueAoFs.reduce<{ [key: string]: string }>(
        (acc: { [key: string]: string }, aof: string): { [key: string]: string } => {
          acc[aof] = generatePastelColor(aof);
          return acc;
        },
        {} 
      );
      setAofColors(newAofColors);
    } catch (error) {
      // handle error if needed
    }
  }, [user]);

  // Fetch employees
  const fetchEmployees = useCallback(async () => {
    try {
      setIsLoadingEmployees(true);
      const companyName = user?.company || 'Kaynes';
      const response = await api.get(`/api/employees/${companyName}/`);
      setAllEmployees(response.data);
    } catch (error) {
      // handle error if needed
    } finally {
      setIsLoadingEmployees(false);
    }
  }, [user]);

  // Fetch sunburst data
  const fetchSunburstData = useCallback(async () => {
    try {
      setIsLoadingSunburst(true);
      const companyName = user?.company || 'Kaynes';
      const response = await api.get(`/api/decision-sunburst/${companyName}/`);
      setSunburstData(response.data);
    } catch (error) {
      // handle error if needed
    } finally {
      setIsLoadingSunburst(false);
    }
  }, [user]);

  useEffect(() => { fetchDecisions(); }, [fetchDecisions]);
  useEffect(() => {
    if (decisions.length > 0) {
      fetchEmployees();
      fetchSunburstData();
    }
  }, [decisions.length, fetchEmployees, fetchSunburstData]);

  // Sorting and filtering decisions (memoized)
  const processedDecisions = useMemo(() => {
    let sorted = [...decisions].sort((a, b) => {
      if (sortColumn === 'status') {
        return (statusSortOrder.indexOf(a.status) - statusSortOrder.indexOf(b.status)) * (sortDirection === 'asc' ? 1 : -1);
      } else if (sortColumn === 'decision_priority') {
        return (prioritySortOrder.indexOf(a.decision_priority) - prioritySortOrder.indexOf(b.decision_priority)) * (sortDirection === 'asc' ? 1 : -1);
      } else {
        if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      }
    });
    return sorted.filter(decision =>
      decision.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      decision.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      decision.decision_priority.toLowerCase().includes(searchTerm.toLowerCase()) ||
      decision.decision_level.toLowerCase().includes(searchTerm.toLowerCase())
    ).filter(decision => {
      const employeeMatch = employeeFilter.length === 0 || employeeFilter.some(empId =>
        decision.inform.concat(decision.advise, decision.decide, decision.execute)
          .some(decisionEmp => decisionEmp.employee_id === empId)
      );
      const priorityMatch = priorityFilter.length === 0 || priorityFilter.includes(decision.decision_priority);
      const typeMatch = typeFilter.length === 0 || typeFilter.includes(decision.decision_level);
      return employeeMatch && priorityMatch && typeMatch &&
        (!criticalFilter || (decision.execute_best_practice || decision.decide_best_practice));
    });
  }, [decisions, sortColumn, sortDirection, searchTerm, employeeFilter, priorityFilter, typeFilter, criticalFilter]);

  // Count of critical decisions (memoized)
  const criticalDecisionsCount = useMemo(() => {
    return decisions.filter(d => d.execute_best_practice || d.decide_best_practice).length;
  }, [decisions]);

  const toggleCriticalFilter = useCallback(() => {
    setCriticalFilter(prev => !prev);
  }, []);

  // API update functions
  const deleteDecision = useCallback(async (id: number) => {
    try {
      await api.delete(`/api/decisions/${id}/`);
      setDecisions(prev => prev.filter(decision => decision.id !== id));
    } catch (error) {
      // handle error if needed
    }
  }, []);

  const startEditing = useCallback((id: number, description: string) => {
    setEditingDescription(id);
    setEditedDescription(description);
  }, []);

  const saveDescription = useCallback(async (id: number) => {
    try {
      await updateDecision(id, 'description', editedDescription);
      setEditingDescription(null);
    } catch (error) {
      // handle error if needed
    }
  }, [editedDescription, updateDecision]);

  const updateEmployees = useCallback(async (id: number, field: 'inform' | 'advise' | 'decide' | 'execute', employees: Employee[]) => {
    try {
      const payload = { [`${field}_ids`]: employees.map(e => e.employee_id) };
      await api.patch(`/api/decisions/${id}/`, payload);
      setDecisions(prev => {
        const updatedDecisions = prev.map(decision => {
          if (decision.id === id) {
            const updatedDecision = { ...decision, [field]: employees };
            updatedDecision.execute_best_practice = updatedDecision.execute.length === 0;
            updatedDecision.decide_best_practice = updatedDecision.decide.length !== 1;
            return updatedDecision;
          }
          return decision;
        });
        return updatedDecisions;
      });
    } catch (error) {
      // handle error if needed
    }
  }, []);

  const clearAllFilters = useCallback(() => {
    setEmployeeFilter([]);
    setPriorityFilter([]);
    setTypeFilter([]);
  }, []);

  // Group decisions by department and area (memoized)
  const groupedDecisions = useMemo(() => {
    const groups: GroupedDecisions = {};
    processedDecisions.forEach(decision => {
      const department = decision.department || 'Other';
      const area = decision.area_of_focus || 'Uncategorized';
      if (!groups[department]) groups[department] = {};
      if (!groups[department][area]) groups[department][area] = [];
      groups[department][area].push(decision);
    });
    return groups;
  }, [processedDecisions]);

  // Compute departments from decisions (memoized)
  const departments = useMemo(() => {
    const departmentMap = new Map<string, Set<string>>();
    decisions.forEach(decision => {
      const dept = decision.department || 'Other';
      const area = decision.area_of_focus || 'Uncategorized';
      if (!departmentMap.has(dept)) departmentMap.set(dept, new Set());
      departmentMap.get(dept)?.add(area);
    });
    return Array.from(departmentMap).map(([name, areas]) => ({
      name,
      areas_of_focus: Array.from(areas).sort()
    })).sort((a, b) => a.name.localeCompare(b.name));
  }, [decisions]);

  const createNewDecision = useCallback(async (department: string, areaOfFocus: string) => {
    try {
      const companyName = user?.company || 'Kaynes';
      const newDecision: Partial<Decision> = {
        company: companyName,
        inform: [],
        advise: [],
        decide: [],
        execute: [],
        decision_id: `D${Date.now()}`,
        deadline: new Date().toISOString().split('T')[0],
        decision_level: "Routine",
        status: "To-Do",
        decision_priority: "Medium",
        description: "New decision",
        execute_best_practice: false,
        decide_best_practice: false,
        department,
        area_of_focus: areaOfFocus,
      };
      const response = await api.post(`/api/decisions/${companyName}/`, newDecision);
      const createdDecision = response.data;
      setDecisions(prev => [createdDecision, ...prev]);
      setEditingDescription(createdDecision.id);
      setEditedDescription(createdDecision.description);
      setIsNewDecisionDialogOpen(false);
    } catch (error) {
      // handle error if needed
    }
  }, [user]);

  const toggleRowExpansion = useCallback((id: number) => {
    setExpandedRows(prev => prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]);
  }, []);

  // Sunburst view renderer
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderSunburstView = useCallback(() => {
    if (isLoadingSunburst) {
      return (
        <div className="flex justify-center items-center h-full w-full">
          <div className="animate-pulse space-y-4">
            <div className="h-48 w-48 bg-gray-200 rounded-full"></div>
            <div className="h-4 w-32 bg-gray-200 rounded mx-auto"></div>
          </div>
        </div>
      );
    }
    if (!sunburstData) {
      return (
        <div className="flex justify-center items-center h-full w-full">
          <p className="text-gray-500 text-lg">No data available</p>
        </div>
      );
    }
    return (
      <div className="w-full h-full" style={{ height: 'calc(85vh)' }}>
        <Plot
          data={sunburstData.data}
          layout={{
            ...sunburstData.layout,
            margin: { t: 10, l: 10, r: 10, b: 90 },
            autosize: true,
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)',
            font: { size: 14 },
          }}
          config={{
            responsive: true,
            displayModeBar: false,
          }}
          style={{ width: '100%', height: '100%' }}
          useResizeHandler={true}
        />
      </div>
    );
  }, [isLoadingSunburst, sunburstData]);

  // Loading and empty states
  const LoadingState = useCallback(() => (
    <div className="h-full flex items-center justify-center p-8">
      <div className="space-y-4 text-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black mx-auto"></div>
        <p className="text-muted-foreground">Loading decisions...</p>
      </div>
    </div>
  ), []);

  const EmptyState = useCallback(() => (
    <div className="h-full flex items-center justify-center p-8">
      <div className="bg-muted/50 rounded-lg p-8 max-w-md text-center space-y-4">
        <h3 className="text-lg font-semibold">No Decisions Yet</h3>
        <p className="text-muted-foreground">
          Start by adding your first decision to track.
        </p>
        <Button onClick={() => setIsNewDecisionDialogOpen(true)} className="mt-4">
          <Plus className="mr-2 h-4 w-4" />
          Add Your First Decision
        </Button>
      </div>
    </div>
  ), []);

  const startEditingPriority = useCallback((id: number, currentValue: string) => {
    setEditingPriority({ id, value: currentValue });
  }, []);

  const savePriority = useCallback(async (id: number, newValue: string) => {
    try {
      await updateDecision(id, 'decision_priority', newValue);
    } catch (error) {
      // handle error if needed
    }
  }, [updateDecision]);

  const startEditingType = useCallback((id: number, currentValue: string) => {
    setEditingType({ id, value: currentValue });
  }, []);

  const saveType = useCallback(async (id: number, newValue: string) => {
    try {
      await updateDecision(id, 'decision_level', newValue);
    } catch (error) {
      // handle error if needed
    }
  }, [updateDecision]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow w-full overflow-hidden">
        <div className="h-full flex flex-col w-full">
          {/* Header Section */}
          <div className="sticky top-0 bg-background backdrop-blur-sm z-20 px-4 py-2 md:px-6 md:py-4 border-b border-gray-200/50">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="flex items-center space-x-2">
                <CriticalAlertIndicator
                  onClick={toggleCriticalFilter}
                  criticalCount={criticalDecisionsCount}
                  totalCount={decisions.length}
                />
                <Button
                  variant="outline"
                  onClick={() => setIsNewDecisionDialogOpen(true)}
                  className="bg-white hover:bg-gray-50 border-gray-200 p-1"
                >
                  <Plus className="h-4 w-4 text-black" />
                  <span className="hidden md:inline text-black ml-1">New Decision</span>
                </Button>
              </div>
              {!isSummaryView && (
                <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
                  {/* Search Input Container */}
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-black" />
                    <Input
                      type="search"
                      placeholder="Search decisions..."
                      className="pl-10 w-full sm:w-64 md:w-[200px] lg:w-[300px] border-gray-200 focus:border-gray-400 bg-white"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  {/* Mobile Filter Button (visible only on small screens) */}
                  <div className="block md:hidden ml-2">
                    <Button
                      variant="outline"
                      onClick={() => setShowFilterModal(true)}
                      className="bg-white hover:bg-gray-50 border-gray-200"
                    >
                      <Plus size={16} className="mr-1" /> Filters
                    </Button>
                  </div>
                  {/* Desktop Inline Filters (visible on medium and above) */}
                  <div className="hidden md:flex flex-wrap items-center gap-2">
                    {/* User Filter Popover */}
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            as={Button}
                            variant="ghost"
                            size="icon"
                            className={`rounded-full ${employeeFilter.length > 0 || open ? 'bg-gray-100 text-black' : 'bg-white text-black hover:bg-gray-50'}`}
                          >
                            <User size={20} />
                          </Popover.Button>
                          <Popover.Panel className="absolute z-50 mt-2 w-64 transform right-0 sm:px-0">
                            <Card>
                              <CardContent className="p-2">
                                <EmployeeFilter
                                  selectedEmployees={employeeFilter}
                                  onUpdate={setEmployeeFilter}
                                  allEmployees={allEmployees}
                                />
                              </CardContent>
                            </Card>
                          </Popover.Panel>
                        </>
                      )}
                    </Popover>
                    {/* Priority Filter Popover */}
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            as={Button}
                            variant="ghost"
                            size="icon"
                            className={`rounded-full ${priorityFilter.length > 0 || open ? 'bg-gray-100 text-black' : 'bg-white text-black hover:bg-gray-50'}`}
                          >
                            <BarChart2 size={20} />
                          </Popover.Button>
                          <Popover.Panel className="absolute z-50 mt-2 w-64 transform right-0 sm:px-0">
                            <Card>
                              <CardContent className="p-2">
                                <MultiSelectFilter
                                  options={priorityOptions}
                                  selectedOptions={priorityFilter}
                                  onChange={setPriorityFilter}
                                />
                              </CardContent>
                            </Card>
                          </Popover.Panel>
                        </>
                      )}
                    </Popover>
                    {/* Type Filter Popover */}
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            as={Button}
                            variant="ghost"
                            size="icon"
                            className={`rounded-full ${typeFilter.length > 0 || open ? 'bg-gray-100 text-black' : 'bg-white text-black hover:bg-gray-50'}`}
                          >
                            <GitBranch size={20} />
                          </Popover.Button>
                          <Popover.Panel className="absolute z-50 mt-2 w-64 transform right-0 sm:px-0">
                            <Card>
                              <CardContent className="p-2">
                                <MultiSelectFilter
                                  options={decisionLevels}
                                  selectedOptions={typeFilter}
                                  onChange={setTypeFilter}
                                />
                              </CardContent>
                            </Card>
                          </Popover.Panel>
                        </>
                      )}
                    </Popover>
                    <Button
                      variant="ghost"
                      onClick={clearAllFilters}
                      className={`text-black ${employeeFilter.length > 0 || priorityFilter.length > 0 || typeFilter.length > 0 ? 'bg-gray-100' : 'bg-gray-50'}`}
                    >
                      Clear Filters
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Content Section */}
          <div className="flex-grow overflow-auto px-6 pb-6 min-h-[calc(100vh-8rem)]">
            {isLoadingEmployees ? (
              <LoadingState />
            ) : Object.keys(groupedDecisions).length === 0 ? (
              <EmptyState />
            ) : (
              <Accordion
                type="multiple"
                value={expandedDepartments}
                onValueChange={setExpandedDepartments}
                className="w-full space-y-4"
              >
                {Object.entries(groupedDecisions).map(([department, areas]) => (
                  <AccordionItem value={department} key={department} className={accordionItemClasses}>
                    <AccordionTrigger className={accordionTriggerClasses}>
                      {department}
                    </AccordionTrigger>
                    <AccordionContent className="px-6 py-4">
                      {Object.entries(areas).map(([areaOfFocus, decisions]) => (
                        <div key={areaOfFocus} className="mb-8">
                          <div className="flex items-center mb-4">
                            <div
                              className="w-2 h-2 rounded-full mr-3"
                              style={{ backgroundColor: aofColors[areaOfFocus] || '#e5e7eb' }}
                            />
                            <h3 className="text-lg font-medium text-black">
                              {areaOfFocus || 'Uncategorized'}
                              <span className="ml-2 text-sm text-gray-600">
                                ({decisions.length})
                              </span>
                            </h3>
                          </div>
                          <div className="overflow-x-auto">
                            <table className={tableClasses}>
                              <thead className="sticky top-0 bg-background shadow-sm z-10">
                                <tr className="border-b">
                                  <th className="px-4 py-2 bg-blue-200 text-gray-800 text-center font-bold">I</th>
                                  <th className="px-4 py-2 bg-green-200 text-gray-800 text-center font-bold">A</th>
                                  <th className="px-4 py-2 bg-yellow-200 text-gray-800 text-center font-bold">D</th>
                                  <th className="px-4 py-2 bg-purple-200 text-gray-800 text-center font-bold">E</th>
                                  <th className="px-4 py-2 bg-gray-100 text-gray-800 text-center font-bold">Decision</th>
                                  <th className="px-4 py-2 bg-gray-50 text-gray-600 text-center font-medium">Priority</th>
                                  <th className="px-4 py-2 bg-gray-50 text-gray-600 text-center font-medium">Type</th>
                                  <th className="px-4 py-2 bg-gray-100 text-gray-800 text-center font-bold">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {decisions.map((decision) => (
                                  <tr key={decision.id} className={`group ${tableRowClasses}`}>
                                    <td className={tableCellClasses}>
                                      <EmployeeCell
                                        employees={decision.inform}
                                        onUpdate={(emps) => updateEmployees(decision.id, 'inform', emps)}
                                        isCritical={false}
                                        decisionId={decision.id}
                                        isExpanded={expandedRows.includes(decision.id)}
                                        allEmployees={allEmployees}
                                        isLoadingEmployees={isLoadingEmployees}
                                      />
                                    </td>
                                    <td className={tableCellClasses}>
                                      <EmployeeCell
                                        employees={decision.advise}
                                        onUpdate={(emps) => updateEmployees(decision.id, 'advise', emps)}
                                        isCritical={false}
                                        decisionId={decision.id}
                                        isExpanded={expandedRows.includes(decision.id)}
                                        allEmployees={allEmployees}
                                        isLoadingEmployees={isLoadingEmployees}
                                      />
                                    </td>
                                    <td className={tableCellClasses}>
                                      <EmployeeCell
                                        employees={decision.decide}
                                        onUpdate={(emps) => updateEmployees(decision.id, 'decide', emps)}
                                        isCritical={decision.decide_best_practice}
                                        criticalType="decide"
                                        decisionId={decision.id}
                                        isExpanded={expandedRows.includes(decision.id)}
                                        allEmployees={allEmployees}
                                        isLoadingEmployees={isLoadingEmployees}
                                      />
                                    </td>
                                    <td className={tableCellClasses}>
                                      <EmployeeCell
                                        employees={decision.execute}
                                        onUpdate={(emps) => updateEmployees(decision.id, 'execute', emps)}
                                        isCritical={decision.execute_best_practice}
                                        criticalType="execute"
                                        decisionId={decision.id}
                                        isExpanded={expandedRows.includes(decision.id)}
                                        allEmployees={allEmployees}
                                        isLoadingEmployees={isLoadingEmployees}
                                      />
                                    </td>
                                    <td className={tableCellClasses}>
                                      <div className="flex items-start">
                                        <button className="mr-2 mt-1 focus:outline-none" onClick={() => toggleRowExpansion(decision.id)}></button>
                                        {editingDescription === decision.id ? (
                                          <textarea
                                            value={editedDescription}
                                            onChange={(e) => setEditedDescription(e.target.value)}
                                            onBlur={() => saveDescription(decision.id)}
                                            className="w-full p-2 border rounded"
                                            rows={3}
                                            autoFocus
                                          />
                                        ) : (
                                          <div className="text-left font-medium max-w-[500px]">
                                            <ExpandableText text={decision.description} />
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                    <td className={tableCellClasses + " text-gray-600 text-center"}>
                                      {editingPriority && editingPriority.id === decision.id ? (
                                        <select
                                          value={editingPriority.value}
                                          onChange={(e) => {
                                            const newVal = e.target.value;
                                            setEditingPriority({ id: decision.id, value: newVal });
                                            savePriority(decision.id, newVal);
                                            setEditingPriority(null);
                                          }}
                                          className="border rounded p-1"
                                          autoFocus
                                        >
                                          {priorityOptions.map(opt => <option key={opt} value={opt}>{opt}</option>)}
                                        </select>
                                      ) : (
                                        <span onClick={() => startEditingPriority(decision.id, decision.decision_priority)} className="cursor-pointer">
                                          {decision.decision_priority}
                                        </span>
                                      )}
                                    </td>
                                    <td className={tableCellClasses + " text-gray-600 text-center"}>
                                      {editingType && editingType.id === decision.id ? (
                                        <select
                                          value={editingType.value}
                                          onChange={(e) => {
                                            const newVal = e.target.value;
                                            setEditingType({ id: decision.id, value: newVal });
                                            saveType(decision.id, newVal);
                                            setEditingType(null);
                                          }}
                                          className="border rounded p-1"
                                          autoFocus
                                        >
                                          {decisionLevels.map(opt => <option key={opt} value={opt}>{opt}</option>)}
                                        </select>
                                      ) : (
                                        <span onClick={() => startEditingType(decision.id, decision.decision_level)} className="cursor-pointer">
                                          {decision.decision_level}
                                        </span>
                                      )}
                                    </td>
                                    <td className={tableCellClasses}>
                                      <div className="flex flex-col space-y-2">
                                        <button
                                          onClick={() => startEditing(decision.id, decision.description)}
                                          className="p-2 text-black hover:bg-white rounded-full transition-colors"
                                        >
                                          <Edit2 className="h-4 w-4" />
                                        </button>
                                        <button
                                          onClick={() => deleteDecision(decision.id)}
                                          className="p-2 text-black hover:bg-white rounded-full transition-colors"
                                        >
                                          <Trash2 className="h-4 w-4" />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ))}
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            )}
          </div>
        </div>
      </div>
      <NewDecisionDialog
        isOpen={isNewDecisionDialogOpen}
        onClose={() => setIsNewDecisionDialogOpen(false)}
        departments={departments}
        onCreateDecision={createNewDecision}
      />
      {showFilterModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg w-11/12 max-w-md p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Filters</h2>
              <button onClick={() => setShowFilterModal(false)} className="text-black">
                <X size={20} />
              </button>
            </div>
            <div className="flex flex-col space-y-4">
              <div>
                <Label>Employees</Label>
                {/* Use EmployeeFilter directly for mobile */}
                <EmployeeFilter
                  selectedEmployees={employeeFilter}
                  onUpdate={setEmployeeFilter}
                  allEmployees={allEmployees}
                />
              </div>
              <div>
                <Label>Priority</Label>
                <MultiSelectFilter
                  options={priorityOptions}
                  selectedOptions={priorityFilter}
                  onChange={setPriorityFilter}
                />
              </div>
              <div>
                <Label>Type</Label>
                <MultiSelectFilter
                  options={decisionLevels}
                  selectedOptions={typeFilter}
                  onChange={setTypeFilter}
                />
              </div>
              <Button variant="ghost" onClick={clearAllFilters} className="mt-2">
                Clear Filters
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}