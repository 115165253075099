import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Mail, Key, AlertCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/button';

export default function Login() {
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [error, setError] = useState('');
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { requestOTP, verifyOTP } = useAuth();

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const isDemoUser = await requestOTP(email);
      if (isDemoUser) {
        navigate('/'); // Automatically navigate for demo users
      } else {
        setIsOTPSent(true); // Show OTP input for non-demo users
      }
    } catch (error) {
      setError('Failed to send OTP. Please check your email and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOTP = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    try {
      await verifyOTP(email, otp);
      navigate('/');
    } catch (err) {
      setError('Invalid OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <header className="fixed top-0 left-0 right-0 z-50 bg-background bg-opacity-90 shadow-sm">
        <div className="container mx-auto px-4 py-4">
          <Link 
            to="/" 
            className="font-arial text-2xl font-black tracking-tighter text-black lowercase hover:opacity-80 transition-opacity"
          >
            frenem
          </Link>
        </div>
      </header>

      <div className="min-h-screen pt-16 flex items-center justify-center">
        <div className="w-full max-w-md p-8 space-y-8 bg-white border border-black rounded-xl shadow-lg relative z-10">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-black">Welcome</h2>
            <p className="mt-2 text-gray-600">Sign in to your account</p>
          </div>

          {error && (
            <div className="flex items-center p-4 text-sm text-red-800 rounded-lg bg-red-50 border border-red-200" role="alert">
              <AlertCircle className="flex-shrink-0 inline w-5 h-5 mr-3" />
              <span className="font-medium">{error}</span>
            </div>
          )}

          <form onSubmit={isOTPSent ? handleVerifyOTP : handleEmailSubmit} className="space-y-6">
            <div>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="block w-full pl-10 pr-3 py-2 border border-black rounded-md bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-20 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isOTPSent}
                />
              </div>
            </div>

            {isOTPSent && (
              <div>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Key className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    id="otp"
                    name="otp"
                    type="text"
                    required
                    maxLength={6}
                    className="block w-full pl-10 pr-3 py-2 border border-black rounded-md bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-20 sm:text-sm"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                </div>
              </div>
            )}

            <Button
              type="submit"
              className="w-full bg-black hover:bg-gray-800 text-white"
              disabled={isLoading}
            >
              {isLoading ? 'Please wait...' : isOTPSent ? 'Verify OTP' : 'Request OTP'}
            </Button>

            {isOTPSent && (
              <button
                type="button"
                onClick={() => {
                  setIsOTPSent(false);
                  setOTP('');
                  setError('');
                }}
                className="w-full text-sm text-gray-600 hover:text-gray-900"
              >
                Use different email
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
